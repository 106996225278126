@import '../../assets/sass/variables.scss';

.row-calendar-slider {
  margin-top: -5px;
  @media only screen and (max-width: $screen-md) {
    margin-top: 0;
  }
  .slick-prev {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    left: -10px;
    @media only screen and (max-width: $screen-sm-max) {
      left: 0;
    }
  }
  .slick-next {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    right: -10px;
    @media only screen and (max-width: $screen-sm-max) {
      right: 0;
    }
  }

  .slick-initialized {
    .slick-slide {
      //display: flex;align-items: center;justify-content: center;
    }
  }

  .each-date-in-calendar {
    padding: 5px;
    @media only screen and (max-width: $screen-xs-max) {
      padding: 2px;
    }
    .content {
      background: rgb(225, 238, 245);
      color: black;
      padding: 10px 0;
      border-radius: 10px;
      justify-content: center;
      align-items: center;
      display: grid;
      min-height: 68px;
      @media only screen and (max-width: $screen-xs-max) {
        min-height: auto;
        padding: 5px;
      }
      .date-of-week {
        font-weight: bold;
      }

      &.selected {
        background: #0882e6;
        color: #fff;
      }
    }
  }
}
