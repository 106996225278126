@import "../../assets/sass/variables.scss";

footer.tyneri-footer {
  @media only screen and (max-width: $screen-xs-max) {
    padding: 12px 50px;
  }
  .content-footer {
    @media only screen and (max-width: $screen-xs-max) {
      text-align: center;
      flex-direction: column;
    }
    .social {
      li {
        a {
          width: auto;
          height: auto;
          font-size: 24px;
          background: transparent;
          line-height: 0;
          &:hover {
            background: transparent;
            color: #ff5722;
            border: none;
          }
          &:before, &:after {
            background: white;
            top: 0;
          }
        }
      }
    }
  }
}