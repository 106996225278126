@import '../../assets/sass/variables.scss';

:local(.contentGetStarted) {
  background-color: #fff;
  margin: 30px 0 !important;
  .get-started-form {
    padding: 30px 5%;
    .get-started-form__head {
      text-align: center;
      .get-started-form__head__title {
        display: table;
        margin: 0 auto;
        font-size: 2.5rem;
        font-weight: 600;
        color: #ff9900;
      }
      .get-started-form__head__option {
        color: #0099cc;
        margin-bottom: 30px;
      }
    }
    .get-started-form__content {
      .MuiDialogContent-dividers {
        border: none;
        padding: 0 15px;
        overflow-y: unset;
      }
      .btn-start {
        background: #0099cc;
        color: #fff;
        border: none;
        width: 100%;
        font-size: 20px;
      }
      .get-started-form__content__more-infor {
        padding: 0 15px;
        font-size: 14px;
        text-align: center;
        .show-login {
          color: #1890ff;
          text-decoration: none;
          background-color: transparent;
          outline: none;
          cursor: pointer;
          -webkit-transition: color 0.3s;
          transition: color 0.3s;
          -webkit-text-decoration-skip: objects;
          &:hover {
            color: #40a9ff;
          }
        }
      }
    }
  }

  .section-browse-idea {
    position: relative;
    height: 100%;
    .section-browse-idea__img {
      background-image: url('../../assets/media/images/browse-idea.png');
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      left: 0;
      background-position: center;
    }
    .section-browse-idea__helper {
      position: absolute;
      left: 50%;
      bottom: 60px;
      width: 100%;
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      color: #fff;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
    .section-browse-idea__btn {
      border: none;
      background: #ff9900;
      position: absolute;
      left: 50%;
      color: #fff;
      bottom: 0;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  @media only screen and (min-width: $screen-lg-min) {
    display: flex;
  }
  @media only screen and (max-width: $screen-md-max) {
    .get-started-form {
      padding: 0;
    }
    .section-browse-idea--mobile {
      height: 400px;
    }
  }
}
