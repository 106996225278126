.same-author {
  .ant-list-item {
    &:first-child {
      padding-top: 0;
    }
    .item-list {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      .item-img-block {
        flex: 0 1 25%;
        margin-right: 10px;
        img {
          border-radius: 5px;
        }
      }
    }
  }
}
