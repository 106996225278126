// @import url("https://fonts.googleapis.com/css?family=Work+Sans&display=swap");
@import '~trix/dist/trix.css';
@import './variables.scss';
// @font-face {
//   font-family: "GT-Eesti";
//   src: url("./fonts/GT-Eesti-Display-Light-0bea939c57.woff2") format("woff2");
// }

@font-face {
  font-family: 'emmeline_regularregular';
  src: url('../fonts/emmeline-regular-webfont.woff2') format('woff2'),
    url('../fonts/emmeline-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.title-font {
  font-family: 'emmeline_regularregular', Helvetica Neue, Helvetica, Arial,
    sans-serif;
  display: inline-block;
  &.huge {
    font-size: 58px;
    font-weight: 400;
    background-color: #03c2aa;
    background-image: linear-gradient(180deg, #03c2aa, #0882e6 50%, #03c2aa);
    background-image: -webkit-linear-gradient(
      180deg,
      #03c2aa,
      #0882e6 50%,
      #03c2aa
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
  }
}

.normal-font {
  font-family: Verdana, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

:local(.sectionFooterSaveToFolder) {
  text-align: initial;
  font-size: 22px;
  margin: -10px -16px;
  .ant-avatar {
    margin-right: 5px;
  }
  .btn-choose-folder {
    font-size: 22px;
  }
}

@mixin make-container($minWidth, $breakpoint) {
  @media (min-width: $minWidth) {
    .tyneri-container {
      max-width: $breakpoint;
    }
  }
}

.tyneri-container {
  width: 100%;
  display: flex;
  align-self: center;
  margin: auto;
}

@include make-container($screen-xs-min, $screen-xs);
@include make-container($screen-sm-min, $screen-sm);
@include make-container($screen-md-min, $screen-md);
@include make-container($screen-lg-min, $screen-lg);
@include make-container($screen-xl-min, $screen-xl);
@include make-container($screen-xxl-min, $screen-xxl);

body {
  .ant-input {
    padding: 0 11px;
  }

  .ant-typography-success {
    color: #52c41a;
  }

  .my-library-container {
    background-color: white;
    padding: 10px;
    width: 100%;
    float: left;
  }

  .library-tab {
    .ant-tabs-bar {
      border-bottom: 0px;
    }
    .ant-tabs-tab {
      margin-right: 20px !important;
      color: grey;
      background-color: #eeeeee;
      border: 0px !important;
      border-radius: 3px !important;
    }
    .ant-tabs-tab-active {
      background-color: #ffaf11 !important;
      color: white !important;
    }
    .folder-list-container {
      .search-bar {
        float: right;
        margin-bottom: 15px;
        position: absolute;
        right: 0px;
        top: 0px;
        @media screen and (max-width: $screen-md-max) {
          position: relative;
        }
      }
    }
    .table-list-folder {
      .actions-th {
        text-align: center;
      }
      thead tr > th {
        background: #fafafa;
        border-bottom: 1px solid #e8e8e8;
        transition: background 0.3s ease;
        color: black;
      }
      .tr-item:hover {
        background-color: #e6f7ff;
        transition: all 0.3s, border 0s;
      }
      .tr-item {
        border-top: 1px solid #e8e8e8;
        .reference {
          display: block;
          .source-text {
            color: #096dd9;
          }
        }
        td {
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .item_detail {
          .name {
            color: rgb(15, 149, 222);
            font-weight: bold;
            font-size: 15px;
          }
          .information {
            .budget {
              margin: 0 5px;
            }
            .pax {
              margin-right: 5px;
            }
          }
        }
      }
      tbody {
        tr.folder {
          cursor: pointer;
          td {
            padding: 10px 0px;
          }
          .folder-container {
            display: flex;
            align-items: flex-end;
            .folder-content {
              display: flex;
              align-items: flex-end;
            }
            .folder-item {
              position: relative;
              font-size: 20px;
              width: fit-content;
              vertical-align: middle;
              align-self: center;
              margin-right: 25px;
              svg {
                font-size: 35px;
                color: #e9870a;
              }
              .tag-custom {
                position: absolute;
                border-radius: 50%;
                height: 22px;
                width: 20px;
                vertical-align: middle;
                align-self: center;
                right: -25px;
                width: 22px;
              }
            }
            .name-folder {
              font-size: 18px;
              font-weight: bold;
              line-height: normal;
              color: black;
            }
          }
          .anticon-edit {
            color: #0f95de;
            margin-left: 5px;
          }
        }
        @media screen and (max-width: $screen-md-max) {
          .body-row.folder {
            .hide-mobile {
              display: none;
            }
          }
          .tr-item {
            td {
            }
            .title {
              width: 100%;
              img {
                display: none;
              }
            }
            .group-action {
              margin: 0 auto;
              .td-action {
                text-align: left;
                display: inline-block;
                .btn-move {
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  .popup-title {
    background: #f3f3f3;
    .btn-close {
      top: 8px;
      color: red;
      right: 8px;
      position: absolute;
    }
  }

  .row-select-create {
    @media only screen and (min-width: $screen-md-min) {
      display: flex;
    }
  }

  .select-create {
    background: #f89901;
    border-color: #f89901;
    @media only screen and (min-width: $screen-md-min) {
      position: absolute;
      bottom: 16px;
    }
  }
  .full-height {
    height: 100%;
  }

  .section-img {
    text-align: center;
    .list-img,
    .plan-img {
      width: 90%;
      height: 239px;
      object-fit: cover;
    }
  }

  .ant-input-affix-wrapper {
    .ant-input-clear-icon {
      color: #80868b;
      font-size: 20px;
    }
  }

  /*::-webkit-scrollbar {
    width: 0.5rem;
    background-color: #ecf0f2;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    -webkit-box-shadow: none;
    background-color: rgba(125, 151, 164, 0.4);
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 0.5rem;
    background-color: transparent;
  }*/

  .hide {
    display: none;
  }

  .no-p {
    padding: 0 !important;
  }

  .badge-primary {
    background-color: #0882e6;
  }

  .MuiDialogTitle-root {
    padding: 15px;
    min-height: 64px;
    .MuiTypography-root {
      margin-right: 36px;
    }
  }
  .MuiDialogContent-root {
    padding: 15px;
  }
  .MuiDialogActions-root {
    padding: 8px 15px;
    align-items: unset;
  }

  .MuiDialog-paper {
    @media only screen and (max-width: $screen-md-max) {
      margin: 0;
      width: 100%;
      max-height: 100%;
      max-width: 100% !important;
    }
  }

  .DayPickerInput {
    .is-invalid-datepicker {
      border-color: #dc3545;
      padding-right: calc(1.5em + 0.75rem);
      background-repeat: no-repeat;
      background-position: center right calc(0.375em + 0.1875rem);
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }

  .is-invalid-autocomplete {
    border-color: #dc3545 !important;
    padding-right: calc(1.5em + 0.75rem);
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .is-invalid-btn-remove {
    border-color: #dc3545;
    border-left: 0;
    background-color: #fff;
    > svg {
      fill: red;
    }
    &:hover {
      background: red;
      > svg {
        fill: #fff;
      }
    }
  }

  font-family: Verdana, Helvetica Neue, Helvetica, Arial, sans-serif;

  .ant-tree.ant-tree-show-line li span.ant-tree-switcher {
    background: unset;
  }
  .ant-tree.ant-tree-show-line li:not(:last-child)::before {
    content: unset;
  }

  .section-btn-progress {
    position: relative;
    .btn-progress {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -12px;
      margin-left: -12px;
    }
  }

  .grid-header {
    .see-more {
      float: right;
    }
    > span {
      font-size: 1.5rem;
      font-weight: 500;
    }
    &.bread {
      border-bottom: 1px solid #eeeeef;
      padding-bottom: 1rem;
      margin: 1rem 0;
      padding-left: 15px;
      padding-right: 15px;
      .ant-breadcrumb {
        font-size: 1rem;
        .ant-breadcrumb-link {
          color: black;
        }
        a {
          color: #0099ff;
          &:hover {
            text-decoration: underline !important;
          }
        }
        .ant-breadcrumb-separator {
          color: #0099ff;
        }
      }
    }
  }

  .collapse-filter {
    border: none;
    font-size: 18px;
    .ant-collapse-arrow {
      > svg {
        fill: #45bbff;
      }
    }
    .ant-collapse-content {
      border-top: none;
      > .ant-collapse-content-box {
        padding: 5px;
      }
    }
    .custom-collapse-child {
      background-color: unset;
      border: none;
      .ant-collapse-item {
        border-bottom: none;
        .ant-collapse-header {
          font-weight: bold;
        }
        .ant-collapse-content {
          border-top: none;
          > .ant-collapse-content-box {
            padding: 0px 16px 16px;
          }
          .custom-control {
            font-size: 1rem;
            line-height: 1.6rem;
          }
        }
      }
    }
  }

  .ant-collapse {
    &.itinerary-optional {
      background-color: #fff;
      border: none;
      .ant-collapse-item {
        border-bottom: unset;
        .ant-collapse-header {
          padding: 12px 0 !important;
          font-size: 16px;
          font-weight: bold;
          i.ant-collapse-arrow {
            color: #45bbff;
            font-size: 18px;
          }
        }
        .ant-collapse-content {
          border-top: unset;
          .ant-collapse-content-box {
            padding: 0;
            .ant-collapse {
              background-color: #fff;
              border: none;
              .ant-collapse-header {
                padding: 12px 0 !important;
                font-size: initial;
                font-weight: normal;
              }
            }
          }
        }
      }
    }
  }

  .hide-on-mobile {
    @media screen and (max-width: $screen-sm) {
      display: none !important;
    }
  }

  .hide-on-desktop {
    @media screen and (min-width: $screen-sm-max) {
      display: none !important;
    }
  }

  .ant-list {
    width: 100%;
  }

  font-size: 1rem;

  .btn-loading {
    font-size: 22px !important;
    .circle-loading {
      margin-left: 0;
      margin-right: 8px;
      svg {
        width: unset;
        height: unset;
      }
    }
  }

  .ant-modal-title {
    font-size: 24px;
    font-weight: bold;
  }

  .ant-modal-body {
    .ant-form-item {
      margin-bottom: 0;
      .ant-radio-group {
        display: grid;
        .ant-radio-wrapper {
          margin-bottom: 8px;
        }
      }
    }
  }

  button#button {
    border: none !important;
    border-radius: 50% !important;
    &::after {
      line-height: unset;
    }
  }

  .what-this {
    cursor: pointer;
    color: #45bbff;
    vertical-align: middle;
    font-style: italic;
    &:hover {
      color: #5fc4ff;
    }
  }

  .filter {
    padding: 15px 15px 0 15px;
    .list-scroll-countries-filter {
      overflow-y: auto;
      max-height: 390px;
    }
    .section-search {
      margin-bottom: 10px;
    }
  }

  .filter-on-mobile {
    @media (max-width: $screen-md-max) {
      min-height: unset !important;
    }
  }

  .trix-button--icon-video::before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItdXBsb2FkLWNsb3VkIj48cG9seWxpbmUgcG9pbnRzPSIxNiAxNiAxMiAxMiA4IDE2Ij48L3BvbHlsaW5lPjxsaW5lIHgxPSIxMiIgeTE9IjEyIiB4Mj0iMTIiIHkyPSIyMSI+PC9saW5lPjxwYXRoIGQ9Ik0yMC4zOSAxOC4zOUE1IDUgMCAwIDAgMTggOWgtMS4yNkE4IDggMCAxIDAgMyAxNi4zIj48L3BhdGg+PHBvbHlsaW5lIHBvaW50cz0iMTYgMTYgMTIgMTIgOCAxNiI+PC9wb2x5bGluZT48L3N2Zz4=);
  }

  trix-toolbar .trix-dialog--attach {
    max-width: 600px;
  }

  trix-toolbar .trix-dialog__attach-fields {
    display: flex;
    align-items: baseline;
  }

  trix-toolbar .trix-dialog__attach-fields .trix-input {
    flex: 1;
  }

  trix-toolbar .trix-dialog__attach-fields .trix-button-group {
    flex: 0 0 content;
    margin: 0;
  }
  // .ant-btn:active, .ant-btn.active, .ant-btn:hover, .ant-btn:focus {
  //   color: white;
  //   border: initial;
  // }

  trix-toolbar .trix-button-row {
    flex-wrap: wrap;
    .trix-button-group--history-tools {
      margin-left: 0 !important;
    }
  }

  trix-toolbar .trix-button-group:not(:first-child) {
    margin-left: 0.5rem;
  }

  trix-toolbar .trix-button-group:last-child {
    margin-left: 0.5rem;
  }

  trix-editor {
    min-height: 12rem;
    max-height: 25rem;
    overflow-y: auto;
  }

  .trix-content-temp .attachment--preview .attachment__caption {
    color: #666;
    font-size: 0.7em !important;
    line-height: 1.2;
    padding: 5px 0;
  }

  .trix-content {
    .attachment-gallery {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      margin: 0;
      padding: 0;
    }
  }

  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    padding: 0;
  }

  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    padding: 0;
  }

  padding-right: 0 !important;
  overflow: unset !important;

  .ant-form-item {
    margin-bottom: 0;
    width: 100%;
    @media screen and (max-width: $screen-md-min) {
      margin-bottom: 10px;
      .ant-form-item-label {
        padding: unset;
        label {
          margin-bottom: 0;
        }
      }
    }
  }

  .basic-infor {
    .MuiFormControlLabel-root {
      margin-bottom: 0;
      &:last-of-type {
        margin-right: 0;
      }
    }
    .basic-infor__header {
      background: #e4e4e4;
      color: black;
      font-weight: bold;
      padding: 5px;
      margin-bottom: 10px;
    }
    .ant-select {
      width: 100%;
      margin-bottom: 11px;
      .ant-select-selection--single {
        height: unset;
        .ant-select-selection__rendered {
          display: flex;
        }
      }
    }
    .badge {
      font-size: 100%;
    }
  }

  .advance-infor {
    .MuiFormControlLabel-root {
      margin-bottom: 0;
      &:last-of-type {
        margin-right: 0;
      }
    }
    position: relative;
    .block {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.86);
      z-index: 9;
      display: flex;
      align-items: center;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      div {
        color: #000;
        font-size: 20px;
      }
    }
    .ant-select {
      width: 100% !important;
    }

    > .ant-input {
      margin-bottom: 10px;
    }

    .MuiFormGroup-root {
      .row {
        align-items: center;
        .MuiFormControlLabel-root {
          margin-bottom: 0;
        }
      }
    }

    .advance-infor__header {
      margin-top: 1rem;
      color: black;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:first-of-type {
        margin-top: 0;
      }
    }
    .input-amount {
      span.ant-form-item-children {
        vertical-align: sub;
      }
    }
    .MuiFormControlLabel-labelPlacementStart {
      margin-bottom: 0;
    }
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }

  #lightboxBackdrop {
    button[title='Close (Esc)'] {
      > span {
        > svg {
          fill: black;
        }
      }
    }
    > div {
      background: #fff;
      padding: 0px 40px;
      svg {
        fill: #fff;
      }
    }
  }

  .MuiDialog-root {
    z-index: 1000 !important;
  }

  div.ql-container.ql-snow {
    min-height: 200px;
  }

  .MuiAppBar-colorPrimary {
    background-color: #fff;
  }

  .MuiAppBar-root {
    z-index: 900 !important;
  }

  .MuiPaper-elevation4 {
    box-shadow: unset;
  }

  .btn-sign-in {
    margin-top: 1rem;
    box-shadow: none;
    background: linear-gradient(40deg, #2effda, #2e5bff);
    &:hover {
      box-shadow: none;
    }
  }

  .btn-sign-up {
    background: #d44b54;
    color: #fff;
    border: none;
    width: unset;
    font-size: 20px;
  }

  #DivWithImg {
    width: 40%;
    height: 100px;
    overflow: hidden;
    margin: 0 auto;
    img {
      width: 100%;
      height: auto;
      min-height: 100%;
      min-width: 200px;
    }
  }

  .dropdown-menu {
    z-index: 2000;
  }

  button {
    &:focus {
      outline: unset;
    }
  }

  .MuiDivider-root {
    margin: 1rem 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: rgb(17, 82, 147);
  }

  .MuiFormLabel-root.Mui-error {
    color: #f44336 !important;
  }

  .MuiFab-root {
    text-transform: unset;
  }

  .MuiButton-root {
    text-transform: unset;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid rgb(17, 82, 147);
  }

  #delete-button.MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    background-color: #f50057;
  }

  .pac-container {
    z-index: 1500;
  }

  .InputFromTo {
    .DayPickerInput-Overlay {
      max-width: 100%;
      z-index: 99;
      @media (max-width: $screen-md) {
        max-width: 170%;
      }
      .DayPicker {
        font-size: 1rem;
      }
    }
  }

  .InputFromTo-to {
    .DayPickerInput-Overlay {
      max-width: 100%;
      margin-left: 0;
      z-index: 99;
      @media (max-width: $screen-sm) {
        max-width: 170%;
        margin-left: -110px;
      }
      .DayPicker {
        font-size: 1rem;
      }
    }
  }

  .title-each-country {
    display: flex;
    justify-content: space-between;
    > .left-side {
      font-weight: bold;
    }
    > .right-side {
      color: #1976d2;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .rc-menu.none-background.rc-menu-root.rc-menu-horizontal {
    > .rc-menu-submenu {
      > .rc-menu-submenu-title {
        padding: 8px 10px !important;
        border-radius: 4px;
      }
    }
  }

  .rc-menu-submenu-title {
    padding: 5px !important;
    white-space: unset !important;
    .MuiListItemAvatar-root {
      min-width: 35px;
    }

    .menu-arrow {
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      vertical-align: baseline;
      text-align: center;
      text-transform: none;
      text-rendering: auto;
      position: absolute;
      right: 16px;
      line-height: 1.5em;
      &:before {
        content: '\F0DA';
      }
    }

    i.rc-menu-submenu-arrow {
      display: none;
    }
  }
  .share-section {
    display: inline-flex;
    .icon {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0 0.5rem;
      cursor: pointer;
      &.icon-facebook {
        fill: #4267b2;
      }
      &.icon-twitter {
        fill: #00aced;
      }
    }
  }
}

[style*='--aspect-ratio'] > :first-child {
  width: 100%;
}
[style*='--aspect-ratio'] > img {
  height: auto;
}
@supports (--custom: property) {
  [style*='--aspect-ratio'] {
    position: relative;
  }
  [style*='--aspect-ratio']::before {
    height: 0;
    content: '';
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*='--aspect-ratio'] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

.hover-effect {
  position: relative;
  overflow: hidden;
  &:before {
    --n: 1;
  }
  &:after {
    --n: 2;
  }
  &:before,
  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: #ff5722;
    transform: translateY(150%);
    border-radius: 50%;
    left: calc((var(--n) - 1) * 50%);
    transition: 0.5s;
    transition-delay: calc((var(--n) - 1) * 0.1s);
    z-index: -1;
    opacity: 1;
  }
  @media only screen and (max-width: $screen-xs-max) {
    padding: 0 7px;
    justify-content: center;
  }
  &:hover,
  &:focus,
  &:active {
    color: white;
    border: 1px solid #ff5722;
    background: transparent !important;
    &:before,
    &:after {
      transform: translateY(0) scale(2);
    }
  }
}
