@import "../../../../../../assets/sass/variables.scss";

.new-job-modal {
  .ant-modal-body {
    padding: 15px;
  }
  .ant-form-item-label {
    line-height: 0px;
  }
  &__footer{
    text-align: right;
    &__save {

    }
  }
}

.new-job {
  &__text {
    cursor: pointer;
    color: $action-edit;
  }
}
