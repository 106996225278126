.my-top-list {
  .ant-list-item-meta-title {
    margin: 0;
    font-size: 20px;
  }
  .author {
    .author__name {
      color: #e99501;
    }
  }
}
