.row-itineraries {
  margin-top: 50px;
  .country-card {
    .section-img-top-destination {
      position: relative;
      overflow: hidden;
      color: #ffffff;
      background: #000000;
       * {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
      }
      &:before{
        height: 100%;
        width: 120%;
        top: 0;
        left: 0;
        content: '';
        background: #a09d9d;
        position: absolute;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        -webkit-transform: rotate(120deg) translateY(-50%);
        transform: rotate(120deg) translateY(-50%);
      }
      .img-top-destination {
        height: 225px;
        object-fit: cover;
        object-position: center;
        position: relative;
        width: 100%;
        opacity: 0.4;
      }
      .section-img-top-destination__name {
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        //transform: translate(-50%, -50%);
        //color: #fff;
        font-size: 30px;
        font-weight: bold;
        h3 {
          position: absolute;
          left: 40px;
          right: 40px;
          display: inline-block;
          -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
          margin: 0;
          top: 50%;
          text-transform: uppercase;
          color: inherit;
        }
      }
      &.blue {
        background: #123851;
      }

      &.red {
        background: #581a14;
      }

      &.yellow {
        background: #7f5006;
      }
      &:hover {
        .img-top-destination {
          opacity: 1;
        }
        .section-img-top-destination__name h3 {
          -webkit-transform: translate(-150%, -50%);
          transform: translate(-150%, -50%);
        }
        &:before {
          -webkit-transform: rotate(120deg) translateY(-150%);
          transform: rotate(120deg) translateY(-150%);
        }
      }
    }
  }
}
