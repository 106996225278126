@import "../../assets/sass/variables.scss";

:local(.itineraryDetails) {
  .header-drag-drop {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    margin-bottom: 5px;
    .header-drag-drop__left {
      cursor: pointer;
      font-size: 20px;
      span:first-child {
        vertical-align: baseline;
        padding-right: 5px;
      }
    }
    .header-drag-drop__right {
      align-self: center;
      font-size: 15px;
    }
  }

  .re-arrange {
    transition: all 0.3s;
    width: fit-content;
    cursor: pointer;
    z-index: 100000;
    position: absolute;
    right: 15px;
  }

  .re-arrange-city {
    transition: all 0.3s;
    width: fit-content;
    cursor: pointer;
    z-index: 100000;
    right: 15px;
    position: inherit;
    top: 4px;
    float: right;
  }

  .done-re-arrange {
    transition: all 0.3s;
    width: fit-content;
    border-radius: 4px;
    padding: 5px;
    cursor: pointer;
    background: $green;
    color: #fff;
    float: right;
  }

  #reorderCity {
    padding-top: 7px;
    .each-item-drag-drop {
      &:hover {
        cursor: move !important;
      }
      .form-control {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  #reorderItineraryDetail {
    padding-top: 7px;
    .each-item-drag-drop {
      &:hover {
        cursor: move !important;
      }
      .form-control {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .ant-tree.ant-tree-directory {
    >li.ant-tree-treenode-selected {
      >span.ant-tree-switcher {
        color: unset;
      }
    }
    .ant-tree-child-tree {
      >li.ant-tree-treenode-selected {
        >span.ant-tree-switcher {
          color: unset;
        }
      }
      >li {
        span.ant-tree-node-content-wrapper {
          &::before {
            content: none;
          }
        }
      }
    }
    >li {
      span.ant-tree-node-content-wrapper {
        &::before {
          content: none;
        }
      }
    }
  }

  .ant-tree-title {
    > div {
      display: inline-table !important;
      font-size: 18px;
      color: black;
    }
  }

  .ant-tree li span.ant-tree-switcher,
  .ant-tree li span.ant-tree-iconEle {
    display: inline-flex;
    width: 16px;
    height: 16px;
    line-height: unset;
    vertical-align: unset;
  }

  .ant-tree li {
    white-space: unset;
    ul {
      padding: 0 0 0 21px;
      .city-name {
        > .ant-tree-node-content-wrapper {
          &:last-of-type {
            font-size: 1rem;
            color: rgba(0, 0, 0, 1);
            &:hover {
              background-color: unset;
            }
          }
          &.ant-tree-node-selected {
            background-color: unset;
          }
        }
      }
      .section-re-arrange-city {
        padding: 0;
        .ant-tree-node-content-wrapper.ant-tree-node-selected {
          background-color: unset;
        }
        > .ant-tree-node-content-wrapper {
          &:hover {
            background-color: unset;
            cursor: unset;
          }
        }
      }
      .section-btn-add-day {
        .ant-tree-node-content-wrapper.ant-tree-node-selected {
          background-color: unset;
        }
      }
    }
  }

  .ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
    display: none;
  }

  .ant-tree-node-content-wrapper-normal {
    width: 100%;
    height: unset !important;
    display: block !important;
    padding: 0 !important;
  }

  .badge.badge-primary {
    padding: 0.3rem;
    font-size: 100%;
  }

  .day-item {
    justify-content: space-between;
    > div.day {
      display: flex;
      margin-left: 5px;
    }
    > div.item {
      padding-right: 5px;
      display: flex;
      .edit-icon {
        font-size: 18px;
        color: $action-edit;
        margin-right: 10px;
      }
      .remove-icon {
        font-size: 18px;
        color: $action-delete;
      }
      > svg {
        width: 1.3rem;
        height: 1.3rem;
        fill: #de014f;
      }
    }
  }

  .MuiAvatar-root {
    margin-right: 0.5rem;
    width: 24px;
    height: 24px;
    display: inherit;
    overflow: hidden;
    position: relative;
    font-size: 1.25rem;
    align-items: center;
    flex-shrink: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
  }

  .title-country {
    font-size: 1rem;
    font-weight: bold;
  }

  .footer-day-detail {
    width: 100%;
    .MuiButton-outlinedPrimary {
      border: none !important;
      color: #0079c1;
      padding: 5px 0;
    }

    .MuiButton-label {
      justify-content: flex-start;
    }

    svg {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background: #fff;
      color: #0f95de;
    }

    span {
      margin-left: 15px;
    }
  }

  .footer-day-detail button {
    margin: 0 auto;
    width: 100%;
  }

  a {
    text-decoration: none;
    color: black !important;
  }

  div[role="progressbar"] {
    color: #ffffff !important;
    width: auto !important;
    height: auto !important;
  }

  a {
    &._active {
      .each-itinerary-detail {
        -webkit-border-radius: 5px 0 0 5px;
        -moz-border-radius: 5px 0 0 5px;
        border-radius: 5px 0 0 5px;
        background-color: #fff;
        color: #000000;
      }
    }
    .each-itinerary-detail {
      width: 100%;
      margin-left: unset;
      margin-right: unset;
      padding: 7px 0;
      cursor: pointer;
      position: relative;
      z-index: 0;
      .itinerary-detail-content {
        padding: 0 15px 0 5px;
        margin-bottom: 0;
        word-break: break-word;
        &.text-day {
          padding-left: 5px;
          color: #9a9a9a;
          font-size: 15px;
        }
      }
      &:hover {
        -webkit-border-radius: 5px 0 0 5px;
        -moz-border-radius: 5px 0 0 5px;
        border-radius: 5px 0 0 5px;
        background-color: #fff;
      }
    }
  }
  .each-itinerary-detail.selected {
    -webkit-border-radius: 5px 0 0 5px;
    -moz-border-radius: 5px 0 0 5px;
    border-radius: 5px 0 0 5px;
    background-color: #fff;
    color: #000000;
  }
}
