@import "../../assets/sass/variables.scss";

.section-option-view {
  background: #f7f7f7;
  justify-content: space-between;
  display: flex;
  padding: 5px;
  margin: 1rem 0;
  border-radius: 5px;
  @media only screen and (max-width: $screen-xs-max) {
    margin: 5px 0;
  }
  .section-option-view-left {
    display: flex;
    align-items: center;
    .each-option-view {
      cursor: pointer;
      font-size: 14px;
      @media only screen and (max-width: $screen-xs-max) {
        font-size: 12px;
      }
      &.active {
        color: #0882e6;
      }
      &:hover {
        color: #0882e6;
      }
      border-right: 1px solid rgb(225, 238, 245);
      padding: 5px 10px;
      &:last-of-type {
        border-right: none;
      }
    }
  }
}
