:local(.addNewStopForm) {
  div.MuiTextField-root {
    margin-bottom: 1rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  
  .warning {
    color: #f44336;
    font-style: oblique;
  }

  .section {
    font-weight: bold;
  }
  
  #save-button {
    width: 178.88px;
    height: 36px;
  }

  #add-button {
    width: 64px;
    height: 36px;
  }

  .btn-green {
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12) !important;
    color: #fff;
    background-color: #81bc2e;
    border-top-color: #76ac2a;
    border-left-color: #76ac2a;
    border-right-color: #76ac2a;
    border-bottom-color: #70a328;
    border-color: transparent;
    &:hover {
      background-color: #6eb111;
    }
  }

  .btn-add {
    background: #E79E3D;
    color: white;
    box-shadow: none;
    &:hover {
      background-color: #c3832e;
      color: white;
    }
  }

  .btn-primary {
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12) !important;
    background-color: #0099ff;
    color: white;
    box-shadow: none;
    &:hover {
      background-color: #1389d8;
      color: white;
    }
  }

  div[role="progressbar"] {
    color: #ffffff !important;
    width: 20px !important;
    height: 20px !important;
  }
}
