:local(.cardTyneri) {
  border: 0;
  .subtit {
    margin-bottom: 5px;
    display: inline-block;width: 100%;
  }
  .size-height {
    padding-top: 75%;
    border-radius: 5px;
    background-position: center;
    background-size: cover;
  }
}
