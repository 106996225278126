:local(.allTyneris) {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  .MuiListItem-root.MuiListItem-gutters {
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: flex-start;
    text-decoration: none;
    padding-left: 16px;
    padding-right: 16px;
  }

  .MuiListItemText-root {
    flex: 1 1 auto;
    min-width: 0;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .MuiAvatar-root.MuiAvatar-circle.flag-image {
    width: 20px;
    height: 20px;
    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 1.25rem;
    align-items: center;
    flex-shrink: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    .MuiAvatar-img {
      color: transparent;
      width: 100%;
      height: 100%;
      object-fit: cover;
      text-align: center;
      text-indent: 10000px;
    }
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }

  .right {
    @media (min-width: 200px) {
      margin-left: 2rem !important;
    }

    @media (max-width: 767px) {
      > img {
        width: 100%;
      }
    }

    @media (max-width: 991px) {
      > img {
        width: 100%;
      }
    }

    @media (min-width: 992px) {
      > img {
        width: 110%;
      }
    }

    @media (min-width: 1190px) {
      > img {
        width: auto;
      }
    }
  }

  .home-page {
    display: inline;
    float: left;
    padding-top: 26px;
    padding-right: 50px;

    @media (min-width: 200px) {
      display: unset;
      float: unset;
      padding-right: unset;
      > * {
        margin-left: 2rem !important;
      }
    }
    h1 {
      // color: #040e28;
      // font-size: 32px;
      // font-weight: 700;
      // margin: 0 0 10px;
      color: #040e28;
      font-size: 33px;
      font-weight: bold;
      line-height: 45px;
    }
    h2 {
      color: #040e28;
      font-size: 22px;
      font-weight: bold;
      line-height: 45px;
    }
    p {
      // margin: 0 0 20px;
      font-size: 16px;
      margin: 10px 0 10px;
      line-height: 30px;
      letter-spacing: 1px;
    }
  }

  .btn-primary {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
    background-color: #0099ff;
    color: white;
    box-shadow: none;
    &:hover {
      background-color: #1389d8;
      color: white;
    }
  }

  .color-primary {
    color: #fff;
    background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%);
    &:hover {
      background-color: #ca770d;
    }
  }
}
