:local(.formQuote) {
  .btn-primary {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
    background-color: #0099ff;
    color: white;
    box-shadow: none;
    &:hover {
      background-color: #1389d8;
      color: white;
    }
  }
}
