:local(.editDayTitleChangeDate) {
  .required-meal {
    color: #f5222d;
    margin-bottom: -1px;
    clear: both;
    min-height: 22px;
    margin-top: -2px;
    font-size: 14px;
    line-height: 1.5;
    -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  trix-editor {
    min-height: 22rem !important;
  }

  .input-amount {
    .ant-input-group-wrapper {
      vertical-align: middle;
    }
  }

  div.MuiTextField-root {
    margin-bottom: 1rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .date-picker {
    margin-bottom: 0 !important;
  }

  div[role="group"] {
    margin-bottom: 1rem;
  }

  padding: 1rem 0;

  .date-picker > div > div > div {
    height: auto;
  }

  .btn-green {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
    color: #fff;
    background-color: #81bc2e;
    border-top-color: #76ac2a;
    border-left-color: #76ac2a;
    border-right-color: #76ac2a;
    border-bottom-color: #70a328;
    border-color: transparent;
    &:hover {
      background-color: #6eb111;
    }
  }
}

:local(.loader) {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/media/images/page-loader.gif");
  background-repeat: no-repeat;
  background-position: center;
}
