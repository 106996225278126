@import "../../assets/sass/variables.scss";
:local(.base) {
  &.author-infor {
    font-family: "Rubik", sans-serif;
    @media only screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
      flex-flow: row wrap;
      .company-request-section {
        width: 100%;
      }
    }
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    background: rgb(247, 247, 247);
    margin: 1rem 0px;
    @media only screen and (max-width: $screen-xs-max) {
      flex-flow: row wrap;
    }
    @media only screen and (max-width: $screen-xs-max) {
      .company-request-section {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
      }
    }
    .company-request-section {
      flex-basis: 100%;
      flex-grow: 1;
      > a{
        display: block;
        width: 100%;
      }
      .company-section {
        align-items: center;
        width: auto;
        &.author {
          width: 100%;
        }
        .author-avatar{
          width: 100px;
          height: 100px;
          > .ant-avatar{
            width: 100%;
            height: 100%;
          }
        }
        .company-logo {
          width: 100px;
          height: 100px;
        }
        .company-infor{
          flex: 1;
          font-weight: 500;
          .company-infor__name{
            color: unset;
            font-size: 20px;
            display: inline-block;
            width: 100%;
            margin-bottom: 5px;
            .type-user {
              display: block;
              font-size: 70%;
              font-weight: normal;
            }
          }
          .company-infor__summary {
            display: flex;align-items: center;
            width: 70%;
            @media only screen and (max-width: $screen-md-max) {
              width: auto;
            }
            > span {
              flex: 1;
              font-size: 14px;
              color: black;
              margin: 0;
              .company-infor__summary__detail {
                display: block;
                color: black;
                font-size: 24px;
              }
            }
          }
        }
      }
    }
    .reference-section {
      font-size: 15px;
      align-self: center;
      display: flex;
      width: 80%;
      .author-infor__name {
        font-size: 16px;
        margin-left: 15px;
      }
      .author-infor__summary {
        font-size: 13px;
        color: #9a9a9a;
        margin-left: 15px;
        span {
          margin-right: 10px;
        }
        .author-infor__summary__detail {
          color: red;
        }
      }
    }
    .btn-customize {
      color: #fff;
      box-shadow: none;
      background-image: -webkit-linear-gradient(330deg,#ff5722,#ffc107);
      background-image: linear-gradient(120deg,#ff5722,#ffc107);
      @media only screen and (max-width: $screen-xs-max) {
        width: 100%;
        margin-left: 0;
      }
      @media only screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
        width: 100%;
        margin-top: 10px;
        margin-left: 0;
      }
    }
  }
}
:local(.overBase) {
  &.overview{
    .ant-descriptions {
      .ant-descriptions-view {
        .ant-descriptions-row {
          display: flex;
          align-items: center;
          justify-content: space-around;
          border-top: 1px solid #eeeeef;
          border-bottom: 1px solid #eeeeef;
          flex-flow: row wrap;
          @media only screen and (max-width: $screen-xs-max) {
            align-items: flex-start;
            flex-direction: column;
          }
          .ant-descriptions-item {
            padding: 16px 0;
            display: flex;align-items: flex-start;
            font-size: 15px;
            @media only screen and (max-width: $screen-xs-max) {
              padding: 8px 0;
            }
            &:last-child {
              border-right: 0;
            }
            .ant-descriptions-item-label {
              display: flex;
              align-items: flex-start;
              font-size: inherit;
              &::after {
                top: unset;
              }
              >b {
                display: flex;
                align-items: center;
                > i {
                  margin-right: 3px;
                }
              }
            }
          }
        }
      }
    }
  }
}
:local(.leftContent) {
  position: relative;
  .to-sticky{
    .left-bar {
      overflow-y: auto;
      background: #f7f7f7;
      padding-left: 15px;
      .si-day-nav {
        padding-left: 10px;
        .main-title-section {
          font-size: 1.5rem;
        }
        .city-name {
          .ant-tree-treenode-selected {
            .each-itinerary-detail {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
  .right-bar{
    .section-title-itinerary {
      .name {
        font-size: 24px;
        font-weight: normal;
      }
    }
    .description{
      margin-top: 15px;
    }
  }
}

:local(.gridHeader) {
  border-bottom: 1px solid #eeeeef;
  padding-bottom: 1rem;
  margin: 1rem 0;
  &.bread {
    display: flex;
    justify-content: space-between;
    .ant-breadcrumb {
      font-size: 1rem;
      .ant-breadcrumb-link {
        color: black;
      }
      a {
        color: #0099ff;
      }
    }
  }

  .total-view {
    >.anticon {
      vertical-align: initial;
    }
  }
}

:local(.shareSection) {
  float: right;
  display: flex;
  align-items: center;
  .icon {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 .5rem;
    cursor: pointer;
    &.icon-facebook {
      fill: #4267b2;
    }
    &.icon-twitter {
      fill: #00aced;
    }
  }
  .view-count{
    display: flex;align-items: center;
  }
}

:local(.baseLike) {
  .cardList {
    .each-related {
      .cardBlock {
        border: 0;
        .cartImage{
          border-radius: 5px;
        }
        .ant-card-body{
          // height: 140px;
          // overflow-y: auto;
          .content {
            font-weight: normal;
          }
        }
      }
    }
  }
}
