:local(.sectionPreview){
  .preview-title {
    overflow: hidden;
    &:after {
      background-color: #e1eef5;
      content: "";
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 100%;
      left: 0.5em;
      margin-right: -50%;
    }
  }
  .preview-content{
    .ant-list-item-meta-title {
      display: flex;
      flex-direction: column;
      a{
        font-weight: 700;
        font-size: 18px;
        text-transform: capitalize;
      }
    }
  }
}
