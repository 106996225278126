@import '../../../../assets/sass/variables.scss';
.inquiries-container {
    padding: 10px;
    background-color: white;
    padding: 10px;
    width: 100%;
    float: left;
    .ant-btn.ant-btn-primary {
        margin-right: 10px;
    }
    .radio-custom {
        border: none;
        color: white;
        box-shadow: none;
        -webkit-box-shadow: none;
        margin: 5px 10px;
        border-radius: 5px;
        outline: none;
        height: 40px;
        line-height: 40px;
        padding: 0 25px;
        margin-bottom: 20px;
    }
    .radio-custom:active, .radio-custom:hover, .radio-custom.ant-radio-button-wrapper-checked {
        border: none!important;
        color: white!important;
        -webkit-box-shadow: 0px 0px 7px #333!important;
        box-shadow: 5px 5px 5px #999!important;
        font-weight: bold!important;
    }
    .radio-custom::before {
        content: '';
        width: 0px;
    }
    .new-radio {
        background-color: $green;
    }
    .seen-radio {
        background-color: $action-preview;
    }
    .close-radio {
        background-color: $action-delete;
    }
}