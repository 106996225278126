:local(.formShareByEmail) {
  .icon-copy-link-share-email {
    display: grid;
    margin: 1rem;
    >button {
      margin: 0 auto;
      background: #fff;
      box-shadow: unset;
      border: 1px solid black;
      svg {
        fill: black;
      }
      &:hover {
        background: black;
        svg {
          fill: #fff;
        }
      }
    }
  }

  .row-icon-share {
    display: inline-flex;
    margin: 0 auto;
    .icon {
      width: 3rem;
      height: 3rem;
      margin: 1rem;
      cursor: pointer;
      &.icon-facebook {
        fill: #4267B2;
      }
    
      &.icon-twitter {
        fill: #00ACED;
      }
    
      &.icon-pinter {
        fill: #CA2128;
      }
    }
  }
  
  .btn-primary {
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12) !important;
    background-color: #0099ff;
    color: white;
    box-shadow: none;
    &:hover {
      background-color: #1389d8;
      color: white;
    }
  }

  div[role="progressbar"] {
    color: #ffffff !important;
    width: 20px !important;
    height: 20px !important;
  }
}
