.home_banner_area {
  .banner_inner {
    top: 50%;
    .banner_content h2 {
      color: #002347;
    }
  }
}

.pricing-section {
  .pricing-item {
    color: #a0a4ab;
  }

  .period {
    color: #a0a4ab;
  }
  .period-toggle {
    cursor: pointer;
  }
}

.nav-link {
  &.active {
    color: #fdc632 !important;
  }
}

.navbar-expand-lg {
  > .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.header_area .navbar-collapse {
  overflow-y: hidden;
}

[data-nav="list"] {
  width: 100%;
}

.custom-card-tyneri {
  padding: 8px;
  float: left;
}