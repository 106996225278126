@import "../../assets/sass/variables.scss";

select.dropdown-toggle {
  background: #0f95de;
  color: #fff;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  cursor: pointer;
  height: 100%;
  > option {
    background: #fff;
    color: black;
  }
  &:focus {
    box-shadow: unset;
    outline: 0;
  }
}

.input-group-btn.search-panel {
  @media (min-width: $screen-lg) and (max-width: $screen-xl) {
    > select {
      @media (min-width: $screen-lg) and (max-width: $screen-xl) {
        width: 100%;
      }
    }
  }

  @media (min-width: $screen-xl) and (max-width: $screen-xxl) {
    width: 100%;
    margin-bottom: 10px;
    > select {
      @media (min-width: $screen-xl) and (max-width: $screen-xxl) {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: $screen-xs-max) {
    width: 100%;
    > select {
      width: 100%;
      border-radius: 5px;
    }
  }

}
.input-group{
  @media only screen and (max-width: $screen-xs-max) {
    > input {
      margin-top: 5px;
      border-radius: 5px !important;
      border: none;
    }
  }
}

button.btn-default {
  background: #febd67;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn {
  &:hover {
    color: #fff !important;
  }
}

.btn-clear {
  &:hover {
    color: black !important;
  }
}
