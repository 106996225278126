:local(.listLibraries) {
  .zoom-place:hover {
    -moz-transform: unset;
    -webkit-transform: unset;
    transform: unset;
    cursor: default;
    .idea-tile-horizontal._can-view-details:hover figure:after {
        display: none;
    }
  }
}
