:local(.contentCreateTopList) {
  .section-save {
    background: #fafafa;
    position: sticky;
    bottom: 0;
    padding: 0 15px;
    margin-top: auto;
  }

  .btn-add-more {
    background: #ffc107;
    color: #000000;
    width: 100%;
    margin-bottom: 15px;
  }
  .input-title-top-list {
    font-size: 22px;
  }

  .title-list {
    .ant-form-item-control {
      line-height: 32px;
      .ant-form-extra {
        padding-top: 8px;
        padding-bottom: 8px;
        float: right;
      }
      .ant-form-explain {
        display: contents;
      }
    }
  }

  .title-create {
    margin: 10px 0;
    font-size: 32px;
    font-weight: bold;
  }

  .number-item {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 70px;
  }

  .dynamic-delete-button {
    color: red;
    cursor: pointer;
    vertical-align: 2px;
    font-size: 18px;
    margin-left: 10px;
  }

  .warning-limit {
    span {
      font-size: 13px;
      color: #dc3545;
      &.anticon-warning {
        vertical-align: text-top;
      }
    }
  }

  .rdw-editor-wrapper {
    background: white;
    border: 1px solid #cdcdcd;
    .rdw-editor-main {
      min-height: 250px;
      max-height: 250px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .rdw-link-modal {
      overflow: auto;
      .rdw-link-modal-label {
        margin: 0px;
      }
      .rdw-link-modal-btn {
        height: auto;
      }
    }
  }
}
