@import "../../../assets/sass/variables.scss";

:local(.listTopLists) {
  .custom-row {
    .ant-spin-nested-loading {
      .ant-spin-container{
        > .ant-row {
          margin-top: 20px;
          display: flex;
          flex-flow: row wrap;
        }
      }
    }
  }
  .content-left {
    background: #e1eef5;
    min-height: calc(100vh - 94px - 53px);
    display: flex;
    flex-direction: column;
    padding: 0;
    @media only screen and (max-width: $screen-md-max) {
      min-height: auto;
    }
  }
  .content-right {
    padding: 0;
    background: #e1eef5;
    min-height: calc(100vh - 94px - 53px);
    @media only screen and (max-width: $screen-md-max) {
      display: none;
    }
    .top-right-container {
      margin: 15px;
    }
  }

  .container {
    max-width: 100%;
    min-height: calc(100vh - 94px - 53px);
    background: #fff;
    box-shadow: 1px 5px 30px rgba(114, 182, 234, 0.2);
  }

  .img-fluid {
    padding: 90px 0;
    display: block;
    position: relative;
    background-color: #3e3e3e;
    background-image: none;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    &.small {
      padding: 30px 0;
    }
  }

  .author {
    color: #9a9a9a;
    font-size: 0.8rem;
    .author__name {
      color: $main-author-name;
    }
  }

  .content-attraction {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 1rem;
    padding-top: 5px;
  }

  .title-attraction {
    margin-bottom: 0;
    font-weight: bold;
    > a {
      color: rgba(0, 0, 0, 0.85);
      &:hover {
        color: #40a9ff;
      }
    }
  }
}
