:local(.tableCustom) {
  code {
    display: inline-block;
    .code-card{
      display: flex;
      align-items: center;
      padding: .5em;
      > i {
        margin-right: 5px;
        font-size: 16px;
      }
    }
  }
}
