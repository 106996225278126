@charset "UTF-8";
:local(.loading) {
  margin-bottom: 8px;
  /* Optional: Makes the sample page fill the window. */ }
  :local(.loading) .thumbnail-places {
    width: 70px;
    height: 70px;
    margin-left: -10px;
    flex-shrink: 0;
    -webkit-box-flex: 0;
    flex-grow: 0;
    border-radius: 50%;
    border: 1px solid #fff; }
  :local(.loading) .btn-add-place > svg {
    cursor: pointer;
    transition-duration: 0.25s;
    transition-property: fill;
    fill: #ecf0f2; }
    :local(.loading) .btn-add-place > svg:hover {
      background-color: rgba(69, 187, 255, 0.05) !important;
      fill: #45bbff !important; }
  :local(.loading) .btn-add-place {
    position: relative; }
  :local(.loading) div[role="progressbar"] {
    color: #45bbff !important;
    height: unset !important;
    width: unset !important; }
  :local(.loading) .sidebar-idea-container {
    margin: 1rem;
    background-color: transparent; }
  :local(.loading) .idea-tile-horizontal {
    cursor: move; }
  :local(.loading) .idea-tile-horizontal._can-view-details {
    cursor: move; }
  :local(.loading) .idea-tile-horizontal {
    border-radius: 0.25rem;
    background-color: #fff;
    box-shadow: 0 0.25rem 2rem -0.5rem rgba(28, 75, 102, 0.3);
    height: 5.4rem;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    box-orient: horizontal;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    flex-direction: row;
    -ms-flex-direction: row;
    -webkit-transform: scale(1.001);
    -moz-transform: scale(1.001);
    -ms-transform: scale(1.001);
    -o-transform: scale(1.001);
    transform: scale(1.001);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition-property: -webkit-transform, box-shadow;
    -moz-transition-property: -moz-transform, box-shadow;
    transition-property: transform, box-shadow;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s; }
  :local(.loading) .idea-tile-horizontal > figure {
    position: relative;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 0 5rem;
    -moz-flex: 0 0 5rem;
    -ms-flex: 0 0 5rem;
    flex: 0 0 5rem;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    min-height: 4rem;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    background-image: url("../../assets/media/images/generic-activities-img.jpg"); }
  :local(.loading) .idea-tile-horizontal > figure {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%; }
  :local(.loading) figure {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline; }
  :local(.loading) .idea-tile-horizontal > figure .btn {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
    opacity: 0; }
  :local(.loading) .idea-tile-horizontal._can-view-details:hover figure {
    position: relative; }
  :local(.loading) .idea-tile-horizontal._can-view-details:hover figure .btn {
    opacity: 1; }
  :local(.loading) .idea-tile-horizontal > figure > * {
    position: relative;
    z-index: 10; }
  :local(.loading) ._btn-small {
    height: 2.1rem;
    padding-left: 0.35rem;
    padding-right: 0.35rem; }
  :local(.loading) ._btn-outline-white {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
    border: 1px solid #d7dfe3; }
  :local(.loading) ._btn-outline-white:focus,
  :local(.loading) ._btn-outline-white:hover {
    color: #fff;
    border: 1px solid #fff; }
  :local(.loading) ._btn-outline-white:focus,
  :local(.loading) ._btn-outline-white:hover {
    color: #fff;
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 0.125); }
  :local(.loading) ._btn-outline-white,
  :local(.loading) ._btn-outline-white:hover {
    border: 1px solid springgreen !important; }
  :local(.loading) .btn {
    cursor: pointer;
    position: relative;
    text-transform: initial;
    border: 1px solid transparent;
    overflow: hidden;
    text-align: center;
    border-radius: 0.25rem;
    font-family: proxima-nova, sans-serif;
    font-weight: 400;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    font-size: 1rem;
    line-height: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    height: 2.9rem;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    -webkit-transition-property: -webkit-transform, background-color, border, color, box-shadow;
    -moz-transition-property: -moz-transform, background-color, border, color, box-shadow;
    transition-property: transform, background-color, border, color, box-shadow;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
    font-weight: 400; }
  :local(.loading) .idea-tile-horizontal._can-view-details:hover figure:after {
    position: absolute;
    content: "";
    background-color: rgba(5, 37, 48, 0.6);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1; }
  :local(.loading) .idea-tile-horizontal > figure:after {
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    -webkit-transition-property: background-color;
    -moz-transition-property: background-color;
    transition-property: background-color;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
    position: absolute;
    content: "";
    background-color: rgba(5, 37, 48, 0);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1; }
  :local(.loading) *,
  :local(.loading) ::after,
  :local(.loading) ::before {
    box-sizing: inherit; }
  :local(.loading) .idea-tile-horizontal ._c-info {
    padding: 0.25rem 0;
    overflow: hidden;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-flex-direction: column;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
    :local(.loading) .idea-tile-horizontal ._c-info > .MuiSkeleton-root {
      padding: 0.25rem 0.5rem !important;
      margin-left: 0.5rem; }
  :local(.loading) .idea-tile-horizontal ._c-info li:first-child {
    color: #052530;
    font-weight: 700; }
  :local(.loading) .idea-tile-horizontal ._c-info li {
    padding: 0.25rem 0.5rem;
    color: #7d97a4;
    font-size: 0.9rem; }
  :local(.loading) .idea-tile-horizontal ._c-info li span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  :local(.loading) .idea-tile-horizontal ._c-info li span p {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  :local(.loading) .click-to-add {
    padding: 2rem 0.5rem;
    cursor: pointer; }
  :local(.loading) .click-to-remove {
    padding: 2rem 0.2rem;
    cursor: pointer; }
  @media only screen and (min-width: 75em) {
    :local(.loading) .click-to-add > svg {
      fill: #7c8e9c;
      width: 1.5rem;
      height: 1.5rem; }
      :local(.loading) .click-to-add > svg.isChecked {
        fill: #4db776; }
    :local(.loading) .click-to-remove > svg {
      fill: #de014f;
      width: 1.5rem;
      height: 1.5rem; }
      :local(.loading) .click-to-remove > svg.isChecked {
        fill: #4db776; } }
  :local(.loading) .click-to-add:hover > svg {
    fill: #45bbff;
    -webkit-transition: 0.35s;
    -moz-transition: 0.35s;
    transition: 0.35s;
    cursor: pointer; }
  :local(.loading) .zoom-place {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: 0.7s ease all;
    -webkit-transition: 0.7s ease all;
    -moz-transition: 0.7s ease all;
    margin: 0 auto;
    overflow-x: hidden;
    cursor: move; }
  :local(.loading) .zoom-place:hover {
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    transform: scale(1.02); }
  :local(.loading) #map {
    height: 100%; }
  :local(.loading) #description {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300; }
  :local(.loading) #infowindow-content .title {
    font-weight: bold; }
  :local(.loading) #infowindow-content {
    display: none; }
  :local(.loading) #map #infowindow-content {
    display: inline; }
  :local(.loading) .pac-card {
    margin: 10px 10px 0 0;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    font-family: Roboto; }
  :local(.loading) #pac-container {
    padding-bottom: 12px;
    margin-right: 12px; }
  :local(.loading) .pac-controls {
    display: inline-block;
    padding: 5px 11px; }
  :local(.loading) .pac-controls label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300; }
  :local(.loading) #pac-input {
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px; }
  :local(.loading) #pac-input:focus {
    border-color: #4d90fe; }
  :local(.loading) #title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px; }

.normal-root :local(.contentDetail) .container {
  display: unset; }

.normal-root :local(.contentDetail)#center-column {
  display: block; }

:local(.contentDetail) {
  display: flex;
  flex: 1;
  height: 100%; }
  :local(.contentDetail).display-unset {
    display: unset; }
  :local(.contentDetail) .warning-limit span {
    font-size: 13px;
    color: #dc3545; }
    :local(.contentDetail) .warning-limit span.anticon-warning {
      vertical-align: text-top; }
  :local(.contentDetail) .cover-image {
    margin-top: 15px;
    width: 100%;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: #e4e4e4;
    height: 120px;
    position: relative; }
    :local(.contentDetail) .cover-image > div {
      cursor: pointer;
      background: #fff;
      position: absolute;
      right: 10px;
      bottom: 10px;
      border-radius: 50%; }
      :local(.contentDetail) .cover-image > div > span {
        padding: 5px;
        color: #0f95de;
        font-size: 20px; }
  :local(.contentDetail) .row-menu {
    border-top: 1px solid #eeeeef;
    border-bottom: 1px solid #eeeeef;
    margin: 10px -15px; }
    :local(.contentDetail) .row-menu > div {
      display: flex;
      width: 100%;
      padding: 10px 0;
      align-items: center;
      justify-content: space-between; }
      :local(.contentDetail) .row-menu > div > .each-menu {
        cursor: pointer;
        display: flex;
        width: calc(100% / 6);
        color: #e79e3d;
        flex-direction: column; }
        :local(.contentDetail) .row-menu > div > .each-menu .anticon {
          color: #e79e3d; }
        :local(.contentDetail) .row-menu > div > .each-menu > span {
          font-size: 11px;
          text-align: center;
          white-space: nowrap;
          padding-top: 3px; }
          @media only screen and (max-width: 575px) {
            :local(.contentDetail) .row-menu > div > .each-menu > span {
              font-size: 9px; } }
        :local(.contentDetail) .row-menu > div > .each-menu svg {
          font-size: 26px; }
          @media only screen and (max-width: 575px) {
            :local(.contentDetail) .row-menu > div > .each-menu svg {
              font-size: 20px; } }
        :local(.contentDetail) .row-menu > div > .each-menu:hover {
          color: #c3832e; }
        :local(.contentDetail) .row-menu > div > .each-menu:last-child {
          border-left: 1px solid #eeeeef; }
  :local(.contentDetail) .overview-itinerary-detail {
    overflow: hidden;
    height: 100px;
    position: relative; }
    :local(.contentDetail) .overview-itinerary-detail.show-more-overview {
      height: auto; }
    :local(.contentDetail) .overview-itinerary-detail .trix-content-temp {
      line-height: 1.5; }
      :local(.contentDetail) .overview-itinerary-detail .trix-content-temp * {
        box-sizing: border-box; }
      :local(.contentDetail) .overview-itinerary-detail .trix-content-temp h1 {
        font-size: 1.2em;
        line-height: 1.2;
        margin: 0; }
      :local(.contentDetail) .overview-itinerary-detail .trix-content-temp pre {
        display: inline-block;
        width: 100%;
        vertical-align: top;
        font-family: monospace;
        font-size: 0.9em;
        margin: 0;
        padding: 0.5em;
        white-space: pre;
        background-color: #eee;
        overflow-x: auto; }
      :local(.contentDetail) .overview-itinerary-detail .trix-content-temp ul,
      :local(.contentDetail) .overview-itinerary-detail .trix-content-temp ol,
      :local(.contentDetail) .overview-itinerary-detail .trix-content-temp li {
        margin: 0;
        padding: 0; }
        :local(.contentDetail) .overview-itinerary-detail .trix-content-temp ul li,
        :local(.contentDetail) .overview-itinerary-detail .trix-content-temp ol li,
        :local(.contentDetail) .overview-itinerary-detail .trix-content-temp li li {
          margin-left: 1em; }
      :local(.contentDetail) .overview-itinerary-detail .trix-content-temp img {
        max-width: 100%;
        height: auto; }
      :local(.contentDetail) .overview-itinerary-detail .trix-content-temp .attachment {
        display: inline-block;
        position: relative;
        max-width: 100%;
        margin: 0;
        padding: 0; }
        :local(.contentDetail) .overview-itinerary-detail .trix-content-temp .attachment a {
          color: inherit;
          text-decoration: none; }
          :local(.contentDetail) .overview-itinerary-detail .trix-content-temp .attachment a:hover, :local(.contentDetail) .overview-itinerary-detail .trix-content-temp .attachment a:visited:hover {
            color: inherit; }
      :local(.contentDetail) .overview-itinerary-detail .trix-content-temp .attachment__caption {
        padding: 0;
        text-align: center; }
        :local(.contentDetail) .overview-itinerary-detail .trix-content-temp .attachment__caption .attachment__name + .attachment__size::before {
          content: " · "; }
      :local(.contentDetail) .overview-itinerary-detail .trix-content-temp .attachment--preview {
        width: 100%;
        text-align: left; }
        :local(.contentDetail) .overview-itinerary-detail .trix-content-temp .attachment--preview .attachment__caption {
          color: #666;
          font-size: 0.9em;
          line-height: 1.2; }
      :local(.contentDetail) .overview-itinerary-detail .trix-content-temp .attachment--file {
        color: #333;
        line-height: 1;
        margin: 0 2px 2px 0;
        padding: 0.4em 1em;
        border: 1px solid #bbb;
        border-radius: 5px; }
      :local(.contentDetail) .overview-itinerary-detail .trix-content-temp .attachment-gallery {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin: 0;
        padding: 0; }
        :local(.contentDetail) .overview-itinerary-detail .trix-content-temp .attachment-gallery .attachment {
          flex: 1 0 33%;
          padding: 0 0.5em;
          max-width: 33%; }
        :local(.contentDetail) .overview-itinerary-detail .trix-content-temp .attachment-gallery.attachment-gallery--2 .attachment, :local(.contentDetail) .overview-itinerary-detail .trix-content-temp .attachment-gallery.attachment-gallery--4 .attachment {
          flex-basis: 50%;
          max-width: 50%; }
  :local(.contentDetail) .break-section {
    margin: 1rem 0;
    padding: 5px;
    background: #e1eef5;
    text-align: center;
    font-weight: bold; }
  :local(.contentDetail) #update-now-button {
    background-color: #006601;
    color: #fff; }
  :local(.contentDetail) .btn-expand-collapse:hover {
    background: #dbdbdb; }
  :local(.contentDetail) .alert-buget-over {
    margin-top: 0.5rem; }
    :local(.contentDetail) .alert-buget-over .ant-alert-error {
      background: #ff0000; }
      :local(.contentDetail) .alert-buget-over .ant-alert-error span {
        color: #fff; }
  :local(.contentDetail) .tyneri-title {
    font-size: 18px;
    padding-top: 0.5rem; }
  :local(.contentDetail) .explore-favorite-bar {
    right: 0;
    width: 25%;
    padding: 0 15px 0 15px;
    margin-top: 94px;
    position: fixed;
    top: 0;
    transition-property: opacity;
    transition-duration: 0.25s;
    padding-bottom: 0.5rem;
    z-index: 0;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  :local(.contentDetail) .processing {
    color: #ffffff !important; }
    :local(.contentDetail) .processing > svg {
      height: unset;
      width: unset; }
  :local(.contentDetail) .row-add {
    position: sticky;
    bottom: 0;
    margin-top: auto;
    right: 50px; }
    :local(.contentDetail) .row-add .btn-add {
      width: 60px;
      height: 60px;
      min-height: 60px !important;
      background: #e79e3d;
      color: white;
      box-shadow: none; }
      :local(.contentDetail) .row-add .btn-add:hover {
        background-color: #c3832e;
        color: white; }
      :local(.contentDetail) .row-add .btn-add svg {
        width: 2rem;
        height: 2rem; }
  :local(.contentDetail) .checkbox-remove span {
    color: #de014f; }
  :local(.contentDetail) .checkbox-remove .checkbox {
    margin-right: 0.5rem; }
    :local(.contentDetail) .checkbox-remove .checkbox svg {
      fill: #de014f;
      width: 1em;
      height: 1em; }
  :local(.contentDetail) .btn-close {
    top: 8px;
    color: #9e9e9e;
    right: 8px;
    position: absolute;
    z-index: 9999; }
  @media (min-width: 992px) and (max-width: 1200px) {
    :local(.contentDetail) .btn-explore {
      margin-bottom: 15px; } }
  :local(.contentDetail) .row.description-icon-transport {
    position: absolute;
    bottom: 16px;
    width: 100%; }
    :local(.contentDetail) .row.description-icon-transport > .col-12 {
      color: #0099ff;
      text-align: right; }
      :local(.contentDetail) .row.description-icon-transport > .col-12 .row {
        display: contents; }
        :local(.contentDetail) .row.description-icon-transport > .col-12 .row > .each-type-transportation {
          display: inline-flex;
          margin: 1rem; }
          :local(.contentDetail) .row.description-icon-transport > .col-12 .row > .each-type-transportation > .icon {
            margin-right: 0.5rem; }
  :local(.contentDetail) .btn-primary {
    background-color: #0099ff;
    color: white;
    box-shadow: none; }
    :local(.contentDetail) .btn-primary:hover {
      background-color: #1389d8;
      color: white; }
  :local(.contentDetail) .written-by {
    font-size: 13px;
    align-self: center;
    color: #9a9a9a; }
    :local(.contentDetail) .written-by .author-name {
      font-style: italic;
      color: #9e9e9e;
      display: contents; }
  :local(.contentDetail) .preview-itinerary a {
    color: #9e9e9e; }
    :local(.contentDetail) .preview-itinerary a:hover {
      text-decoration: none;
      color: #0882e6; }
  :local(.contentDetail) .preview-itinerary:hover {
    text-decoration: none; }
  :local(.contentDetail) .share-by-email {
    color: #9e9e9e; }
    :local(.contentDetail) .share-by-email:hover {
      text-decoration: none;
      color: #a81111; }
  :local(.contentDetail) #save-tinery {
    background: #e1eef5;
    position: sticky;
    bottom: 0;
    width: auto;
    justify-content: center;
    padding: 0;
    margin-top: auto;
    z-index: 9; }
    :local(.contentDetail) #save-tinery .save-itinerary {
      background-color: #4caf50;
      color: white;
      border-radius: 0;
      font-size: 16px; }
      :local(.contentDetail) #save-tinery .save-itinerary svg {
        width: 1.5rem;
        height: 1.5rem; }
      :local(.contentDetail) #save-tinery .save-itinerary:hover {
        background-color: #3b863e;
        color: white; }
    :local(.contentDetail) #save-tinery .main-action {
      margin: 1rem 0; }
      :local(.contentDetail) #save-tinery .main-action .btn-load {
        margin-right: 0.5rem;
        background-color: #05afbe;
        color: white; }
        :local(.contentDetail) #save-tinery .main-action .btn-load:hover {
          background-color: #10919c;
          color: white; }
        @media (min-width: 992px) and (max-width: 1200px) {
          :local(.contentDetail) #save-tinery .main-action .btn-load {
            margin-right: 0;
            margin-bottom: 0.5rem !important; } }
      :local(.contentDetail) #save-tinery .main-action .save-itinerary {
        background-color: #4caf50;
        color: white; }
        :local(.contentDetail) #save-tinery .main-action .save-itinerary:hover {
          background-color: #3b863e;
          color: white; }
    :local(.contentDetail) #save-tinery .secondary-action > button {
      border-radius: 0;
      color: #fff !important; }
      :local(.contentDetail) #save-tinery .secondary-action > button.MuiButtonGroup-groupedContainedPrimary:not(:last-child) {
        border-right: 1px solid #fff !important; }
      :local(.contentDetail) #save-tinery .secondary-action > button:first-child {
        width: 200%; }
    :local(.contentDetail) #save-tinery .secondary-action .btn-save-to-folder .section-btn-move-to {
      display: flex; }
      :local(.contentDetail) #save-tinery .secondary-action .btn-save-to-folder .section-btn-move-to svg {
        margin: 0 auto; }
      :local(.contentDetail) #save-tinery .secondary-action .btn-save-to-folder .section-btn-move-to span {
        align-self: center; }
  :local(.contentDetail) div.title {
    padding: 3px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-flex;
    display: flex;
    box-flex: 1;
    -moz-flex: 1 1 auto;
    flex: 1 1 auto;
    justify-content: space-between; }
    :local(.contentDetail) div.title div.switch {
      margin-right: 0.5rem; }
    :local(.contentDetail) div.title div.MuiChip-root {
      margin-right: 0.5rem;
      height: 22px; }
    :local(.contentDetail) div.title > svg {
      fill: #0f95de; }
    :local(.contentDetail) div.title .setting-tyneri {
      cursor: pointer; }
      :local(.contentDetail) div.title .setting-tyneri > svg {
        fill: #7c8e9c; }
      :local(.contentDetail) div.title .setting-tyneri:hover > svg {
        fill: #0f95de; }
  :local(.contentDetail) .rc-menu-horizontal > .rc-menu-submenu,
  :local(.contentDetail) .rc-menu-horizontal > .rc-menu-item {
    background: #de014f;
    border-bottom: 2px solid #de014f !important; }
  :local(.contentDetail) .none-background {
    background-color: unset;
    border-bottom: unset; }
  :local(.contentDetail) .vertical-straight-list-selected {
    width: 100% !important; }
    @media (min-width: 992px) and (max-width: 1200px) {
      :local(.contentDetail) .vertical-straight-list-selected {
        margin-bottom: 3rem !important; } }
    @media (min-width: 576px) {
      :local(.contentDetail) .vertical-straight-list-selected > div:first-child {
        margin-left: 30% !important; } }
    @media (min-width: 992px) and (max-width: 1200px) {
      :local(.contentDetail) .vertical-straight-list-selected > div:first-child {
        margin-left: 5% !important; } }
    @media (min-width: 1200px) and (max-width: 1600px) {
      :local(.contentDetail) .vertical-straight-list-selected > div:first-child {
        margin-left: 4% !important; } }
    @media (min-width: 1900px) {
      :local(.contentDetail) .vertical-straight-list-selected > div:first-child {
        margin-left: 5% !important; } }
  :local(.contentDetail) #title-itinerary-detail {
    min-height: 61px;
    align-items: center;
    font-size: 1.25rem; }
    :local(.contentDetail) #title-itinerary-detail > span {
      margin: 0 auto; }
  :local(.contentDetail) #itinerary-detail {
    padding: 0;
    background: #f7f7f7; }
    :local(.contentDetail) #itinerary-detail > .col-12 {
      padding-right: 0; }
  :local(.contentDetail) #date-info {
    z-index: 99;
    top: 0;
    background: #fff;
    padding-top: 1rem;
    position: sticky;
    width: 100%;
    display: inline-block; }
  :local(.contentDetail) .row-calendar-slider .slider-custom .slick-prev {
    left: -10px;
    height: 100%; }
  :local(.contentDetail) .row-calendar-slider .slider-custom .slick-next {
    right: -10px;
    height: 100%; }
  :local(.contentDetail) .row-calendar-slider .slider-custom .slick-prev, :local(.contentDetail) .row-calendar-slider .slider-custom .slick-next {
    border-radius: 0; }
    :local(.contentDetail) .row-calendar-slider .slider-custom .slick-prev:hover, :local(.contentDetail) .row-calendar-slider .slider-custom .slick-next:hover {
      width: 40px; }
  :local(.contentDetail) #tab-select {
    padding: 1rem 0; }
  :local(.contentDetail) #date-info + div.row > div {
    width: 100%; }
  :local(.contentDetail) .background-list-selected {
    background: #f0fbff; }
  :local(.contentDetail) .container {
    display: flex;
    max-width: 100%;
    background: #fff;
    box-shadow: 1px 5px 30px rgba(114, 182, 234, 0.2);
    flex-direction: column; }
    @media only screen and (max-width: 991px) {
      :local(.contentDetail) .container {
        display: unset; } }
    @media only screen and (max-width: 767px) {
      :local(.contentDetail) .container {
        min-height: auto; } }
    :local(.contentDetail) .container #fixed-height {
      flex: 1;
      height: 100%; }
      :local(.contentDetail) .container #fixed-height > div {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto; }
  :local(.contentDetail) .tutorial {
    margin-top: 0.45rem;
    padding: 5rem 1.5rem 10rem 1.5rem !important;
    text-align: center;
    border-width: 2px;
    border-style: dashed;
    border-color: #0f95de;
    background: rgba(231, 158, 61, 0.3) !important; }
  :local(.contentDetail) section {
    font-size: unset !important;
    font-weight: unset !important; }
  :local(.contentDetail) ._t-flex-col {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-flex-direction: column; }
  :local(.contentDetail) .t-flex {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex; }
  :local(.contentDetail) .t-rem-padding-top-3 {
    padding-top: 3rem; }
  :local(.contentDetail) .t-rem-padding {
    padding: 1rem; }
  :local(.contentDetail) .t-text-center {
    text-align: center; }
  :local(.contentDetail) .t-primary {
    color: #0f95de; }
  :local(.contentDetail) .t-rem-padding-bottom-fourth {
    padding-bottom: 0.25rem; }
  :local(.contentDetail) .t-margin-auto {
    margin: 0 auto; }
  :local(.contentDetail) .t-svg-xlarge svg {
    width: 3.75rem;
    height: 3.75rem; }
  :local(.contentDetail) .t-primary svg {
    fill: #0f95de; }
  :local(.contentDetail) .t-text-center {
    text-align: center; }
  :local(.contentDetail) .t-rem-padding-bottom-fourth {
    padding-bottom: 0.25rem; }
  :local(.contentDetail) .t-h3 {
    font-size: 1.4rem;
    line-height: 1.4rem; }
  :local(.contentDetail) .t-text-center {
    text-align: center; }
  :local(.contentDetail) .t-rem-padding-bottom-fourth {
    padding-bottom: 0.25rem; }
  :local(.contentDetail) .t-color-gray-dark {
    color: #627e8c; }
  :local(.contentDetail) form {
    margin: 0 auto; }
    :local(.contentDetail) form .form-control {
      border: 1px solid #e1eef5; }
    :local(.contentDetail) form#form-search .form-control {
      border: none !important; }
  :local(.contentDetail) .btn-primary {
    background-color: #0099ff;
    color: white;
    box-shadow: none; }
    :local(.contentDetail) .btn-primary:hover {
      background-color: #1389d8;
      color: white; }
  :local(.contentDetail) .btn-done {
    color: #fff;
    background-color: #81bc2e;
    border-top-color: #76ac2a;
    border-left-color: #76ac2a;
    border-right-color: #76ac2a;
    border-bottom-color: #70a328;
    border-color: transparent; }
    :local(.contentDetail) .btn-done:hover {
      background-color: #6eb111; }
  :local(.contentDetail) button:focus {
    outline: none !important; }
  :local(.contentDetail) .table thead th {
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6; }
  :local(.contentDetail) .table td,
  :local(.contentDetail) .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: unset; }
  :local(.contentDetail) .table td,
  :local(.contentDetail) .table th {
    padding: 0.3rem !important; }
  :local(.contentDetail) .border-timeline div {
    box-shadow: none !important; }
  :local(.contentDetail) div.border-timeline1 > div + div + div {
    display: none; }
  :local(.contentDetail) table tr td {
    position: relative; }
  :local(.contentDetail) .bottom {
    bottom: 0;
    margin: 0 auto; }
  :local(.contentDetail) .css-1bhxhu4 {
    width: 70px;
    height: 70px;
    margin-right: 35px;
    margin-top: 20px;
    flex-shrink: 0;
    -webkit-box-flex: 0;
    flex-grow: 0;
    border-radius: 50%; }
  :local(.contentDetail) .column-new-stop {
    display: inline-grid;
    align-items: center; }
  :local(.contentDetail) .trip-day-header-labels-inner.t-editable {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  :local(.contentDetail) .trip-day-header-labels {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; }
  :local(.contentDetail) .trip-day-header-labels-inner {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; }
    :local(.contentDetail) .trip-day-header-labels-inner:hover {
      background-color: #fff8dc; }
  :local(.contentDetail) .t-editable {
    cursor: pointer;
    -webkit-transition-property: background-color;
    -moz-transition-property: background-color;
    transition-property: background-color;
    -webkit-transition-duration: 0.1s;
    -moz-transition-duration: 0.1s;
    transition-duration: 0.1s; }
  :local(.contentDetail) .trip-day-header-date {
    text-align: center;
    font-weight: 700;
    border-right: 1px solid #d7dfe3;
    padding-right: 0.5rem;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto; }
  :local(.contentDetail) .trip-day-header-date-month {
    text-transform: uppercase; }
  :local(.contentDetail) .trip-day-header-date-day {
    font-weight: 700; }
  :local(.contentDetail) .trip-day-header-date + .trip-day-header-title {
    padding-left: 0.5rem;
    margin: 0 auto; }
  :local(.contentDetail) .trip-day-header-title {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    font-size: 1.25rem;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    overflow: hidden;
    text-overflow: clip;
    white-space: inherit; }
  :local(.contentDetail) .trip-day-header-title span + span {
    padding-right: 0.25rem;
    position: relative;
    max-width: calc(100% - 1rem);
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 1 auto;
    -moz-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  :local(.contentDetail) .trip-day-header-title figure {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto; }
  :local(.contentDetail) .trip-day-header-title figure._has-date {
    font-size: 18px;
    color: #0f95de; }
  :local(.contentDetail) .trip-sidebar-tabbar li {
    width: 100%;
    position: relative;
    top: 1px;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    background: #fff; }
  :local(.contentDetail) .trip-sidebar-tabbar li._is-selected {
    background: #0f95de; }
  :local(.contentDetail) .trip-sidebar-tabbar li {
    width: 100%;
    padding: 1rem;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    border-bottom: 1px solid transparent;
    padding: 0.5rem;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 1 auto;
    -moz-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    background: #fff; }
  :local(.contentDetail) [data-ember-action] {
    cursor: pointer; }
  :local(.contentDetail) .trip-sidebar-tabbar li._is-selected span {
    color: #fff; }
  :local(.contentDetail) .trip-sidebar-tabbar li span {
    text-align: center; }
  :local(.contentDetail) .trip-sidebar-tabbar li span {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    color: black; }
  :local(.contentDetail) #select-my-places {
    padding: 0 15px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden; }
  :local(.contentDetail) #select-favorite-places {
    padding: 0 15px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden; }
  @media only screen and (min-width: 60em) {
    :local(.contentDetail) .trip-event-list-blank-state {
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
      box-orient: horizontal;
      -webkit-box-direction: normal;
      -moz-box-direction: normal;
      box-direction: normal;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      flex-direction: row;
      -ms-flex-direction: row;
      -webkit-box-align: start;
      -moz-box-align: start;
      box-align: start;
      -webkit-align-items: flex-start;
      -moz-align-items: flex-start;
      -ms-align-items: flex-start;
      -o-align-items: flex-start;
      align-items: flex-start;
      -ms-flex-align: start;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      box-pack: justify;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      -o-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: justify; } }
  :local(.contentDetail) .trip-event-list-blank-state {
    padding-left: 9.5rem;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -moz-box-pack: end;
    box-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    -o-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: end; }
  :local(.contentDetail) .component-explainer-arrow._subtle {
    color: #7d97a4; }
  :local(.contentDetail) .component-explainer-arrow.on-left {
    -webkit-box-align: start;
    -moz-box-align: start;
    box-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    -o-align-items: flex-start;
    align-items: flex-start;
    -ms-flex-align: start; }
  :local(.contentDetail) .component-explainer-arrow.marker-arrow-up {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-flex-direction: column; }
  @media only screen and (min-width: 60em) {
    :local(.contentDetail) .trip-event-list-blank-date {
      display: -webkit-box;
      display: -moz-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex; } }
  :local(.contentDetail) .trip-event-list-blank-date {
    position: relative;
    top: -2rem;
    max-width: 20rem;
    padding-right: 1rem;
    display: none;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 1 auto;
    -moz-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto; }
  :local(.contentDetail) .component-explainer-arrow {
    text-align: center;
    color: #679625;
    max-width: 100%;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    -o-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: justify; }
  @media only screen and (min-width: 60em) {
    :local(.contentDetail) .trip-event-list-blank-state {
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
      box-orient: horizontal;
      -webkit-box-direction: normal;
      -moz-box-direction: normal;
      box-direction: normal;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      flex-direction: row;
      -ms-flex-direction: row;
      -webkit-box-align: start;
      -moz-box-align: start;
      box-align: start;
      -webkit-align-items: flex-start;
      -moz-align-items: flex-start;
      -ms-align-items: flex-start;
      -o-align-items: flex-start;
      align-items: flex-start;
      -ms-flex-align: start;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      box-pack: justify;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      -o-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: justify; } }
  :local(.contentDetail) .component-explainer-arrow.marker-arrow-up ._bracket-wrap {
    width: 100%;
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    box-ordinal-group: 2;
    -webkit-order: 2;
    -moz-order: 2;
    order: 2;
    -ms-flex-order: 2; }
  :local(.contentDetail) .component-explainer-arrow ._bracket-wrap {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
  :local(.contentDetail) .component-explainer-arrow ._bracket {
    position: relative;
    z-index: 10;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto; }
  :local(.contentDetail) .component-explainer-arrow ._bracket svg {
    height: 7rem;
    width: 2rem; }
  :local(.contentDetail) .component-explainer-arrow._subtle ._inner {
    background-color: #f2f5f6; }
  :local(.contentDetail) .component-explainer-arrow ._inner {
    background-color: #f2f8ea;
    padding: 0.75rem 0.5rem;
    border-radius: 3rem;
    margin: 0 -0.75rem;
    position: relative;
    z-index: 0;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-transform: rotate(-1deg);
    -moz-transform: rotate(-1deg);
    -ms-transform: rotate(-1deg);
    -o-transform: rotate(-1deg);
    transform: rotate(-1deg); }
  :local(.contentDetail) .component-explainer-arrow.on-left ._arrow {
    padding-left: 2rem;
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  :local(.contentDetail) .component-explainer-arrow.marker-arrow-up ._arrow {
    margin-bottom: -1rem;
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    box-ordinal-group: 1;
    -webkit-order: 1;
    -moz-order: 1;
    order: 1;
    -ms-flex-order: 1; }
  :local(.contentDetail) .component-explainer-arrow ._arrow {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-left: 1rem; }
  :local(.contentDetail) .component-explainer-arrow.marker-arrow-up ._arrow svg {
    transform: rotate(-50deg);
    width: 3rem;
    height: 5rem; }
  :local(.contentDetail) .component-explainer-arrow._subtle svg {
    fill: #b9c7ce; }
  :local(.contentDetail) .component-explainer-arrow ._arrow svg {
    width: 5rem;
    height: 3rem; }
  :local(.contentDetail) .component-explainer-arrow svg {
    fill: #81bc2e; }
  :local(.contentDetail) .component-explainer-arrow.on-right {
    -webkit-box-align: end;
    -moz-box-align: end;
    box-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    -o-align-items: flex-end;
    align-items: flex-end;
    -ms-flex-align: end; }
  :local(.contentDetail) .trip-event-list-blank-add {
    position: relative;
    top: -2rem;
    right: 2rem;
    max-width: 30rem;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 1 auto;
    -moz-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto; }
  :local(.contentDetail) .component-explainer-arrow.on-right ._arrow {
    padding-right: 18rem; }
  :local(.contentDetail) .filter {
    padding: 0; }
    :local(.contentDetail) .filter .main-menu-container {
      margin-bottom: 0;
      padding: 0; }
      :local(.contentDetail) .filter .main-menu-container .ant-menu-submenu.ant-menu-submenu-inline
.ant-menu-submenu-title
.anticon {
        line-height: 65px; }
  :local(.contentDetail) #sidebar {
    background: #f7f7f7; }
    @media only screen and (max-width: 991px) {
      :local(.contentDetail) #sidebar {
        display: none; } }
    :local(.contentDetail) #sidebar #detail-tab-select #select-explore {
      padding: 15px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column; }
      :local(.contentDetail) #sidebar #detail-tab-select #select-explore .section-location-folder {
        display: flex;
        justify-content: space-between;
        margin: 0 -15px 15px -15px;
        padding: 0 15px;
        font-size: 20px; }
        :local(.contentDetail) #sidebar #detail-tab-select #select-explore .section-location-folder .folder-menu {
          padding: 5px;
          font-size: 15px;
          align-self: center;
          color: #e9870a;
          cursor: pointer; }
          :local(.contentDetail) #sidebar #detail-tab-select #select-explore .section-location-folder .folder-menu:hover {
            color: #ffba5f; }
      :local(.contentDetail) #sidebar #detail-tab-select #select-explore div#droppable2 {
        overflow-x: hidden; }
    :local(.contentDetail) #sidebar .top-right-container {
      height: 88px;
      width: 100%;
      padding: 15px;
      background: #e1eef5; }
      :local(.contentDetail) #sidebar .top-right-container .menu-login {
        padding: 4px 0; }
        :local(.contentDetail) #sidebar .top-right-container .menu-login .dropdown-hover {
          padding: 4.5px 0; }
  @media only screen and (max-width: 991px) {
    :local(.contentDetail) #center-column {
      margin-left: 0 !important;
      padding: 0 10px !important; } }
  :local(.contentDetail) #left-column {
    background: #f7f7f7;
    width: 100%; }
    @media only screen and (max-width: 991px) {
      :local(.contentDetail) #left-column {
        position: static !important; }
        :local(.contentDetail) #left-column #save-tinery {
          width: calc(100% + 30px);
          position: sticky;
          z-index: 1; } }
  @media only screen and (max-width: 767px) {
    :local(.contentDetail) #center-column {
      margin-left: 100% !important;
      opacity: 0;
      display: none !important; }
    :local(.contentDetail) #left-column {
      position: unset !important;
      min-height: auto !important; }
      :local(.contentDetail) #left-column #itinerary-detail {
        height: auto;
        margin-bottom: 60px; }
    :local(.contentDetail) #sidebar {
      display: none; }
    :local(.contentDetail) #save-tinery {
      width: calc(100% + 30px); } }
