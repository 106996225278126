.row-country-slider {
  .each-country-scroll {
    padding: 8px;
    float: left;
    .each-country-scroll__content {
      border: 2px solid #efefef;
      padding: 8px;
      justify-content: center;
      color: #1a2b49;
      font-weight: 400;
      display: flex;
      &:hover {
        transition: box-shadow 0.3s ease-in-out;
        -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
        cursor: pointer;
      }
    }
  }
}
