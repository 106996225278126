@import "../../assets/sass/variables.scss";

#root {
  height: 100vh;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: $screen-md-max) {
    height: auto;
    display: unset;
  }
  &.normal-root {
    height: auto;
    display: unset;
  }
}
.responsiveTable {
  thead {
    th {
      font-size: 15px;
    }
  }
  tr {
    td {
      padding: 10px;
    }
  }
}
body.swal2-height-auto {
  height: inherit !important;
}

.admin-layout {
  height: 100%;
  width: 100%;
  /*  table */
  .ant-table-body {
    overflow-x: auto !important;
    @media screen and (max-width: $screen-sm) {
      overflow-x: auto !important;
    }
  }
  .ant-layout {
    background-color: #e3e8ee;
  }
  .td-action {
    text-align: right;
  }
  .ant-table-thead th {
    font-weight: bold;
  }

  /* login */
  .menu-login {
    margin-bottom: 0px;
    .ant-avatar-circle {
      text-align: center;
      img {
        width: 40px;
        height: 40px;
        border-radius: 100px;
        margin: 0 auto;
      }
    }
    .user-text {
      color: white;
    }
    .ant-layout-sider-zero-width-trigger {
      z-index: 10;
      top: 130px;
    }
  }
  /*left content */
  .ant-menu-dark,
  .ant-layout-sider-trigger,
  .ant-layout-sider-children {
    background-color: #1b4268 !important;
  }
  .main-menu-container {
    .menu-and-logo {
      flex-direction: row;
      display: flex;
      background-color: #1b4268;
      align-items: center;
      width: 100%;
      padding-left: 15px;
      .logo-container {
        padding: 0 10px;
        position: static;
        .main-logo {
          width: 100%;
        }
      }
      .ant-menu-horizontal {
        border-bottom: 0;
        .ant-menu-submenu {
          border-bottom: 0;
          .ant-menu-submenu-title {
            padding: 0 10px;
            .anticon {
              font-size: 24px;
              color: white;
              margin-right: 0;
            }
          }
          .ant-menu-sub {
            .ant-menu-item {
              width: 100%;
              padding: 0 15px !important;
              a {
                color: white;
              }
            }
          }
        }
      }
      .ant-menu-inline {
        width: 100%;
        .ant-menu-submenu-title {
          float: none;
          left: 0;
          padding: 0 32px !important;
          text-overflow: clip;
          font-size: 14px;
          height: 40px;
          line-height: 40px;
          .anticon {
            margin: 0;
            font-size: 16px;
            line-height: 40px;
          }
        }
      }
    }
  }
  .ant-layout-sider {
    &.ant-layout-sider-collapsed {
      .main-menu-container {
        .menu-and-logo {
          .logo-container {
            display: none;
          }
        }
      }
    }
    @media screen and (max-width: $screen-md) {
      z-index: 10;
    }
    .button-tyneri-content {
      padding: 0 10px;
      width: 100%;
      bottom: 10px;
      position: absolute;
    }
  }
  .ant-layout-sider-children {
    background-color: #1b4268;
  }
  .ant-layout-sider-zero-width {
    > .ant-layout-sider-children {
      background-color: #1b4268;
      @media screen and (max-width: $screen-md) {
        width: 0px !important;
      }
    }
  }
  /* right content */
  .abs-content {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    height: 100%;
    .box-scroll {
      width: 100%;
      height: auto;
      > .content-right {
        height: 100%;
        overflow: auto;
        width: 100%;
      }
    }
    @media screen and (max-width: $screen-md) {
      margin-left: 0px;
    }
  }
  .site-layout-background {
    padding: 30px;
    overflow-y: hidden;
    height: 100%;
    overflow-x: hidden;
  }
  .menu-custom {
    .ant-menu-item {
      color: white;
      padding-left: initial;
      @media only screen and (max-width: $screen-md-max) {
        font-size: inherit;
        display: flex;
        align-items: center;
      }
      svg {
        font-size: 18px;
        vertical-align: unset;
      }
      .block {
        float: right;
      }
    }
    .create-tyneri {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.ant-menu-inline-collapsed {
      .create-tyneri {
        padding: 0 15px;
        span {
          display: none;
        }
      }
    }
  }
  .header-admin {
    padding: 0 20px;
    justify-content: space-between;
    display: flex;
    background-color: #265c90;
    .name-page {
      color: white;
      font-size: 1.5rem;
      @media screen and (max-width: $screen-xs-max) {
        font-size: 1rem;
      }
    }
    @media screen and (max-width: $screen-sm-max) {
      height: auto;
      width: 100%;
      float: left;
      padding: 10px;
    }
    .menu {
      display: flex;
      float: right;
      .menu-login {
        color: black;
        .user-text {
          color: black;
        }
        .anticon {
          vertical-align: 0px !important;
          font-size: 14px;
        }
        .dropdown-hover {
          padding: 0;
        }
      }
      .create-tyneri {
        @media only screen and (max-width: $screen-xs-max) {
          font-size: 16px;
        }
      }
    }
  }
  .ant-layout-sider-zero-width-trigger {
    @media screen and (max-width: $screen-sm) {
      position: absolute;
      top: 0px;
      right: -30px;
      z-index: 1;
      width: 30px;
      height: 66px;
      color: #fff;
      font-size: 20px;
      line-height: 58px;
      text-align: center;
      background: #001529;
      border-radius: 0 4px 4px 0;
      cursor: pointer;
      transition: background 0.3s ease;
    }
  }
}
