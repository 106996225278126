@import "../../assets/sass/variables.scss";

.company-layout {
  /* header */
  &.ant-layout{
    height: fit-content;
  }
  .ant-layout-header {
    background-color: white;
    padding: 0 10px;
    height: auto;
    .main-menu-container {
      .menu-and-logo {
        .ant-menu-submenu-title {
          height: auto;
        }
        .logo-container {
          top: -8px;
        }
      }
      @media screen and (max-width: $screen-sm){
        width: 100%;
        float: left;
      }
    }
    .header {
      width: 100%;
      justify-content: space-between;
      display: flex;
      @media screen and (max-width: $screen-sm){
        justify-content: initial;
        display: inline-block;
        float: left;
      }
      .main-menu-subdomain {
        @media screen and (max-width: $screen-sm){
          justify-content: space-between;
          display: flex;
        }
        .sign-in {
          position: absolute;
          right: 15px;
        }
      }
      .menu-container {
        .menu-login {
          .menu-and-button {
            .hover-effect {
              > * {
                z-index: 2;
              }
              &:before, &:after {
                z-index: 1;
              }
            }
          }
        }
      }
      .logo-img {
        width: 100px;
      }
      .menu-container {
        text-align: right;
        display: flex;
        ul {
          display: inline-flex;
          border-bottom: 0;
          li {
            width: 100%;
            margin: 0 10px;
            .button-tyneri-content {
              width: 200px;
              @media screen and (max-width: $screen-sm){
                width: 100%;
              }
              .add-new-tyneri {
                padding: 0;
                margin-bottom: 0px;
              }
            }
            @media screen and (max-width: $screen-sm){
              margin: 0px;
            }
          }
          li.sign-in {
            @media screen and (max-width: $screen-sm){
              display: none;
            }
          }
          @media screen and (max-width: $screen-sm){
            display: inline;
            width: 100%;
            margin: 0px;
          }
        }
        @media screen and (max-width: $screen-sm){
          width: 100%;
          float: left;
        }
      }
    }
  }
  /* banner */
  .banner {
    width: 100%;
    background-size: cover !important;
    background-position: center !important;
    padding-top: 30%;
    position: relative;
  }
  /* content */
  .content {
    .children {
      //max-width: 1040px;
      margin: 0 auto;
      @media screen and (max-width: $screen-sm) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
