@import "../../assets/sass/variables.scss";

@media (max-width: $screen-sm) {
  .container-card {
    margin: 1rem 0;
  }
}

.each-option {
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
  display: inherit;
  &.more {
    font-size: 18px;
  }
}

.option-pref {
  font-size: 15px;
  color: #e33c59;
}

.option-age {
  font-size: 15px;
  color: #31a2ec;
}

.option-type {
  font-size: 15px;
  color: #f3aa30;
}

span.views {
  display: block;
  font-size: 14px;
  color: #fff;
  text-align: right;
  >.anticon {
    vertical-align: initial;
  }
}
