@import "../../../assets/sass/variables.scss";

.form-confirm-container {
  #body-content {
    width: 100%;
    float: left;
    background-color: white;
    padding: 10px;
    page-break-inside: avoid;
  }
  #body-content {
    li {
      width: 100% !important;
      height: 800px;
    }
  }
  #body-content .header {
    page-break-inside: avoid;
    justify-content: space-between;
    display: flex;
    width: 100%;
    @media screen and (max-width: $screen-sm-max) {
      display: inline;
    }
  }
  #body-content .header .user-quotation{
    display: inline-block;
    .user-content{
      width: fit-content;
      display: flex;
      margin-bottom: 10px;
      font-size: 15px;
      i {
        vertical-align: 0px;
        line-height: 20px;
        font-size: 26px;
        margin-right: 15px;
      }
    }
  }
  #body-content .header .company-info-container{
    display: flex;
  }

  #body-content .header .companyLogo {
    page-break-inside: avoid;
    width: 100px;
  }
  #body-content .header .headerText {
    page-break-inside: avoid;
    font-size: 15px;
    margin-bottom: 5px;
    color: black;
  }
  #body-content .header .companyInfo {
    display: grid;
    margin-right: 10px;
  }

  #body-content .image-container {
    width: 100%;
    float: left;
    background-size: cover !important;
    background-position: center !important;
    min-height: 300px;
    margin-top: 10px;
  }
  #body-content .image-container img {
    width: 100%;
  }

  #body-content .itinerary-description {
    width: 100%;
    float: left;
    margin-top: 10px;
    page-break-inside: avoid;
  }

  #body-content .itinerary-description h3 {
    font-size: 25px;
    font-weight: bold;
  }

  #body-content .itinerary-over-view {
    width: 100%;
    float: left;
    margin-top: 10px;
    page-break-after: always;
  }

  #body-content .itinerary-over-view h3 {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid #cdcdcd;
  }
  #body-content .itinerary-over-view .days {
    width: 100%;
    float: left;
    margin-top: 10px;
  }
  #body-content .itinerary-over-view .days .day-item {
    page-break-inside: avoid;
    width: 50%;
    float: left;
    padding-left: 15px;
    font-size: 14px;
    margin-bottom: 15px;
  }
  #body-content .itinerary-over-view .days .day-item .day-description {
    margin-top: 5px;
  }

  @media only screen and (max-width: $screen-md-max) {
    #body-content .itinerary-over-view .days .day-item {
      width: 100%;
    }
  }

  #body-content .itinerary-over-view .days .day-item .day-header .day-index {
    background-color: #13b2da;
    padding: 4px;
    color: white;
    font-size: 14px;
    margin-right: 5px;
    border-radius: 4px;
  }
  #body-content .itinerary-over-view .days .day-item .day-header .start_date {
    color: grey;
    font-size: 14px;
  }

  #body-content .pricing-container {
    width: 100%;
    float: left;
    margin-top: 10px;
  }

  #body-content .pricing-container h3 {
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid #cdcdcd;
    margin-bottom: 10px;
  }

  #body-content .pricing-container .info-pricing {
    display: flex;
    text-align: center;
    font-size: 15px;
  }
  #body-content .pricing-container .info-pricing .item {
    display: grid;
    margin-right: 40px;
  }

  #body-content .pricing-container .info-pricing .item .value {
    color: grey;
  }

  #body-content .detail-container {
    width: 100%;
    float: left;
    margin-top: 20px;
  }

  #body-content .detail-container h3 {
    text-align: center;
    background-color: #f8f7f5;
    color: black;
    padding: 5px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 20px;
  }
  #body-content .detail-container .day-item {
    margin-top: 10px;
    border-top: 1px solid #e4e4e4;
    padding-top: 10px;
  }

  #body-content .detail-container .day-item .day-header {
    justify-content: space-between;
    display: flex;
  }

  #body-content .detail-container .day-item .day-header .day-index {
    font-size: 18px;
  }

  #body-content .detail-container .day-item .address {
    font-size: 14px;
    color: grey;
  }

  #body-content .day-item .day-info .advance {
    font-size: 14px;
  }
  #body-content .day-item .stops .responsiveTable {
    border-collapse: separate;
    border-spacing: 0 0.4em;
  }

  #body-content .day-item .stops .stop-item {
    margin-top: 10px;
    font-size: 14px;
    page-break-inside: avoid;
  }
  #body-content .day-item .stops .stop-item .time {
    display: block;
    color: #a39c9c;
  }
  #body-content .day-item .stops .stop-item .budget {
    color: black;
    font-size: 15px;
    text-align: left;
    display: grid;
  }
  #body-content .day-item .stops .stop-item .skip-btn {
    width: max-content;
    background-color: green;
    color: white;
  }
  #body-content .day-item .stops .stop-item .reject-td {
    display: block;
  }
  #body-content .day-item .stops .stop-item .activity {
    margin-bottom: 25px;
  }
  #body-content .day-item .stops .stop-item .activity .content {
    padding-right: 10px;
  }
  #body-content .day-item .stops .responsiveTable thead {
    font-size: 14px;
    background-color: #f3f3ff;
    color: grey;
  }

  #body-content .day-item .stops .responsiveTable thead th {
    margin-bottom: 10px;
    padding: 7px 0px;
  }
  #body-content .day-item .stops .responsiveTable thead th.time {
    padding-left: 10px;
  }

  #body-content
    .day-item
    .stops
    .stop-item
    .duration
    .anticon.anticon-clock-circle {
    vertical-align: 0.125em;
    margin-right: 5px;
  }

  #body-content .other-information {
    margin-top: 15px;
    width: 100%;
    float: left;
  }
  #body-content .other-information .text-information {
    text-align: center;
    background-color: #f8f7f5;
    color: black;
    padding: 5px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 20px;
  }

  #body-content .other-information .summary .text-summary {
    border-bottom: 1px solid #cdcdcd;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size: 18px;
  }

  #body-content .other-information .summary table {
    width: 100%;
    float: left;
    margin-bottom: 10px;
  }

  #body-content .other-information .bottom-content {
    width: 100%;
    float: left;
  }

  #body-content .other-information .bottom-content .title {
    font-size: 18px;
    float: left;
    width: 100%;
    border-top: 1px solid #cdcdcd;
    padding-top: 10px;
  }
  .submit-container {
    .ant-btn-primary {
      margin-left: 10px;
    }
  }
}
