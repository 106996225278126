@import "../../assets/sass/variables.scss";
.content-view-top-list {
  .ant-timeline-item-content {
    margin: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #e8e8e8;
    .ant-page-header {
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @media only screen and (max-width: $screen-xs-max) {
        flex-direction: column;
      }
    }
  }

  .ant-timeline-item {
    &:first-child {
      .ant-timeline-item-content {
        border-top: 1px solid #e8e8e8;
        padding-top: 20px;
      }
    }
    &:last-child {
      .ant-timeline-item-content {
        border-bottom: none;
      }
    }
  }

  .ant-timeline-item-tail {
    position: unset;
  }
  .ant-page-header-content {
    padding: 0;
    flex: 1;
    .box-content {
      display: flex;
      @media only screen and (max-width: $screen-xs-max) {
        flex-direction: column;
      }
      .stop-image {
        flex: 0 1 30%;
        margin-right: 10px;
        @media only screen and (max-width: $screen-xs-max) {
          margin-right: 0;
          flex: 1;
          margin-bottom: 10px;
        }
      }
      .desc-stop {
        flex: 1;
      }
    }
  }
  .ant-page-header-heading {
    flex: 0 1 13%;
    width: auto;
    text-align: right;
    @media only screen and (max-width: $screen-xs-max) {
      position: absolute;
      left: 0;
    }
    .ant-page-header-heading-title {
      word-break: normal;
      line-height: normal;
      width: 100%;
      @media only screen and (max-width: $screen-xs-max) {
        padding: 0;
      }
    }
    .ant-page-header-heading-sub-title {
      margin: 0;
      flex: 1 1;
      h2.ant-typography {
        margin-bottom: 0;
      }
    }
    .number-stop {
      font-size: 48px;
      margin-right: 12px;
      flex: 0;
      color: #8e8a8a;
    }
  }

  .title-vote {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @media only screen and (max-width: $screen-xs-max) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0;
    }
    > .ant-typography {
      flex: 1;
      @media only screen and (max-width: $screen-xs-max) {
        font-size: 20px;
        margin: 0;
      }
    }
    .wq-question-votes {
      flex: 0 0 auto;
      @media only screen and (max-width: $screen-xs-max) {
        margin: 10px 0;
        width: 100%;
        display: flex;justify-content: flex-end;
      }
    }
    .wq-question-vote-down-btn {
      &:disabled {
        cursor: unset;
      }
      .icon {
        border-top-color: #f44336 !important;
      }
    }
    .wq-question-vote-up-btn {
      &:disabled {
        cursor: unset;
      }
    }
    .wq-question-vote-btn {
      float: left;
      height: 55px;
      width: 55px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.05);
      padding: 3px 0 0;
      color: #333;
      border: 1px solid #dbdbdb;
      border-radius: 4px;
      line-height: 1;
      transition: all 0.1s linear;
      cursor: pointer;
      .icon {
        height: 0;
        width: 0;
        border: 6px solid transparent;
        display: block;
        transition: all 0.1s linear;
      }
      .number {
        font-size: 15px;
        font-weight: 700;
        transition: all 0.1s linear;
      }
      .text {
        font-size: 11px;
        font-weight: 400;
        transition: all 0.1s linear;
      }
    }
    .wq-question-vote-down-btn {
      &:not(.is-voted) {
        &:not(:disabled) {
          &:hover {
            border-color: #f44336;
            color: #f44336;
          }
          &:focus {
            border-color: #f44336;
            color: #f44336;
          }
        }
      }
      &.is-voted {
        border-color: #f44336;
        background-color: #f44336;
        color: #fff;
        cursor: unset;
        >.icon {
          border-top-color: currentColor !important;
        }
      }
    }

    .wq-question-vote-up-btn {
      &:not(.is-voted) {
        &:not(:disabled) {
          &:hover {
            border-color: #009cff;
            color: #009cff;
          }
          &:focus {
            border-color: #009cff;
            color: #009cff;
          }
        }
      }
      margin-left: 6px;
      .icon {
        transform: translateY(-50%);
        border-top-color: transparent;
        border-bottom-color: #009cff;
      }
      &.is-voted {
        border-color: #009cff;
        background-color: #009cff;
        color: #fff;
        cursor: unset;
        >.icon {
          border-bottom-color: currentColor !important;
        }
      }
    }

    .wq-question-vote-btn {
      background: transparent;
    }
  }
}
