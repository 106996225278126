@import "../../assets/sass/variables.scss";

.quotation-confirm {
  .confirm-token {
    background: linear-gradient(0deg,whitesmoke,#e3e8ee);
    height: 100vh;
    padding-top: 10%;
  }
  .form-confirm-container {
    background: linear-gradient(0deg,whitesmoke,#e3e8ee);
    padding-top: 30px;
    height: auto;
    width: 100%;
    float: left;
  }
  @media only screen and (max-width: $screen-md-max) {
    width: 100%;
    float: left;
    height: 100%;
  }
  .ant-result {
    background: white;
    max-width: 500px;
    margin: 0 auto;
    border-radius: 10px;
  }

  .submit-container {
    width: 100%;
    float: left;
    text-align: right;
    background-color: white;
  }

  .skip-all-button-container {
    text-align: right;
  }
  .check-all {
    margin-top: 20px;
  }
  .ant-skeleton-paragraph {
    min-height: 500px;
    li {
      height: 800px !important;
      width: 100% !important;
    }
  }
}
