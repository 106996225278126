@import "../../../../assets/sass/variables.scss";

.home-page {
  .company-info {
    text-align: left;
    background: white;
    .company-logo-container {
      width: 100%;
      display: flex;
      .company-logo {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: white;
        object-fit: cover;
        object-position: center;
        margin-right: 10px;
      }
      .company-name {
        .jobname{
          font-size: 60%;
        }
      }
    }
    .about-company {
      margin-top: 85px;
      position: relative;
      @media screen and (max-width: $screen-lg) {
        padding-left: 10px;
      }
      h5 {
        margin-bottom: 10px;
      }
    }
    .contact {
      margin-top: 10px;
      .content {
        font-size: 14px;
        .see-more {
          color: blue;
          cursor: pointer;
        }
      }
      h5 {
        font-size: 16px;
        margin-bottom: 10px;
      }
      .ant-descriptions-row > th,
      .ant-descriptions-row > td {
        padding-bottom: 5px;
      }
      .ant-descriptions-item-colon::after {
        content: "";
      }
      .anticon {
        font-size: 18px;
        vertical-align: 0px;
      }
    }
  }
}
