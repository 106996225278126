@import "../../../assets/sass/variables.scss";

.card-tyneri {
  border: none;
  .ant-card-cover {
    img {
      border-radius: 8px;
      width: 100%;
    }
  }
  .ant-card-body {
    text-align: center;
    position: relative;
    padding-top: 32px;
    background: transparent;
    .avatar-custom {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        background: #fff;
      }
    }
    .ant-card-meta {
      margin-top: 20px;
      .ant-card-meta-title {
        white-space: unset;
        text-overflow: unset;
        font-size: 18px;
        a {
          color: #000000;
          &:hover {
            color: #007bff;
          }
        }
      }
    }
  }
}
