.header-form {
  background: #e4e4e4;
  font-size: 1.5rem;
  color: #1890ff;
}

.contain-form {
  margin: 1rem 0;
  padding: 0 15px;
  > .col-md-4 {
    margin: 0 auto;
  }
  > span {
    font-size: 1.2rem;
  }

  .contain-form-group-btn {
    margin-bottom: 1rem;
    > button {
      margin-right: 2rem;
      box-shadow: none;
      background: #fff;
    }

    > button.my-itineraries {
      border: 1px solid #339933;
      color: #339933;
      &.active {
        background: #339933;
        color: #fff;
      }
    }
    > button.my-favorite {
      border: 1px solid #3399fe;
      color: #3399fe;
      &.active {
        background: #3399fe;
        color: #fff;
      }
    }
    > button.draft {
      border: 1px solid #cd3301;
      color: #cd3301;
      &.active {
        background: #cd3301;
        color: #fff;
      }
    }
  }

  .img-fluid {
    width: 50px;
    height: 50px;
    background-color: #3e3e3e;
    background-image: none;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin: 0.5rem 0;
  }

  .MuiTypography-root {
    margin: 1rem 0;
  }

  th {
    padding: 6px 12px;
  }

  td {
    padding: 6px 12px;
  }

  .group-action {
    > div + div {
      justify-content: flex-end;
    }
    button.ant-btn {
      > i {
        vertical-align: text-top;
      }
    }
  }

  .header {
    font-weight: 900;
    color: #ffffff;
    &.itineraries {
      background: #339933;
    }
    &.favorite {
      background: #3399fe;
    }
    &.draft {
      background: #cd3301;
    }
  }
}

.footer-form {
  margin: 1rem 0;
  > .MuiButtonBase-root {
    color: #fff;
    background-color: #1976d2;
    &:hover {
      background-color: rgb(17, 82, 147);
    }
  }
  > .MuiFormHelperText-root.Mui-error {
    text-align: center;
    margin-bottom: 1rem;
    color: #f44336 !important;
    font-size: 1rem;
  }
  > .MuiFormHelperText-root.Mui-success {
    text-align: center;
    margin-bottom: 1rem;
    color: #008000 !important;
    font-size: 1rem;
  }
}

.loader {
  position: inherit;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/media/images/page-loader.gif");
  background-repeat: no-repeat;
  background-position: center;
}
