@import "../../assets/sass/variables.scss";

:local(.formViewEditPlace) {
  .MuiDialog-container{
    .MuiDialog-paperFullWidth{
      @media only screen and (max-width: $screen-xs-max) {
        width: 100%;
        max-width: 100%;
        margin: 0;
        .start-time-select{
          margin-bottom: 10px;
          display: flex;
          flex-direction: column;
          .ant-typography{
            margin-bottom: 10px;
          }
          .ant-time-picker {
            width: 100%;
          }
        }
        .row-duration {
          display: flex;
          flex-direction: column;
          .ant-typography{
            align-self: start !important;
            margin-bottom: 10px;
          }
          > div {
            justify-content: space-between;
            > div:first-child, div:last-child{
              flex: 1 1 auto;
              .ant-select {
                width: 100% !important;
              }
            }
          }
        }
      }
      .catalog-mobile{
        width: 100%;
      }
    }
  }
  div.MuiTextField-root {
    margin-bottom: 1rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .popup-title {
    background: #f3f3f3;
    min-height: 64px;
    padding: 16px 30px !important;
    >h6 {
      max-width: calc(100% - 1.5rem);max-width: calc(100% - 1.5rem);
    }
    .btn-close {
      top: 8px;
      color: red;
      right: 8px;
      position: absolute;
    }
  }

  .warning {
    color: #f44336;
    font-style: oblique;
  }

  #save-button {
    width: 64px;
    height: 36px;
  }

  #delete-button {
    width: 102.86px;
    height: 36px;
  }

  .btn-save-to-library {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
    background-color: #e9870a;
    color: white;
    box-shadow: none;
    &:hover {
      background-color: #ffba5f;
      color: white;
    }
    margin-right: 8px;
  }

  .btn-primary {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
    background-color: #0099ff;
    color: white;
    box-shadow: none;
    &:hover {
      background-color: #1389d8;
      color: white;
    }
  }

  div[role="progressbar"] {
    color: #ffffff !important;
    width: 20px !important;
    height: 20px !important;
  }
}
