@import '../../../../../assets/sass/variables.scss';
.loading {
  text-align: center;
  height: 90vh;
  padding-top: 25%;
  .ant-spin-dot {
    font-size: 35px;
  }
}
@keyframes new-item-animation {
  0% {
    opacity: 0;
    transform: translateX(300px);
  }
  70% {
    opacity: 1;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes removed-item-animation {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  30% {
    opacity: 1;
    transform: translateX(50px);
  }

  80% {
    opacity: 1;
    transform: translateX(-800px);
  }

  100% {
    opacity: 0;
    transform: translateX(-800px);
  }
}
.create-quotation-container {
  .ant-skeleton-content .ant-skeleton-paragraph > li {
    width: 100% !important;
    height: 800px;
  }
  .title {
    border-bottom: 0px;
    padding: 10px;
    font-size: 18px;
  }
  .ant-input-group {
    .ant-input[name='budget'] {
      width: 60%;
    }
  }
  .ant-form-item-label {
    text-align: left;
  }
  .ant-form-item-label > label::after {
    content: '';
  }
  .submit-content {
    width: 100%;
    float: left;
    padding: 15px 25px;
    background-color: white;
    .quotation-to-from {
      border-bottom: 1px solid #cdcdcd;
      padding-top: 25px !important;
      h5 {
        font-size: 18px;
        width: 100%;
        float: left;
        margin-bottom: 10px;
      }
      .quotation-from {
        text-align: right;
        .content {
          margin-top: 10px;
          float: right;
          display: flex;
          text-align: right;
        }
        .company-logo {
          img {
            width: 100px;
            margin-left: 10px;
          }
        }
        @media screen and (max-width: $screen-md) {
          h5 {
            text-align: left;
          }
          text-align: left;
        }
      }
    }
    .quotation-content {
      border-bottom: 1px solid #cdcdcd;
      border-top: 0px;
      padding-top: 25px !important;
      padding-bottom: 25px !important;
      .img-tyneri {
        img {
          width: 280px;
          float: right;
          @media screen and (max-width: $screen-md) {
            width: 100%;
          }
        }
      }
    }
  }

  /* Other-information */
  .other-information {
    margin-top: 20px;
    border-top: 1px solid #cdcdcd;
    padding-top: 20px;
    .text {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  /* save button */
  .submit-container {
    margin-top: 20px;
    justify-content: space-between;
    display: flex;
    .btn-custom {
      font-size: 18px;
      padding: 10px;
      color: white;
      border-radius: 5px;
      border: 1px solid #ededed;
    }
    .btn-cancel {
      background-color: red;
    }
    .btn-submit-email {
      margin-right: 10px;
      background-color: orangered;
    }
    .btn-submit {
      background-color: green;
    }
  }
  .submit-container-right {
    display: inline !important;
    text-align: right;
  }
}
