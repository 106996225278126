.header-form {
  background: #E4E4E4;
  font-size: 1.5rem;
  color: #1890FF;
}

.contain-form {
  margin: 1rem 0;
  > .col-md-4 {
    margin: 0 auto;
  }
  >span {
    font-size: 1.2rem;
  }
}

.footer-form {
  margin: 1rem 0;
  > .MuiButtonBase-root {
    color: #fff;
    background-color: #1976d2;
    &:hover {
      background-color: rgb(17, 82, 147);
    }
  }
  >.MuiFormHelperText-root.Mui-error {
    text-align: center;
    margin-bottom: 1rem;
    color: #f44336 !important;
    font-size: 1rem;
  }
  >.MuiFormHelperText-root.Mui-success {
    text-align: center;
    margin-bottom: 1rem;
    color: #008000 !important;
    font-size: 1rem;
  }
}

.loader {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/media/images/page-loader.gif");
  background-repeat: no-repeat;
  background-position: center;
}

