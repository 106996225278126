.text-edit-inline {
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
}
.comment-optional {
  font-size: 14px;
  cursor: pointer;
  color: #9a9c9d;
  font-style: italic;
}
