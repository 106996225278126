@import "../../assets/sass/variables.scss";

:local(.itineraryDetail) {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/media/images/page-loader.gif");
  background-repeat: no-repeat;
  background-position: center;
}

:local(.wrapper) {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  @media only screen and (max-width: $screen-sm-max) {
    min-height: 0;
  }

  #button {
    display: inline-block;
    background-color: #989897;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 4px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    transition: background-color .3s,
      opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
  }
  #button::after {
    content: "\f077";
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    font-size: 1em;
    line-height: 50px;
    color: #fff;
  }
  #button:hover {
    cursor: pointer;
    background-color: #333;
  }
  #button:active {
    background-color: #555;
  }
  #button.show {
    opacity: 1;
    visibility: visible;
  }
}
