.modal-inquiries-detail {
  .ant-modal-body {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .ant-descriptions-item > span {
    display: block;
  }
  .ant-modal-content {
    border-radius: 5px;
  }
  .request-info {
    .ant-descriptions-title {
      margin-bottom: 5px;
    }
    .ant-descriptions-row > th, .ant-descriptions-row > td {
      padding-bottom: 5px;
    }
    .ant-descriptions-item {
      display: flex;
    }
  }
  .tyneri-info {
    .ant-descriptions-item > span {
      display: inline-block;
    }
    .ant-descriptions-item-colon::after{
      content: ':';
    }
    .title {
      margin-bottom: 20px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: bold;
      font-size: 16px;
      line-height: 1.5;
    }
  }
  .information-request {
    .ant-descriptions-title {
      margin: 10px 0px;
      border-bottom: 1px solid gray;
    }
    h5  {
      font-size: 16px;
    }
    .item {
      margin-bottom: 10px;
    }
  }
  .ant-modal-footer {
    height: 60px;
    .footer-container {
      background-color: white;
      button {
        right: 0px;
        float: right;
      }
      .ant-checkbox-wrapper {
        margin-top: 5px;
        float: left;
      }
      .update-status-button {
        margin-left: 10px;
      }
      .contact-list-btn {
        background-color: #faad14;
        color: white;
      }
      .contact-list-btn:hover {
        border-color: white;
      }
    }
  }
}
