.each-contributor {
  text-align: center;
  margin: 15px 0;
  position: relative;
  * {
    transition: 0.5s;
  }
  span {
    margin: 0 auto;
  }
  .hover-avatar-effect {
    .avatar-title{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0,0,0,0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      width: 140px;
      height: 140px;
      border-radius: 50%;
      font-weight: bold;
      transform: scale(0.1);
      opacity: 0;
    }
    &:hover {
      .avatar-title {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}
