@import "../../assets/sass/variables.scss";

:local(.wrapper) {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#tyneri-layout {
  background: white;
  .ant-layout-header {
    background: white;
    padding: 0;
    z-index: 100;
    min-height: 75px;
    line-height: 75px;
    height: auto;
    .ant-menu-horizontal {
      line-height: 63px;
      border-bottom: 0;
      > .ant-menu-item {
        border-bottom: 0;
        margin-top: 0;
        > a {
          text-transform: capitalize;
          &::before {
            bottom: 0;
          }
        }
      }
      > .ant-menu-item:hover,
      > .ant-menu-item-active {
        border-bottom: 0;
      }
    }
    > .ant-row-flex {
      box-shadow: 2px 1px 10px 0px #ccc;
      > .ant-col {
        padding: 0 15px;
      }
    }
  }
}

.ant-row.mobile {
  @media only screen and (max-width: $screen-xs-max) {
    background: #002347;
  }
  .height-50 {
    flex: 1;
  }
}
