@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Special+Elite");
:local(.placesInListSelected) .MuiAccordionSummary-content {
  width: 100%; }

:local(.placesInListSelected) .main-content.main-content-infor > div {
  background: #f7f7f7;
  border: 0; }

@media only screen and (max-width: 575px) {
  :local(.placesInListSelected) {
    padding-top: 20px; } }

:local(.placesInListSelected) .trix-content-temp {
  line-height: 1.5; }
  :local(.placesInListSelected) .trix-content-temp * {
    box-sizing: border-box; }
  :local(.placesInListSelected) .trix-content-temp h1 {
    font-size: 1.2em;
    line-height: 1.2;
    margin: 0; }
  :local(.placesInListSelected) .trix-content-temp pre {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    font-family: monospace;
    font-size: 0.9em;
    margin: 0;
    padding: 0.5em;
    white-space: pre;
    background-color: #eee;
    overflow-x: auto; }
  :local(.placesInListSelected) .trix-content-temp ul,
  :local(.placesInListSelected) .trix-content-temp ol,
  :local(.placesInListSelected) .trix-content-temp li {
    margin: 0;
    padding: 0; }
    :local(.placesInListSelected) .trix-content-temp ul li,
    :local(.placesInListSelected) .trix-content-temp ol li,
    :local(.placesInListSelected) .trix-content-temp li li {
      margin-left: 1em; }
  :local(.placesInListSelected) .trix-content-temp img {
    max-width: 100%;
    height: auto; }
  :local(.placesInListSelected) .trix-content-temp .attachment {
    display: inline-block;
    position: relative;
    max-width: 100%;
    margin: 0;
    padding: 0; }
    :local(.placesInListSelected) .trix-content-temp .attachment a {
      color: inherit;
      text-decoration: none; }
      :local(.placesInListSelected) .trix-content-temp .attachment a:hover, :local(.placesInListSelected) .trix-content-temp .attachment a:visited:hover {
        color: inherit; }
  :local(.placesInListSelected) .trix-content-temp .attachment__caption {
    padding: 0;
    text-align: center; }
    :local(.placesInListSelected) .trix-content-temp .attachment__caption .attachment__name + .attachment__size::before {
      content: " · "; }
  :local(.placesInListSelected) .trix-content-temp .attachment--preview {
    width: 100%;
    text-align: center; }
    :local(.placesInListSelected) .trix-content-temp .attachment--preview .attachment__caption {
      color: #666;
      font-size: 0.9em;
      line-height: 1.2; }
  :local(.placesInListSelected) .trix-content-temp .attachment--file {
    color: #333;
    line-height: 1;
    margin: 0 2px 2px 0;
    padding: 0.4em 1em;
    border: 1px solid #bbb;
    border-radius: 5px; }
  :local(.placesInListSelected) .trix-content-temp .attachment-gallery {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 0;
    padding: 0; }
    :local(.placesInListSelected) .trix-content-temp .attachment-gallery .attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%; }
    :local(.placesInListSelected) .trix-content-temp .attachment-gallery.attachment-gallery--2 .attachment, :local(.placesInListSelected) .trix-content-temp .attachment-gallery.attachment-gallery--4 .attachment {
      flex-basis: 50%;
      max-width: 50%; }

:local(.placesInListSelected) blockquote {
  font-size: 1rem;
  line-height: 1;
  position: relative;
  padding: 0.5rem;
  background: #ddf3d0;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px; }

:local(.placesInListSelected) blockquote::before {
  font-family: "Special Elite", cursive;
  content: open-quote;
  font-size: 3rem;
  font-weight: bold;
  color: #04a67b;
  vertical-align: -0.3em; }

:local(.placesInListSelected) blockquote::after {
  font-family: "Special Elite", cursive;
  content: close-quote;
  font-size: 3rem;
  font-weight: bold;
  color: #04a67b;
  vertical-align: -0.3em;
  position: absolute;
  bottom: -14px;
  right: 6px; }

:local(.placesInListSelected) .quote {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-top: -40px;
  margin-left: 30px;
  margin-right: 20px;
  font-style: italic; }

:local(.placesInListSelected) cite {
  line-height: 3;
  text-align: left; }

:local(.placesInListSelected) div.ReactGridGallery {
  width: 100%; }

:local(.placesInListSelected) .MuiButtonBase-root.MuiIconButton-root.MuiExpansionPanelSummary-expandIcon.MuiIconButton-edgeEnd {
  display: none; }

:local(.placesInListSelected):focus {
  outline: unset !important; }

:local(.placesInListSelected) .distance-duration {
  white-space: initial; }

:local(.placesInListSelected) > div:first-child {
  margin: unset !important; }

:local(.placesInListSelected) svg {
  fill: #fff; }

:local(.placesInListSelected) .col-3 > svg {
  fill: black;
  width: 1.2rem;
  height: 1.2rem; }

:local(.placesInListSelected) .btn-secondary {
  color: #0099ff !important;
  background-color: transparent;
  border: none; }

:local(.placesInListSelected) .btn-primary {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
  background-color: #0099ff;
  color: white;
  box-shadow: none; }
  :local(.placesInListSelected) .btn-primary:hover {
    background-color: #1389d8;
    color: white; }

:local(.placesInListSelected) .bullet {
  display: "inline-block";
  margin: "0 2px";
  transform: "scale(0.8)"; }

:local(.placesInListSelected) .dropdown {
  visibility: hidden; }
  :local(.placesInListSelected) .dropdown .dropdown-width {
    width: 350px;
    font-size: 0.8rem; }

:local(.placesInListSelected) .thumbnail-places .img-fluid {
  width: 100%;
  height: 80px;
  border-radius: 4px;
  background-color: #3e3e3e;
  background-image: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }
  @media only screen and (max-width: 575px) {
    :local(.placesInListSelected) .thumbnail-places .img-fluid {
      height: 150px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; } }

:local(.placesInListSelected) .time-start {
  font-weight: bold;
  padding: 0;
  font-size: 0.9rem; }

:local(.placesInListSelected) .vertical-divider {
  border-right: 1px solid black;
  padding-right: 0; }

:local(.placesInListSelected) .disabled-action {
  pointer-events: none;
  opacity: 0.4; }

:local(.placesInListSelected) .favorite-icon {
  margin-right: 10px;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }

:local(.placesInListSelected) .edit-icon {
  margin-right: 10px;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }

:local(.placesInListSelected) .remove-icon {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }

:local(.placesInListSelected) .icon-quote {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0rem;
  bottom: -0.7rem; }

:local(.placesInListSelected) .category-icon > svg {
  width: 1rem;
  height: 1rem;
  fill: #fff; }

:local(.placesInListSelected) .card {
  width: 100%;
  margin: 0 auto;
  position: unset;
  box-shadow: unset; }

:local(.placesInListSelected) .MuiExpansionPanelSummary-root {
  padding: 15px; }

:local(.placesInListSelected) .MuiExpansionPanel-root.Mui-expanded {
  margin: 0 auto; }

:local(.placesInListSelected) .selectedTransport {
  color: #0099ff; }

:local(.placesInListSelected) .selectedTransport svg {
  fill: #0099ff; }

:local(.placesInListSelected) .dropdown-menu {
  border: 1px solid black; }

:local(.placesInListSelected) .dropdown-item {
  padding: 0.25rem 0.5rem; }
  :local(.placesInListSelected) .dropdown-item:active {
    background-color: transparent;
    color: #0099ff; }

:local(.placesInListSelected) .row.setting-transport {
  margin-left: 10px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 0.8rem; }
  @media only screen and (max-width: 575px) {
    :local(.placesInListSelected) .row.setting-transport {
      display: none; } }

@media only screen and (max-width: 575px) {
  :local(.placesInListSelected) .zoom-place.row {
    margin: 0; }
  :local(.placesInListSelected) .zoom-place .thumbnail-places {
    padding: 0; }
  :local(.placesInListSelected) .zoom-place .main-content {
    padding: 0; }
    :local(.placesInListSelected) .zoom-place .main-content .card {
      border-top-left-radius: 0;
      border-top-right-radius: 0; } }

:local(.placesInListSelected) .icon-transportation {
  display: none;
  position: absolute;
  top: 0.6rem;
  border-radius: 50%;
  width: 30px;
  height: 20px;
  background: #fff;
  border: 1px solid white;
  left: 6.6rem; }
  @media (min-width: 576px) {
    :local(.placesInListSelected) .icon-transportation {
      left: 30% !important; } }
  @media (min-width: 992px) and (max-width: 1200px) {
    :local(.placesInListSelected) .icon-transportation {
      left: 3.5% !important; } }
  @media (min-width: 1200px) and (max-width: 1600px) {
    :local(.placesInListSelected) .icon-transportation {
      left: 8% !important; } }
  @media (min-width: 1600px) {
    :local(.placesInListSelected) .icon-transportation {
      left: 12% !important; } }
  :local(.placesInListSelected) .icon-transportation > span {
    display: flex;
    width: 32px;
    height: 32px;
    position: relative;
    justify-content: center;
    cursor: pointer;
    align-self: center;
    align-items: center; }
    :local(.placesInListSelected) .icon-transportation > span > svg {
      fill: #9a9a9a; }

:local(.placesInListSelected) .left-distant .distance-detail {
  margin-left: 25%;
  display: flex; }
  :local(.placesInListSelected) .left-distant .distance-detail span {
    position: inherit;
    left: 9rem;
    font-size: 0.8rem;
    align-self: center;
    margin-right: 10px;
    color: #9a9a9a; }

:local(.placesInListSelected) .right-setting-column {
  padding-left: 0.1rem; }

:local(.placesInListSelected) .setting-icon > svg {
  fill: #9a9a9a; }

:local(.placesInListSelected) .MuiIconButton-label > svg {
  fill: currentColor;
  width: 1em;
  height: 1em; }

:local(.placesInListSelected) .MuiExpansionPanelDetails-root {
  padding: 10px !important; }

:local(.placesInListSelected) .MuiExpansionPanel-root:before {
  opacity: 0; }

:local(.placesInListSelected) .MuiExpansionPanelSummary-content {
  margin: 0;
  display: unset; }

:local(.placesInListSelected) .MuiExpansionPanelSummary-content.Mui-expanded {
  margin: 0;
  display: unset; }

:local(.placesInListSelected) .title-action {
  display: flex;
  justify-content: space-between; }
  :local(.placesInListSelected) .title-action div {
    display: flex; }
    :local(.placesInListSelected) .title-action div .MuiCircularProgress-root {
      margin-right: 10px;
      color: #0f95de; }
      :local(.placesInListSelected) .title-action div .MuiCircularProgress-root > svg {
        width: unset;
        height: unset; }
    :local(.placesInListSelected) .title-action div .time {
      display: block;
      font-size: 17px;
      color: #9a9a9a;
      margin-right: 5px; }
      :local(.placesInListSelected) .title-action div .time > svg {
        height: 1.3rem;
        width: 1.3rem;
        fill: #9a9a9a !important;
        margin-right: 5px; }
    :local(.placesInListSelected) .title-action div .status {
      color: #fca81b; }
    :local(.placesInListSelected) .title-action div .action {
      display: flex; }

:local(.placesInListSelected) .place-title {
  font-size: 1rem;
  font-weight: bold; }
  @media only screen and (max-width: 575px) {
    :local(.placesInListSelected) .place-title {
      font-size: 16px; } }

:local(.placesInListSelected) .place-address {
  margin-bottom: 0;
  color: #9a9c9d;
  font-size: 14px; }
  :local(.placesInListSelected) .place-address > p {
    margin-bottom: 0 !important; }

:local(.placesInListSelected) .content-event-detail {
  display: flex;
  flex-flow: row wrap;
  margin: 5px 0px !important; }
  :local(.placesInListSelected) .content-event-detail > .content {
    color: #4d636e;
    font-size: 1rem;
    margin-right: 10px; }
    :local(.placesInListSelected) .content-event-detail > .content > .value {
      font-weight: bold;
      color: black; }
    :local(.placesInListSelected) .content-event-detail > .content:last-of-type {
      margin-right: 0; }

:local(.placesInListSelected) .content {
  color: #4d636e;
  font-size: 1rem;
  font-weight: 400; }
