@import "../../../../assets/sass/variables.scss";

.edit-folder-modal {
  .ant-modal-body {
    padding: 10px;
    padding-left: 25px;
  }
  .ant-form-item-label {
    line-height: 0px;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }
}

:local(.loader) {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../../../../assets/media/images/page-loader.gif");
  background-repeat: no-repeat;
  background-position: center;
}
