@import "../../assets/sass/variables.scss";

.main-menu-container {
  // margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex: 1;
  .menu-and-logo {
    display: flex;
    align-items: center;
    width: 100%;
    //flex-direction: row-reverse;
    @media only screen and (max-width: $screen-md-max) {
      flex-direction: row;
    }
    .main-menu {
      .anticon-menu {
        vertical-align: 0px;
      }
      font-size: 30px;
    }
    .custom-submenu{
      .ant-menu-submenu-title{
        padding: 0 5px;
        .anticon{
          font-size: 20px;
          margin-right: 0;
        }
      }
    }
  }
  .menu-and-button {
    margin-left: 30px;
  }
  .login{
    display: none;
    @media only screen and (max-width: $screen-md-max) {
      display: block;
    }
  }
  .menu-item-custom {
    a {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .ant-menu-root.ant-menu-inline {
    border: none;
    width: fit-content;
  }
  .ant-menu-submenu.ant-menu-submenu-inline {
    .ant-menu-submenu-title {
      float: left;
      background-color: transparent!important;
      padding-right: 0px;
      padding-left: 0px!important;
      height: 80px;
      width: 40px;
      .anticon {
        font-size: 30px;
        line-height: 45px;
      }
      .ant-menu-submenu-arrow {
        display: none;
      }
      @media only screen and (max-width: $screen-md-max) {
        height: auto;
        margin: 0;
        .anticon {
          line-height: normal;
          font-size: 24px;
        }
      }
    }
  }
  .logo-container {
    z-index: 10;
    left: 60px;
    top: 0px;
    .main-logo {
      width: 100%;
      max-width: 120px;
    }
    @media only screen and (max-width: $screen-md-max) {
      position: static;
      flex: 0 auto;
      .main-logo {
        width: 100%;
      }
    }
  }
  .ant-menu-submenu > .ant-menu {
    background-color: transparent;
    width: 100%;
    float: left;
    li {
      padding-left: 0!important;
    }
  }
  .ant-menu {
    background-color: transparent;
  }
}
.ant-row.mobile{
  justify-content: space-between;
  align-items: center;
  flex-flow: row;
  display: flex;
  z-index: 1;
  box-shadow: 2px 1px 10px 0px #ccc;
  background: white;
  @media only screen and (max-width: $screen-xs-max) {
    box-shadow: 1px 1px 5px 1px #ccc;
    //margin-bottom: 10px;
  }
  .ant-menu-horizontal {
    background: none;
    color: white;
  }
  .main-menu-container {
    .menu-and-logo{
      align-items: center;
      justify-content: flex-start;
      .logo-container {
        z-index: 10;
        @media only screen and (max-width: $screen-xs-max) {
          position: static;
        }
        .main-logo {
          width: 150px;
          //margin-left: 3px;
          /*@media only screen and (max-width: $screen-xs-max) {
            width: 70px;
          }*/
        }
      }
      .ant-menu-submenu.ant-menu-submenu-inline {
        .ant-menu-submenu-title {
          height: auto;
          width: 30px;
          .anticon {
            font-size: 28px;
            line-height: 70px;
            @media only screen and (max-width: $screen-xs-max) {
              font-size: 20px;
              line-height: normal;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
