@import "../../assets//sass/variables.scss";
.cardList {
  .each-related {
    margin-bottom: 15px;
    .ant-card-meta-title {
      overflow: unset;
      white-space: unset;
      text-overflow: unset;
      .option-pref {
        font-size: 10px;
        color: #e33c59;
      }

      .option-age {
        font-size: 10px;
        color: #31a2ec;
      }

      .option-type {
        font-size: 10px;
        color: #f3aa30;
      }
    }

    .ant-skeleton-header {
      padding: 0;
    }
    .cardBlock {
      width: auto !important;
      .total-reviews {
        font-size: 12px;
      }
      .ant-card-body {
        height: 170px;
      }
      .content {
        margin-top: 5px;
        min-height: 80px;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 14px;
      }
      .total-views {
        font-size: 14px;
        >.anticon {
          vertical-align: initial;
        }
      }
      &:hover {
        .cartImage {
          .hover-info {
            display: flex;
            opacity: 1;
            font-size: 10px;
          }

          &::after {
            position: absolute;
            content: "";
            background-color: rgba(5, 37, 48, 0.6);
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 1;
          }
        }
      }
    }
    .cartTitle {
      color: #ff4d55;
      font-size: 20px;
    }
    .cartDes {
      font-size: 14px;
      p {
        max-height: 40px;
        height: 40px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
    }
    .cartImage {
      position: relative;
      height: 150px;
      background: rgba(0, 0, 0, 0.075) no-repeat center;
      background-size: cover;
      .hover-info {
        -webkit-transition-property: opacity;
        transition-property: opacity;
        -webkit-transition-duration: 0.25s;
        transition-duration: 0.25s;
        opacity: 0;
        z-index: 10;
        bottom: 10px;
        cursor: pointer;
        position: absolute;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: scale(1);
        transform: scale(1);
        font-size: 10px;
        line-height: 1;
        display: -moz-flex;
        display: flex;
        flex-flow: row wrap;
        box-align: center;
        -moz-align-items: center;
        -ms-align-items: center;
        -o-align-items: center;
        align-items: center;
        -ms-flex-align: center;
        box-pack: center;
        -ms-flex-pack: center;
        // height: 2.9rem;
        padding-left: 0.7rem;
        padding-right: 0.7rem;
        -webkit-transition-property: -webkit-transform, background-color, border,
          color, box-shadow;
        -webkit-transition-property: background-color, border, color, box-shadow,
          -webkit-transform;
        transition-property: background-color, border, color, box-shadow,
          -webkit-transform;
        transition-property: transform, background-color, border, color,
          box-shadow;
        transition-property: transform, background-color, border, color,
          box-shadow, -webkit-transform;
        -webkit-transition-duration: 0.25s;
        transition-duration: 0.25s;
        .each-option {
          font-size: 11px;
        }
      }

      &::after {
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem;
        -webkit-transition-property: background-color;
        -moz-transition-property: background-color;
        transition-property: background-color;
        -webkit-transition-duration: 0.25s;
        -moz-transition-duration: 0.25s;
        transition-duration: 0.25s;
        position: absolute;
        content: "";
        background-color: rgba(5, 37, 48, 0);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
      }
      &:before {
        content: "";
        display: block;
        padding-top: 75%;
      }
      .card-tag-left {
        position: absolute;
        left: 10px;
        top: 10px;
        margin: 0;
      }
      .card-tag-right {
        position: absolute;
        right: 10px;
        top: 10px;
        margin: 0;
      }
    }
  }
}
