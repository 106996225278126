@import "../../assets/sass/variables.scss";
.ant-modal {
  height: fit-content;
}

.company-request-section {
  display: flex;
  justify-content: space-between;
  @media (max-width: $screen-sm) {
    display: inherit;
    width: 100%;
  }
  .btn-request {
    height: 100%;
    @media only screen and (max-width: $screen-md-max) {
      padding: 6px 8px;
    }
    .anticon {
      vertical-align: inherit;
    }
    @media (max-width: $screen-sm) {
      width: 100%;
      height: 36px;
      margin-top: 10px;
    }
  }
  .company-section {
    display: flex;
    width: fit-content;
    top: 20px;
    left: 20px;
    @media (max-width: $screen-sm) {
      width: 100%;
      display: flex;
    }
    .company-infor {
      margin-left: 15px;
      .company-infor__name {
        color: #007bff;
      }
      .company-infor__summary {
        color: #9a9c9d;
        font-size: 13px;
        > span {
          margin-right: 15px;
          .company-infor__summary__detail {
            color: red;
          }
        }
      }
    }

    .company-logo {
      background-color: white;
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 100px;
    }
  }
}

.inquiries-form {
  .ant-form-item-label > label::after {
    content: "";
  }
  .ant-typography {
    text-transform: uppercase;
    margin-bottom: 0px;
    font-size: 18px;
    line-height: inherit;
  }
  .ant-steps-item-description {
    font-size: 13px;
  }
  .ant-form-item {
    margin-bottom: 20px !important;
    .ant-form-item-label {
      line-height: 20px;
      white-space: initial;
      text-align: left;
      font-weight: bold;
      span {
        width: 100%;
        float: left;
        color: grey;
        font-size: 13px;
        margin: 5px 0px;
      }
    }
  }
  .about {
    .ant-form-item {
      margin-bottom: 5px !important;
      padding-right: 10px;
      @media screen and (max-width: $screen-sm) {
        padding-right: 0px;
      }
    }
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: grey;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: grey;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: grey;
    }
  }
}
.modal-inquiries {
  .ant-modal-content {
    border-radius: 0px;
    border: 0px;
    .ant-form-item-label > label {
      font-weight: normal;
    }
    form .ant-mentions, form textarea.ant-input {
      min-height: 80px;
    }
  }
  .ant-modal-footer {
    width: 100%;
    float: left;
    background: white;
    border: 0px;
    padding: 10px 30px;
    .ant-checkbox-wrapper {
      float: left;
    }
    button {
      text-transform: uppercase;
      background-color: #fa8c16;
      border-color: #fa8c16;
      font-weight: bold;
      float: right;
    }
  }
}
