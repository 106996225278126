@import '../../assets/sass/variables.scss';

.btn-google {
  width: 100% !important;
  margin: 0 !important;
}

.section-sign-up-now {
  margin-top: 10px;
  .sign-up-now {
    cursor: pointer;
    color: #1890ff;
    &:hover {
      text-decoration: underline;
    }
  }
}

.forget-password {
  margin-top: 10px;
  cursor: pointer;
  color: #1890ff;
  &:hover {
    text-decoration: underline;
  }
}

.popover-account {
  background-color: #8d8e90;
  margin: -17px;
  padding: 5px;
  z-index: 10000;
  position: absolute;
  right: 10px;
  top: 25px;
  .email {
    color: #d1d3d8;
  }
  .name {
    color: white;
    font-size: 17px;
    margin-bottom: 10px;
    font-weight: bold;
  }
}

.menu-login {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  .to-right {
    display: flex;
    justify-content: flex-end;
    > .ant-menu-horizontal {
      width: 100%;
      float: right;
      text-align: right;
    }
  }

  .button-sign-in {
    border: none;
    box-shadow: none;
    font-size: 18px;
    > .anticon-user-add {
      vertical-align: text-top;
    }
  }

  .dropdown-hover {
    float: right;
    padding-right: 15px;
    .user-text {
      font-size: 20px;
    }
    .anticon.anticon-down {
      font-size: 20px;
      vertical-align: 0px;
    }
  }
  .ant-menu-horizontal {
    > .ant-menu-item {
      padding: 0 10px;
      > a {
        font-size: 12px;
        display: flex;
        align-items: center;
        @media only screen and (min-width: $screen-xl-min) {
          font-size: 18px;
        }
        @media only screen and (max-width: $screen-xs-max) {
          font-size: 10px;
        }
      }
    }
  }
}
.ant-dropdown {
  min-width: 130px !important;
}
.ant-dropdown-custom {
  // width: 120px !important;
  border: 1px solid #ccc;
  .ant-menu-inline {
    border-right: 0px;
    margin-top: 0px;
  }
  li {
    padding: 0px !important;
    margin-bottom: 0px !important;
    line-height: 27px;
    height: 30px;
    a {
      padding: 0 10px;
      width: 100%;
      float: left;
      font-size: 14px;
      .anticon {
        vertical-align: 0px;
        font-size: 20px;
        font-weight: bold;
      }
    }

    .name {
      font-weight: bold;
      font-size: 16px;
      width: 100%;
      float: left;
      padding: 5px 10px 0 10px;
    }

    .email {
      font-size: 12px;
      width: 100%;
      float: left;
      padding: 0 10px;
      color: #9a9a9a;
    }

    .divider {
      padding: 0 10px;
      width: 100%;
      float: left;
      .dash {
        width: 100%;
        height: 1px;
        background-color: #cdcdcd;
      }
    }

    .sign-out {
      width: 100%;
      float: left;
      padding: 0 10px;
      font-size: 14px;
      .sign-out-icon {
        width: 100%;
        float: left;
      }
      .anticon {
        vertical-align: 0px;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  li:last-child {
    margin-bottom: 4px;
  }
}
.login-form {
  .MuiInputBase-root,
  .MuiFormControl-root {
    width: 100%;
  }
}

.ant-row.mobile {
  .menu-login {
    margin-bottom: 0px;
    line-height: 75px;
    padding-right: 10px;
    justify-content: flex-end;
    @media only screen and (max-width: $screen-md-max) {
      line-height: normal;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    @media only screen and (max-width: $screen-xs-max) {
      line-height: normal;
    }
    .button-sign-in {
      margin-left: 10px;
    }
    .anticon-user {
      vertical-align: 5px;
    }
    .dropdown-hover {
      padding: 0px;
    }
  }
}

.hover-effect {
  position: relative;
  overflow: hidden;
  &:before {
    --n: 1;
  }
  &:after {
    --n: 2;
  }
  &:before,
  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: #ff5722;
    transform: translateY(150%);
    border-radius: 50%;
    left: calc((var(--n) - 1) * 50%);
    transition: 0.5s;
    transition-delay: calc((var(--n) - 1) * 0.1s);
    z-index: -1;
    opacity: 1;
  }
  @media only screen and (max-width: $screen-xs-max) {
    padding: 0 7px;
    justify-content: center;
  }
  &:hover,
  &:focus,
  &:active {
    color: white;
    border: 1px solid #ff5722;
    background: transparent !important;
    &:before,
    &:after {
      transform: translateY(0) scale(2);
    }
  }
}
