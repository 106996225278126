:local(.wrapper) {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  #container {
    min-height: calc(100vh - 94px - 53px);
    background: #fff;
    width: 100%;
  }

  .ant-layout {
    background: #fff;
  }

  li.ant-menu-item {
    .anticon {
      vertical-align: text-top;
      font-size: 1.2rem;
    }
    > svg {
      font-size: 1.2rem;
      margin-left: 10px;
      vertical-align: text-bottom;
    }

    > span {
      font-size: 1.2rem;
    }
  }

  .create-tyneri {
    color: white;
    box-shadow: none;
    background-image: linear-gradient(120deg, #fb9911 0%, #fef451 100%);
    &:hover {
      background-image: linear-gradient(120deg, #fbaa39 0%, #fff66b 100%);
    }

    span {
      color: #fff !important;
      > svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
