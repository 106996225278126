@import "../../../../assets/sass/variables.scss";
.dashboard-container {
  background-color: white;
  padding: 10px;
  .report {
    .loading-card {
      padding: 10px;
      .ant-skeleton-paragraph {
        li {
          width: 100% !important;
          height: 180px;
        }
      }
    }
    .card-dashboard {
      margin: 10px;
      border-radius: 5px;
      .static-content {
        display: grid;
        text-align: left;
        color: white;
        .name {
          font-size: 17px;
        }
        .number {
          font-size: 35px;
          font-weight: bold;
          color: white;
        }
        .text-all {
          width: fit-content;
          padding: 5px 10px;
          margin-top: 10px;
          background-color: white;
          color: black;
          border-radius: 15px;
          cursor: pointer;
        }
      }
      .icon {
        margin: 15%;
        text-align: center;
        svg {
          color: white;
          font-size: 50px;
        }
      }
    }
  }
  .table-static {
    margin-top: 30px;
    .ant-empty-image {
      width: 100%;
      float: left;
    }
    .table-children {
      margin: 10px;
      border-radius: 5px;
      .recent-customers-text {
        font-size: 16px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-top: 10px;
        border-bottom: 1px solid #dee2e6;
        width: 100%;
        float: left;
        font-weight: bold;
      }
      .content {
        padding: 10px;
        background-color: white;
        .responsiveTable {
          thead {
            tr {
              th {
                font-size: 15px;
                padding: 10px 0px;
              }
            }
          }
          td {
            font-size: 15px;
          }
          .item {
            border-top: 1px solid #dee2e6;
            td {
              padding: 10px 0px;
              .action-detail {
                background-color: #352c7b;
                padding: 5px 10px;
                width: fit-content;
                color: white;
                border-radius: 15px;
                font-size: 13px;
                cursor: pointer;
                i {
                  vertical-align: 1px;
                  margin-right: 5px;
                }
              }
            }
          }
          @media screen and (max-width: $screen-md-max) {
            tbody tr {
              border: 0px;
            }
          }
        }
      }
    }
  }
}
.modal-contact-view {
  strong {
    margin-right: 10px;
  }
}
