:local(.lodgingTab) {
    .MuiFormLabel-root.Mui-error {
      color: #f44336;
    }
  }
  
  .section {
    font-weight: bold;
  }
  
  .show-more {
    color: #45bbff;
    cursor: pointer;
    &:hover {
      color: #5fc4ff;
    }
  }
  