@import '../../assets/sass/variables.scss';

:local(.contentSignUp) {
  background-color: #fff;
  margin: 30px 0 !important;
  .sign-up-form {
    padding: 30px;
    max-width: 520px;
    margin: 0 auto;
    .sign-up-form__head {
      .sign-up-form__head__title {
        display: table;
        font-size: 2.5rem;
        font-weight: 600;
      }
      .sign-up-form__head__option {
        margin-bottom: 30px;
      }
    }
    .sign-up-form__content {
      .MuiDialogContent-dividers {
        border: none;
        padding: 0 15px;
        overflow-y: unset;
      }
      .MuiTextField-root {
        width: 100%;
      }
    }
  }

  .section-browse-idea {
    position: relative;
    height: 100%;
    .section-browse-idea__img {
      background-image: url('../../assets/media/images/sign-up.png');
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      left: 0;
      background-position: center;
    }
    .section-browse-idea__helper-main {
      position: absolute;
      left: 30px;
      bottom: 60px;
      width: 100%;
      font-size: 2rem;
      color: #fff;
    }

    .section-browse-idea__helper-secondary {
      position: absolute;
      left: 30px;
      bottom: 30px;
      width: 100%;
    }
  }

  @media only screen and (min-width: $screen-lg-min) {
    display: flex;
  }
  @media only screen and (max-width: $screen-md-max) {
    .sign-up-form {
      padding: 0;
    }
    .section-browse-idea--mobile {
      height: 400px;
    }
  }
}
