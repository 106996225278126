@import "../../assets/sass/variables.scss";

.change-location {
  display: flex;
  background: transparent;
  font-size: 15px;
  > span {
    color: #0f95de;
    padding-right: 5px;
  }
  select {
    border: none;
    background: transparent;
    color: #0f95de;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
  }
}
