:local(.wrapperContent) {
  display: flex;
  flex-direction: column;
  .img-fluid{
    &.max-width {
      width: 100%;
      display: block;
    }
  }
  .section-like{
    .main-title-section {
      font-size: 1.4rem;
      margin: 0;
    }
  }

  .timeline-event {
    > div:first-of-type {
      top: 20px !important;
    }
  }

  .timeline-event-1 {
    > div:first-of-type {
      display: none;
    }
  }
}