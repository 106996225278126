@charset "UTF-8";
:local(.pageLoader) {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/media/images/page-loader.gif");
  background-repeat: no-repeat;
  background-position: center; }

:local(.viewItineraryPdf) .trix-content-temp {
  line-height: 1.5; }
  :local(.viewItineraryPdf) .trix-content-temp * {
    box-sizing: border-box; }
  :local(.viewItineraryPdf) .trix-content-temp h1 {
    font-size: 1.2em;
    line-height: 1.2;
    margin: 0; }
  :local(.viewItineraryPdf) .trix-content-temp blockquote {
    margin: 0 0 0 0.3em;
    padding: 0 0 0 0.6em;
    border-left: 0.3em solid #ccc; }
  :local(.viewItineraryPdf) .trix-content-temp pre {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    font-family: monospace;
    font-size: 0.9em;
    margin: 0;
    padding: 0.5em;
    white-space: pre;
    background-color: #eee;
    overflow-x: auto; }
  :local(.viewItineraryPdf) .trix-content-temp ul,
  :local(.viewItineraryPdf) .trix-content-temp ol,
  :local(.viewItineraryPdf) .trix-content-temp li {
    margin: 0;
    padding: 0; }
    :local(.viewItineraryPdf) .trix-content-temp ul li,
    :local(.viewItineraryPdf) .trix-content-temp ol li,
    :local(.viewItineraryPdf) .trix-content-temp li li {
      margin-left: 1em; }
  :local(.viewItineraryPdf) .trix-content-temp img {
    max-width: 100%;
    height: auto; }
  :local(.viewItineraryPdf) .trix-content-temp .attachment {
    display: inline-block;
    position: relative;
    max-width: 100%;
    margin: 0;
    padding: 0; }
    :local(.viewItineraryPdf) .trix-content-temp .attachment a {
      color: inherit;
      text-decoration: none; }
      :local(.viewItineraryPdf) .trix-content-temp .attachment a:hover, :local(.viewItineraryPdf) .trix-content-temp .attachment a:visited:hover {
        color: inherit; }
  :local(.viewItineraryPdf) .trix-content-temp .attachment__caption {
    padding: 0;
    text-align: center; }
    :local(.viewItineraryPdf) .trix-content-temp .attachment__caption .attachment__name + .attachment__size::before {
      content: " · "; }
  :local(.viewItineraryPdf) .trix-content-temp .attachment--preview {
    width: 100%;
    text-align: left; }
    :local(.viewItineraryPdf) .trix-content-temp .attachment--preview .attachment__caption {
      color: #666;
      font-size: 0.9em;
      line-height: 1.2; }
  :local(.viewItineraryPdf) .trix-content-temp .attachment--file {
    color: #333;
    line-height: 1;
    margin: 0 2px 2px 0;
    padding: 0.4em 1em;
    border: 1px solid #bbb;
    border-radius: 5px; }
  :local(.viewItineraryPdf) .trix-content-temp .attachment-gallery {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 0;
    padding: 0; }
    :local(.viewItineraryPdf) .trix-content-temp .attachment-gallery .attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%; }
    :local(.viewItineraryPdf) .trix-content-temp .attachment-gallery.attachment-gallery--2 .attachment, :local(.viewItineraryPdf) .trix-content-temp .attachment-gallery.attachment-gallery--4 .attachment {
      flex-basis: 50%;
      max-width: 50%; }

:local(.viewItineraryPdf) .container {
  max-width: 100% !important; }

:local(.viewItineraryPdf) .timeline-event {
  width: 100% !important; }
  :local(.viewItineraryPdf) .timeline-event > div:first-of-type {
    left: 35px !important;
    top: 20px !important;
    height: calc(100% - 5rem) !important; }

@media (min-width: 576px) {
  :local(.viewItineraryPdf) .vertical-straight > div:first-child {
    margin-left: 30% !important; } }

@media (min-width: 992px) {
  :local(.viewItineraryPdf) .vertical-straight > div:first-child {
    margin-left: 22.5% !important; } }

@media (min-width: 1200px) {
  :local(.viewItineraryPdf) .vertical-straight > div:first-child {
    margin-left: 19.5% !important; } }

:local(.viewItineraryPdf) .wrapper-content {
  display: flex;
  min-height: 100vh;
  flex-direction: column; }

:local(.viewItineraryPdf) .row {
  margin-right: unset !important; }

:local(.viewItineraryPdf) .total-day {
  color: #4d636e;
  font-size: 1rem;
  font-weight: 600; }

:local(.viewItineraryPdf) .total-amount {
  color: #4d636e;
  font-size: 1rem;
  font-weight: 600; }

:local(.viewItineraryPdf) .wrapper {
  box-shadow: 1px 0 30px rgba(114, 182, 234, 0.2);
  background: #fff; }

:local(.viewItineraryPdf) .wrapper > img {
  display: block;
  margin: 0 auto; }

:local(.viewItineraryPdf) .float-right.view-in-currency:hover {
  cursor: pointer;
  color: #ff484a; }

:local(.viewItineraryPdf) .day-title {
  text-align: center;
  position: relative;
  font-size: 1.5rem;
  width: 100%;
  background-color: #fff;
  top: 0;
  padding: 1rem 1rem 0 0rem;
  z-index: 30;
  border-bottom: 1px solid transparent;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition-property: border-bottom-color;
  -moz-transition-property: border-bottom-color;
  transition-property: border-bottom-color;
  -webkit-transition-duration: 0.25s;
  -moz-transition-duration: 0.25s;
  transition-duration: 0.25s;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex; }
  :local(.viewItineraryPdf) .day-title::after {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
    z-index: 10;
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-top: 1px dotted #d7dfe3; }

:local(.viewItineraryPdf) .day-title span:first-child {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  box-flex: 0;
  -webkit-flex: 0 1 auto;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px; }

:local(.viewItineraryPdf) .day-title span {
  position: relative;
  z-index: 11;
  padding-right: 0.5rem;
  background-color: #fff; }

:local(.viewItinerary) .img-fluid {
  width: 100%;
  border-radius: 5px;
  background-color: #e4e4e4;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 1rem 0;
  position: relative; }

:local(.viewItinerary) .main-title-section {
  font-size: 1.5rem;
  margin-top: 1rem; }

:local(.viewItinerary) .item-in-overview {
  padding: 0 20px; }
  :local(.viewItinerary) .item-in-overview li {
    list-style: disc; }

:local(.viewItinerary) .btn-primary {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
  background-color: #0099ff;
  color: white;
  box-shadow: none; }
  :local(.viewItinerary) .btn-primary:hover {
    background-color: #1389d8;
    color: white; }

:local(.viewItinerary) .author-infor {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background: #f7f7f7;
  margin: 1rem 0; }
  @media (max-width: 576px) {
    :local(.viewItinerary) .author-infor {
      display: inherit; } }
  :local(.viewItinerary) .author-infor .reference-section {
    font-size: 15px;
    align-self: center;
    display: flex;
    width: 80%; }
    :local(.viewItinerary) .author-infor .reference-section a {
      color: #007bff; }
    :local(.viewItinerary) .author-infor .reference-section .author-infor__name {
      font-size: 16px;
      margin-left: 15px; }
    :local(.viewItinerary) .author-infor .reference-section .author-infor__summary {
      font-size: 13px;
      color: #9a9a9a;
      margin-left: 15px; }
      :local(.viewItinerary) .author-infor .reference-section .author-infor__summary span {
        margin-right: 10px; }
      :local(.viewItinerary) .author-infor .reference-section .author-infor__summary .author-infor__summary__detail {
        color: red; }

:local(.viewItinerary) .btn-customize {
  margin-left: 10px;
  color: white;
  box-shadow: none;
  background-image: linear-gradient(120deg, #ff5722, #ffc107);
  width: 20%; }
  @media (max-width: 576px) {
    :local(.viewItinerary) .btn-customize {
      width: 100%;
      margin-left: 0;
      margin-top: 10px; } }

:local(.viewItinerary) .trix-content-temp {
  line-height: 1.5; }
  :local(.viewItinerary) .trix-content-temp * {
    box-sizing: border-box; }
  :local(.viewItinerary) .trix-content-temp h1 {
    font-size: 1.2em;
    line-height: 1.2;
    margin: 0; }
  :local(.viewItinerary) .trix-content-temp blockquote {
    margin: 0 0 0 0.3em;
    padding: 0 0 0 0.6em;
    border-left: 0.3em solid #ccc; }
  :local(.viewItinerary) .trix-content-temp pre {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    font-family: monospace;
    font-size: 0.9em;
    margin: 0;
    padding: 0.5em;
    white-space: pre;
    background-color: #eee;
    overflow-x: auto; }
  :local(.viewItinerary) .trix-content-temp ul,
  :local(.viewItinerary) .trix-content-temp ol,
  :local(.viewItinerary) .trix-content-temp li {
    margin: 0;
    padding: 0; }
    :local(.viewItinerary) .trix-content-temp ul li,
    :local(.viewItinerary) .trix-content-temp ol li,
    :local(.viewItinerary) .trix-content-temp li li {
      margin-left: 1em; }
  :local(.viewItinerary) .trix-content-temp img {
    max-width: 100%;
    height: auto; }
  :local(.viewItinerary) .trix-content-temp .attachment {
    display: inline-block;
    position: relative;
    max-width: 100%;
    margin: 0;
    padding: 0; }
    :local(.viewItinerary) .trix-content-temp .attachment a {
      color: inherit;
      text-decoration: none; }
      :local(.viewItinerary) .trix-content-temp .attachment a:hover, :local(.viewItinerary) .trix-content-temp .attachment a:visited:hover {
        color: inherit; }
  :local(.viewItinerary) .trix-content-temp .attachment__caption {
    padding: 0;
    text-align: center; }
    :local(.viewItinerary) .trix-content-temp .attachment__caption .attachment__name + .attachment__size::before {
      content: " · "; }
  :local(.viewItinerary) .trix-content-temp .attachment--preview {
    width: 100%;
    text-align: left; }
    :local(.viewItinerary) .trix-content-temp .attachment--preview .attachment__caption {
      color: #666;
      font-size: 0.9em;
      line-height: 1.2; }
  :local(.viewItinerary) .trix-content-temp .attachment--file {
    color: #333;
    line-height: 1;
    margin: 0 2px 2px 0;
    padding: 0.4em 1em;
    border: 1px solid #bbb;
    border-radius: 5px; }
  :local(.viewItinerary) .trix-content-temp .attachment-gallery {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 0;
    padding: 0; }
    :local(.viewItinerary) .trix-content-temp .attachment-gallery .attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%; }
    :local(.viewItinerary) .trix-content-temp .attachment-gallery.attachment-gallery--2 .attachment, :local(.viewItinerary) .trix-content-temp .attachment-gallery.attachment-gallery--4 .attachment {
      flex-basis: 50%;
      max-width: 50%; }

:local(.viewItinerary) .chat-bar {
  width: 100%;
  top: 0;
  transition-property: opacity;
  transition-duration: 0.25s;
  padding-bottom: 0.5rem;
  height: auto;
  z-index: 0;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0); }
  :local(.viewItinerary) .chat-bar .str-chat__main-panel > div + div + div + div {
    margin-bottom: 10rem; }

:local(.viewItinerary) nav {
  display: block; }

@media screen and (max-width: 576px) and (max-width: 576px) {
  :local(.viewItinerary) .left-bar {
    min-height: unset !important; } }

@media (max-width: 576px) {
  :local(.viewItinerary) .left-bar .si-day-nav {
    position: unset !important;
    margin-top: 0 !important;
    width: 100% !important; }
  :local(.viewItinerary) .chat-bar {
    position: unset !important;
    margin-top: 0 !important;
    width: 100% !important; } }

:local(.viewItinerary) .left-bar {
  padding-right: 0;
  min-height: calc(100vh - 94px - 53px);
  background: #f7f7f7; }
  :local(.viewItinerary) .left-bar .filter {
    padding: 0;
    background: #e1eef5;
    margin-left: -15px;
    padding-left: 15px; }
    :local(.viewItinerary) .left-bar .filter .main-menu-container {
      margin-bottom: 0;
      padding: 0; }
      :local(.viewItinerary) .left-bar .filter .main-menu-container .ant-menu-submenu.ant-menu-submenu-inline
.ant-menu-submenu-title
.anticon {
        line-height: 65px; }
  :local(.viewItinerary) .left-bar .si-day-nav {
    position: sticky;
    width: 100%;
    top: 0px;
    transition-property: opacity;
    transition-duration: 0.25s;
    padding-bottom: 1rem;
    height: calc(100vh);
    z-index: 0;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 0;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    left: 0; }
    :local(.viewItinerary) .left-bar .si-day-nav ul.si-day-nav-inner a {
      text-decoration: none;
      padding: 0.5rem 1rem 0.5rem 0.75rem;
      color: #7d97a4;
      border-left: 0.125rem solid transparent;
      transition-property: background-color, border-left, color;
      transition-duration: 125ms;
      display: -webkit-box;
      display: -moz-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      box-align: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      -o-align-items: center;
      align-items: center;
      -ms-flex-align: center; }
      :local(.viewItinerary) .left-bar .si-day-nav ul.si-day-nav-inner a:hover {
        background: #fff; }
      :local(.viewItinerary) .left-bar .si-day-nav ul.si-day-nav-inner a.si-day-nav-item-inner {
        font-size: 0.9rem; }
        :local(.viewItinerary) .left-bar .si-day-nav ul.si-day-nav-inner a.si-day-nav-item-inner span {
          display: block; }
        :local(.viewItinerary) .left-bar .si-day-nav ul.si-day-nav-inner a.si-day-nav-item-inner span:first-child {
          letter-spacing: 1px;
          font-weight: 500;
          color: black;
          word-break: break-word;
          padding-top: 5px; }
      :local(.viewItinerary) .left-bar .si-day-nav ul.si-day-nav-inner a._active {
        background: #fff; }
    :local(.viewItinerary) .left-bar .si-day-nav._fixed {
      opacity: 1; }

@media only screen and (max-width: 575px) {
  :local(.viewItinerary) .css-on-mobile {
    background: none !important;
    padding-bottom: 10px;
    border-bottom: 1px solid #f9f5f5; } }

:local(.viewItinerary) .overview.hide-on-desktop ul.si-day-nav-inner a {
  text-decoration: none;
  padding: 0.5rem 1rem 0.5rem 0.75rem;
  color: #7d97a4;
  border-left: 0.125rem solid transparent;
  transition-property: background-color, border-left, color;
  transition-duration: 125ms;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center; }
  :local(.viewItinerary) .overview.hide-on-desktop ul.si-day-nav-inner a:hover {
    background: #fff; }
  :local(.viewItinerary) .overview.hide-on-desktop ul.si-day-nav-inner a.si-day-nav-item-inner {
    font-size: 0.9rem; }
    :local(.viewItinerary) .overview.hide-on-desktop ul.si-day-nav-inner a.si-day-nav-item-inner span {
      display: block; }
    :local(.viewItinerary) .overview.hide-on-desktop ul.si-day-nav-inner a.si-day-nav-item-inner span:first-child {
      letter-spacing: 1px;
      font-weight: 500;
      color: black;
      word-break: break-word;
      padding-top: 5px; }
  :local(.viewItinerary) .overview.hide-on-desktop ul.si-day-nav-inner a._active {
    background: #fff; }

:local(.viewItinerary) .timeline-event {
  width: 100% !important; }
  :local(.viewItinerary) .timeline-event > div:first-of-type {
    left: 35px !important;
    top: 20px !important;
    height: calc(100% - 5rem) !important; }

:local(.viewItinerary) .timeline-event-1 > div:first-of-type {
  display: none; }

:local(.viewItinerary) .container {
  max-width: 100% !important; }
  :local(.viewItinerary) .container .section-chat {
    background: #f7f7f7; }
    :local(.viewItinerary) .container .section-chat .top-right-container {
      padding: 15px;
      margin: 0 -15px;
      background: #e1eef5; }
    :local(.viewItinerary) .container .section-chat .main-title-section {
      margin-bottom: 7px; }
    :local(.viewItinerary) .container .section-chat .livestream.str-chat .str-chat__list {
      background: #fefefe;
      padding: 10px; }
      :local(.viewItinerary) .container .section-chat .livestream.str-chat .str-chat__list .str-chat__li {
        margin: 0 !important; }
      :local(.viewItinerary) .container .section-chat .livestream.str-chat .str-chat__list .str-chat__message-livestream .str-chat__message-livestream-left .str-chat__avatar {
        margin-top: 9px; }
      :local(.viewItinerary) .container .section-chat .livestream.str-chat .str-chat__list .str-chat__message-livestream .str-chat__message-livestream-content .str-chat__message-livestream-author {
        float: left;
        margin-bottom: 0 !important;
        padding: 8px 0 0 10px; }
      :local(.viewItinerary) .container .section-chat .livestream.str-chat .str-chat__list .str-chat__message-livestream .str-chat__message-livestream-content > div > p {
        background-color: #f2f3f5;
        border-radius: 18px;
        padding: 8px 10px; }
    :local(.viewItinerary) .container .section-chat .str-chat__small-message-input-emojiselect {
      align-items: end; }

@media (min-width: 576px) {
  :local(.viewItinerary) .vertical-straight > div:first-child {
    margin-left: 30% !important; } }

@media (min-width: 992px) {
  :local(.viewItinerary) .vertical-straight > div:first-child {
    margin-left: 22.5% !important; } }

@media (min-width: 1200px) {
  :local(.viewItinerary) .vertical-straight > div:first-child {
    margin-left: 3% !important; } }

@media (min-width: 1200px) and (max-width: 1600px) {
  :local(.viewItinerary) .vertical-straight > div:first-child {
    margin-left: 10% !important; } }

:local(.viewItinerary) .wrapper-content {
  display: flex;
  min-height: 100vh;
  flex-direction: column; }
  :local(.viewItinerary) .wrapper-content.tablet {
    min-height: auto; }
    :local(.viewItinerary) .wrapper-content.tablet .left-bar {
      min-height: auto; }
    :local(.viewItinerary) .wrapper-content.tablet .si-day-nav {
      height: auto; }
      :local(.viewItinerary) .wrapper-content.tablet .si-day-nav .ant-anchor-wrapper {
        background: inherit;
        padding-left: 0; }
    :local(.viewItinerary) .wrapper-content.tablet .author-infor {
      flex-direction: column; }
      :local(.viewItinerary) .wrapper-content.tablet .author-infor .company-request-section {
        width: 100%;
        align-items: center;
        margin-bottom: 10px;
        display: block; }
        :local(.viewItinerary) .wrapper-content.tablet .author-infor .company-request-section .btn-request {
          margin-top: 10px;
          width: 100%;
          padding: 6px 8px; }
      :local(.viewItinerary) .wrapper-content.tablet .author-infor .btn-customize {
        width: 100%;
        margin: 0; }

:local(.viewItinerary) .description {
  color: #1f2122;
  font-size: 15px; }

:local(.viewItinerary) .share-section {
  float: right;
  display: inline-flex; }
  :local(.viewItinerary) .share-section .icon {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0.5rem;
    cursor: pointer; }
    :local(.viewItinerary) .share-section .icon.icon-facebook {
      fill: #4267b2; }
    :local(.viewItinerary) .share-section .icon.icon-twitter {
      fill: #00aced; }

.share-by-email {
  cursor: pointer;
  border-radius: 50%; }
  .share-by-email svg {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0.5rem;
    fill: black; }

.day-title {
  display: grid;
  position: relative;
  font-size: 1.4rem;
  width: 100%;
  background-color: #fff;
  top: 0;
  padding: 1rem 1rem 0 0rem;
  z-index: 30; }
  .day-title .each-title-day {
    overflow: hidden;
    font-size: 1.3rem; }
  .day-title .each-title-location {
    padding-top: 5px;
    color: #9a9c9d;
    font-size: 16px; }
    .day-title .each-title-location img {
      margin-right: 5px;
      width: 15px; }

.section-title-itinerary .name {
  font-size: 20px;
  width: 100%;
  font-weight: bold;
  margin: 10px 0; }
  @media screen and (max-width: 768px) {
    .section-title-itinerary .name {
      width: 100%; } }

.section-title-itinerary .total-day {
  color: #4d636e;
  font-size: 1rem;
  font-weight: 600; }

.section-title-itinerary .total-amount {
  color: #4d636e;
  font-size: 1rem;
  font-weight: 600; }

.section-title-itinerary .wrapper {
  background: #fff;
  padding: 1rem 15px;
  min-height: calc(100vh - 94px - 53px); }

.section-title-itinerary .wrapper > img {
  display: block;
  margin: 0 auto;
  cursor: pointer; }

.section-title-itinerary .float-right.view-in-currency:hover {
  cursor: pointer;
  color: #ff484a; }

.btn-pos {
  display: none; }
  @media only screen and (max-width: 575px) {
    .btn-pos {
      display: block; } }
  .btn-pos .ant-affix {
    left: 20px;
    z-index: 1000; }
    .btn-pos .ant-affix .ant-btn-icon-only.ant-btn-lg {
      width: 50px;
      height: 50px; }
      .btn-pos .ant-affix .ant-btn-icon-only.ant-btn-lg > i {
        font-size: 28px; }

.drawer-select .ant-drawer-content-wrapper {
  height: 50% !important; }
  .drawer-select .ant-drawer-content-wrapper .ant-drawer-wrapper-body {
    overflow: hidden; }
    .drawer-select .ant-drawer-content-wrapper .ant-drawer-wrapper-body .ant-drawer-body {
      height: calc(100% - 55px);
      overflow: auto; }
