@import "../../assets/sass/variables.scss";

:local(.loading) {
  margin-bottom: 8px;

  .thumbnail-places {
    width: 70px;
    height: 70px;
    margin-left: -10px;
    flex-shrink: 0;
    -webkit-box-flex: 0;
    flex-grow: 0;
    border-radius: 50%;
    border: 1px solid #fff;
  }

  .btn-add-place > svg {
    cursor: pointer;
    &:hover {
      background-color: rgba(69, 187, 255, 0.05) !important;
      fill: #45bbff !important;
    }
    transition-duration: 0.25s;
    transition-property: fill;
    fill: #ecf0f2;
  }

  .btn-add-place {
    position: relative;
  }

  div[role="progressbar"] {
    color: #45bbff !important;
    height: unset !important;
    width: unset !important;
  }

  .sidebar-idea-container {
    margin: 1rem;
    background-color: transparent;
  }
  .idea-tile-horizontal {
    border-radius: 0.25rem;
    border: 1px solid #ecf0f2;
    background-color: #fff;
    box-shadow: 0 0.25rem 2rem -0.5rem rgba(28, 75, 102, 0.3);
    //height: 5.4rem;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    box-orient: horizontal;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    flex-direction: row;
    -ms-flex-direction: row;
    -webkit-transform: scale(1.001);
    -moz-transform: scale(1.001);
    -ms-transform: scale(1.001);
    -o-transform: scale(1.001);
    transform: scale(1.001);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition-property: -webkit-transform, box-shadow;
    -moz-transition-property: -moz-transform, box-shadow;
    transition-property: transform, box-shadow;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
  }
  .idea-tile-horizontal > figure {
    position: relative;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 0 9rem;
    -moz-flex: 0 0 9rem;
    -ms-flex: 0 0 9rem;
    flex: 0 0 9rem;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    min-height: 4rem;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    background-image: url("../../assets/media/images/generic-activities-img.jpg");
  }

  .idea-tile-horizontal > figure {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
  }

  figure {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  .idea-tile-horizontal > figure .btn {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
    opacity: 0;
  }

  .idea-tile-horizontal._can-view-details:hover figure {
    position: relative;
  }

  .idea-tile-horizontal._can-view-details:hover figure .btn {
    opacity: 1;
  }

  .idea-tile-horizontal > figure > * {
    position: relative;
    z-index: 10;
  }
  ._btn-small {
    height: 2.1rem;
    padding-left: 0.35rem;
    padding-right: 0.35rem;
  }
  ._btn-outline-white {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
    border: 1px solid #d7dfe3;
  }

  ._btn-outline-white:focus,
  ._btn-outline-white:hover {
    color: #fff;
    border: 1px solid #fff;
  }
  ._btn-outline-white:focus,
  ._btn-outline-white:hover {
    color: #fff;
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 0.125);
  }

  ._btn-outline-white,
  ._btn-outline-white:hover {
    border: 1px solid springgreen !important;
  }

  .idea-tile-horizontal._can-view-details:hover figure:after {
    position: absolute;
    content: "";
    background-color: rgba(5, 37, 48, 0.6);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .idea-tile-horizontal > figure:after {
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    -webkit-transition-property: background-color;
    -moz-transition-property: background-color;
    transition-property: background-color;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
    position: absolute;
    content: "";
    background-color: rgba(5, 37, 48, 0);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  *,
  ::after,
  ::before {
    box-sizing: inherit;
  }

  .idea-tile-horizontal ._c-info {
    padding: 0.25rem 0;
    overflow: hidden;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-flex-direction: column;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    > .MuiSkeleton-root {
      padding: 0.25rem 0.5rem !important;
      margin-left: 0.5rem;
    }
  }
  .idea-tile-horizontal ._c-info li:first-child {
    color: #052530;
    font-weight: 700;
  }
  .idea-tile-horizontal ._c-info li {
    padding: 0.25rem 0.5rem;
    color: #7d97a4;
    font-size: 0.9rem;
  }
  .idea-tile-horizontal ._c-info li span {
    display: block;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }
  .idea-tile-horizontal ._c-info li span p {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .click-to-add {
    padding: 2rem 0.5rem;
    cursor: pointer;
  }

  .click-to-remove {
    padding: 2rem 0.2rem;
    cursor: pointer;
  }

  @media only screen and (min-width: 75em) {
    .click-to-add > svg {
      fill: #7c8e9c;
      width: 1.5rem;
      height: 1.5rem;
      &.isChecked {
        fill: #4db776;
      }
    }

    .click-to-remove > svg {
      fill: #de014f;
      width: 1.5rem;
      height: 1.5rem;
      &.isChecked {
        fill: #4db776;
      }
    }
  }

  .click-to-add {
    &:hover {
      > svg {
        fill: #45bbff;
        -webkit-transition: 0.35s;
        -moz-transition: 0.35s;
        transition: 0.35s;
        cursor: pointer;
      }
    }
  }

  .zoom-place {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: 0.7s ease all;
    -webkit-transition: 0.7s ease all;
    -moz-transition: 0.7s ease all;
    //width: 100%;
    margin: 0 auto;
    overflow-x: hidden;
  }

  .zoom-place:hover {
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }

  #map {
    height: 100%;
  }
  /* Optional: Makes the sample page fill the window. */
  #description {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
  }

  #infowindow-content .title {
    font-weight: bold;
  }

  #infowindow-content {
    display: none;
  }

  #map #infowindow-content {
    display: inline;
  }

  .pac-card {
    margin: 10px 10px 0 0;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    font-family: Roboto;
  }

  #pac-container {
    padding-bottom: 12px;
    margin-right: 12px;
  }

  .pac-controls {
    display: inline-block;
    padding: 5px 11px;
  }

  .pac-controls label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
  }

  #pac-input {
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px;
  }

  #pac-input:focus {
    border-color: #4d90fe;
  }

  #title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px;
  }
}

:local(.breadcrumb) {
  padding: 0 15px;
  h3 {
    color: black;
    font-size: 20px;
  }

  .MuiPaper-root {
    background: unset;
  }

  ol.MuiBreadcrumbs-ol {
    display: -webkit-box;
    white-space: nowrap;

    li {
      font-size: 12px;
    }

    li.MuiBreadcrumbs-li:last-of-type {
      width: 70%;
    }

    p.MuiTypography-body1 {
      overflow: hidden;
      font-size: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

:local(.contentTrendy) {
  .top-new {
    > span {
      font-size: 1rem;
      line-height: 2.1rem;
    }
  }

  .menu-popular-tyneries {
    @media (min-width: $screen-xl) and (max-width: $screen-xxl) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .rc-menu-horizontal > .rc-menu-submenu,
  .rc-menu-horizontal > .rc-menu-item {
    background: #de014f;
    border-bottom: 2px solid #de014f !important;
  }

  .none-background {
    background-color: unset;
    border-bottom: unset;
  }

  .try-sample {
    width: 155.5px;
    &:hover {
      text-decoration: none;
    }
    > div {
      background-color: #de014f !important;
      box-shadow: none !important;
      &:hover {
        background-color: #cc064c !important;
        color: white !important;
      }
      span {
        color: #fff !important;
        // > svg {
        //   width: 1rem;
        //   height: 1rem;
        // }
      }
    }
  }

  .container {
    max-width: 100%;
    min-height: calc(100vh - 94px - 53px);
    background: #fff;
    box-shadow: 1px 5px 30px rgba(114, 182, 234, 0.2);
  }

  .sidebar-idea-container {
    margin: 1rem;
    background-color: transparent;
  }
  .idea-tile-horizontal {
    border-radius: 0.25rem;
    border: 1px solid #ecf0f2;
    background-color: #fff;
    box-shadow: 0 0.25rem 2rem -0.5rem rgba(28, 75, 102, 0.3);
    //height: 5.4rem;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    box-orient: horizontal;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    flex-direction: row;
    -ms-flex-direction: row;
    -webkit-transform: scale(1.001);
    -moz-transform: scale(1.001);
    -ms-transform: scale(1.001);
    -o-transform: scale(1.001);
    transform: scale(1.001);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition-property: -webkit-transform, box-shadow;
    -moz-transition-property: -moz-transform, box-shadow;
    transition-property: transform, box-shadow;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
  }
  .idea-tile-horizontal > figure {
    position: relative;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 0 9rem;
    -moz-flex: 0 0 9rem;
    -ms-flex: 0 0 9rem;
    flex: 0 0 9rem;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    min-height: 4rem;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    background-image: url("../../assets/media/images/generic-activities-img.jpg");
  }

  .idea-tile-horizontal > figure {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
  }

  figure {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  .idea-tile-horizontal > figure .btn {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
    opacity: 0;
  }

  .idea-tile-horizontal._can-view-details:hover figure {
    position: relative;
  }

  .idea-tile-horizontal._can-view-details:hover figure .btn {
    opacity: 1;
  }

  .idea-tile-horizontal > figure > * {
    position: relative;
    z-index: 10;
  }
  ._btn-small {
    height: 2.1rem;
    padding-left: 0.35rem;
    padding-right: 0.35rem;
  }
  ._btn-outline-white {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
    border: 1px solid #d7dfe3;
  }

  ._btn-outline-white:focus,
  ._btn-outline-white:hover {
    color: #fff;
    border: 1px solid #fff;
  }
  ._btn-outline-white:focus,
  ._btn-outline-white:hover {
    color: #fff;
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 0.125);
  }

  ._btn-outline-white,
  ._btn-outline-white:hover {
    border: 1px solid springgreen !important;
  }

  // .btn {
  //   cursor: pointer;
  //   position: relative;
  //   text-transform: initial;
  //   border: 1px solid transparent;
  //   overflow: hidden;
  //   text-align: center;
  //   border-radius: 0.25rem;
  //   font-family: proxima-nova, sans-serif;
  //   font-weight: 400;
  //   -webkit-backface-visibility: hidden;
  //   backface-visibility: hidden;
  //   -webkit-transform: scale(1);
  //   -moz-transform: scale(1);
  //   -ms-transform: scale(1);
  //   -o-transform: scale(1);
  //   transform: scale(1);
  //   font-size: 1rem;
  //   line-height: 1;
  //   display: -webkit-box;
  //   display: -moz-box;
  //   display: -webkit-flex;
  //   display: -moz-flex;
  //   display: -ms-flexbox;
  //   display: flex;
  //   -webkit-flex-flow: row wrap;
  //   -moz-flex-flow: row wrap;
  //   flex-flow: row wrap;
  //   -webkit-box-align: center;
  //   -moz-box-align: center;
  //   box-align: center;
  //   -webkit-align-items: center;
  //   -moz-align-items: center;
  //   -ms-align-items: center;
  //   -o-align-items: center;
  //   align-items: center;
  //   -ms-flex-align: center;
  //   -webkit-box-pack: center;
  //   -moz-box-pack: center;
  //   box-pack: center;
  //   -webkit-justify-content: center;
  //   -moz-justify-content: center;
  //   -ms-justify-content: center;
  //   -o-justify-content: center;
  //   justify-content: center;
  //   -ms-flex-pack: center;
  //   height: 2.9rem;
  //   padding-left: 0.7rem;
  //   padding-right: 0.7rem;
  //   -webkit-transition-property: -webkit-transform, background-color, border, color, box-shadow;
  //   -moz-transition-property: -moz-transform, background-color, border, color, box-shadow;
  //   transition-property: transform, background-color, border, color, box-shadow;
  //   -webkit-transition-duration: 0.25s;
  //   -moz-transition-duration: 0.25s;
  //   transition-duration: 0.25s;
  //   font-weight: 400;
  // }

  .idea-tile-horizontal._can-view-details:hover figure:after {
    position: absolute;
    content: "";
    background-color: rgba(5, 37, 48, 0.6);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .idea-tile-horizontal > figure:after {
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    -webkit-transition-property: background-color;
    -moz-transition-property: background-color;
    transition-property: background-color;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
    position: absolute;
    content: "";
    background-color: rgba(5, 37, 48, 0);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  *,
  ::after,
  ::before {
    box-sizing: inherit;
  }

  .idea-tile-horizontal ._c-info {
    padding: 0.25rem 0;
    overflow: hidden;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-flex-direction: column;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
  }
  .idea-tile-horizontal ._c-info li:first-child {
    color: #052530;
    font-weight: 0;
  }

  .idea-tile-horizontal ._c-info li {
    padding: 0.25rem 0.5rem;
    color: #7d97a4;
    font-size: 0.9rem;
  }
  .idea-tile-horizontal ._c-info li span {
    display: block;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }
  .click-to-add {
    padding: 2rem 1rem;
    cursor: pointer;
  }

  @media only screen and (min-width: 75em) {
    .click-to-add > svg {
      fill: #7c8e9c;
      width: 1.5rem;
      height: 1.5rem;
      &.isChecked {
        fill: #4db776;
      }
    }
  }

  .click-to-add {
    &:hover {
      > svg {
        fill: #0f95de;
        -webkit-transition: 0.35s;
        -moz-transition: 0.35s;
        transition: 0.35s;
        cursor: pointer;
      }
    }
  }

  .zoom-place {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: 0.7s ease all;
    -webkit-transition: 0.7s ease all;
    -moz-transition: 0.7s ease all;
    margin: 0 auto;
    overflow-x: hidden;
    margin: 1rem 0;
  }

  .zoom-place:hover {
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }

  #map {
    height: 100%;
  }
  /* Optional: Makes the sample page fill the window. */
  #description {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
  }

  #infowindow-content .title {
    font-weight: bold;
  }

  #infowindow-content {
    display: none;
  }

  #map #infowindow-content {
    display: inline;
  }

  .pac-card {
    margin: 10px 10px 0 0;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    font-family: Roboto;
  }

  #pac-container {
    padding-bottom: 12px;
    margin-right: 12px;
  }

  .pac-controls {
    display: inline-block;
    padding: 5px 11px;
  }

  .pac-controls label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
  }

  #pac-input {
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px;
  }

  #pac-input:focus {
    border-color: #4d90fe;
  }

  #title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px;
  }

  .content-center {
    background-color: white;
    min-height: 100vh;
    padding: 0 10px;
    @media only screen and (max-width: $screen-xs-max) {
      padding: 0;
    }
  }
  .content-right {
    padding-top: 10px;
    @media only screen and (max-width: $screen-md-max) {
      display: none;
    }
    .top-right-container {
      margin: 15px;
    }
  }
  .custom-card-tyneri {
    padding: 8px;
    float: left;
  }
}

.list-tinerary {
  margin-top: 15px;
}

.list-data-item {
  padding: 0 15px;
  @media only screen and (max-width: $screen-xs-max) {
    padding: 0;
  }
}
.each-card-my-plans {
  margin: 1rem 0;
}
