@import "../../assets/sass/variables.scss";

:local(.headerTopList) {
  margin-bottom: 10px;

  .company-request-section {
    padding: 15px;
    margin: 1rem 0;
    width: 100%;
  }
  .img-fluid {
    width: 100%;
  }
  .row-description {
    .ant-typography {
      margin-top: 1em;
    }
  }

  .head-title {
    > .ant-typography {
      @media only screen and (max-width: $screen-xs-max) {
        margin-top: .5em;
        font-size: 26px;
      }
    }
  }

  .category-share {
    display: flex;
    justify-content: space-between;
    .tag-category {
      background: #EBEBEB;
      text-transform: uppercase;
      font-weight: bold;
      padding: 2px 5px;
      width: fit-content;
      border-radius: 6px;
      font-size: 14px;
    }
    .share-section {
      float: right;
      display: inline-flex;
      .icon {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 0.5rem;
        cursor: pointer;
        &.icon-facebook {
          fill: #4267b2;
        }
        &.icon-twitter {
          fill: #00aced;
        }
      }
    }
  }

  .type-user {
    display: block;
    font-size: 70%;
    font-weight: normal;
  }

  .btn-primary {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
    background-color: #0099ff;
    color: white;
    box-shadow: none;
    &:hover {
      background-color: #1389d8;
      color: white;
    }
  }
}
