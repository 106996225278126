/*

ART FACTORY

https://templatemo.com/tm-537-art-factory

*/

/* 
---------------------------------------------
Table of contents
---------------------------------------------
01. font & reset css
02. reset
03. global styles
04. header
05. welcome
06. features
07. team
08. pricing
09. blog
10. contact
11. footer
12. preloader

--------------------------------------------- 
*/

/* 
---------------------------------------------
font & reset css
--------------------------------------------- 
*/
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900");
/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
div pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
figure,
header,
nav,
section,
article,
aside,
footer,
figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header,
nav,
section,
article,
aside,
footer,
hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  background-color: #fff;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  margin-bottom: 0px;
}

ul {
  margin-bottom: 0px;
}

p {
  font-size: 15px;
  line-height: 25px;
  color: #4a4a4a;
}

/* 
---------------------------------------------
global styles
--------------------------------------------- 
*/
html,
body {
  background: #fff;
  font-family: "Roboto", sans-serif;
}

::selection {
  background: #ff589e;
  color: #fff;
}

::-moz-selection {
  background: #ff589e;
  color: #fff;
}

@media (max-width: 991px) {
  html,
  body {
    overflow-x: hidden;
  }
  .mobile-top-fix {
    margin-top: 30px;
    margin-bottom: 0px;
  }
  .mobile-bottom-fix {
    margin-bottom: 30px;
  }
  .mobile-bottom-fix-big {
    margin-bottom: 60px;
  }
}

a.main-button-slider {
  font-size: 13px;
  border-radius: 20px;
  padding: 12px 20px;
  background-color: #f55858;
  text-transform: uppercase;
  color: #fff !important;
  letter-spacing: 0.25px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

a.main-button-slider:hover {
  background-color: #0088e8;
}

a.main-button {
  font-size: 13px;
  border-radius: 20px;
  padding: 12px 20px;
  background-color: #00b4c6;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.25px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

a.main-button:hover {
  background-color: #0088e8;
}

button.main-button {
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 13px;
  border-radius: 20px;
  padding: 10px 20px;
  background-color: #f55858;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.25px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

button.main-button:hover {
  background-color: #0088e8;
}

.section-heading h2 {
  text-align: center;
  font-size: 48px;
  font-weight: 300;
  color: #232323;
  margin-bottom: 20px;
}

.section-heading p {
  font-size: 17px;
  color: #777;
  text-align: center;
}

/* 
---------------------------------------------
header
--------------------------------------------- 
*/

.background-header {
  background-color: #fff;
  height: 80px !important;
  position: fixed !important;
  top: 0px;
  left: 0px;
  right: 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15) !important;
}

.background-header .logo,
.background-header .main-nav .nav li a {
  color: #1e1e1e !important;
}

.background-header .main-nav .nav > li:hover > a {
  color: #f55858 !important;
}

.background-header .main-nav .nav > li > ul > li:hover > a {
  color: #f55858 !important;
}

.background-header .nav li a.active {
  color: #f55858 !important;
}

.header-area {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;
  height: 100px;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.header-area .main-nav {
  min-height: 80px;
  background: transparent;
}

/* .header-area .main-nav > div + div {
  display: inline;
} */

.header-area .main-nav .logo {
  line-height: 80px;
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  float: left;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.header-area .main-nav .nav {
  float: right;
  margin-top: 27px;
  margin-left: 20px;
  margin-right: 0px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 999;
}

.header-area .main-nav .nav li {
  padding-left: 20px;
  padding-right: 20px;
}

.header-area .main-nav .nav li:last-child {
  padding-right: 0px;
}

.header-area .main-nav .nav li a {
  display: block;
  font-weight: 500;
  font-size: 13px;
  color: #fff;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 40px;
  line-height: 40px;
  border: transparent;
  letter-spacing: 1px;
}

.header-area .main-nav .nav li:hover a {
  color: #f55858;
}

.header-area .main-nav .nav li.submenu {
  position: relative;
  padding-right: 35px;
}

.header-area .main-nav .nav li.submenu:after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 12px;
  color: #fff;
  position: absolute;
  right: 18px;
  top: 12px;
}

.header-area.background-header .main-nav .nav li.submenu:after {
  color: #000;
}

.header-area .main-nav .nav li.submenu ul {
  position: absolute;
  width: 200px;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  top: 40px;
  opacity: 0;
  transform: translateY(-2em);
  visibility: hidden;
  z-index: -1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
}

.header-area .main-nav .nav li.submenu ul li {
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.header-area .main-nav .nav li.submenu ul li a {
  display: block;
  background: #fff;
  color: #3b566e;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  font-size: 13px;
  border-bottom: 1px solid #f5f5f5;
}

.header-area .main-nav .nav li.submenu ul li a:before {
  content: "";
  position: absolute;
  width: 0px;
  height: 40px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background: #f55858;
}

.header-area .main-nav .nav li.submenu ul li a:hover {
  background: #fff;
  color: #f55858;
  padding-left: 25px;
}

.header-area .main-nav .nav li.submenu ul li a:hover:before {
  width: 3px;
}

.header-area .main-nav .nav li.submenu:hover ul {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.header-area .main-nav .menu-trigger {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 15px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 40px;
  display: none;
}

.header-area .main-nav .menu-trigger span,
.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #1e1e1e;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}

.background-header .main-nav .menu-trigger span,
.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #1e1e1e;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
  width: 75%;
}

.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  content: "";
}

.header-area .main-nav .menu-trigger span {
  top: 16px;
}

.header-area .main-nav .menu-trigger span:before {
  -moz-transform-origin: 33% 100%;
  -ms-transform-origin: 33% 100%;
  -webkit-transform-origin: 33% 100%;
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

.header-area .main-nav .menu-trigger span:after {
  -moz-transform-origin: 33% 0;
  -ms-transform-origin: 33% 0;
  -webkit-transform-origin: 33% 0;
  transform-origin: 33% 0;
  top: 10px;
}

.header-area .main-nav .menu-trigger.active span,
.header-area .main-nav .menu-trigger.active span:before,
.header-area .main-nav .menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.header-area .main-nav .menu-trigger.active span:before {
  -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
  -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
  -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #1e1e1e;
}

.background-header .main-nav .menu-trigger.active span:before {
  background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger.active span:after {
  -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #1e1e1e;
}

.background-header .main-nav .menu-trigger.active span:after {
  background-color: #1e1e1e;
}

.header-area.header-sticky {
  min-height: 80px;
}

.header-area.header-sticky .nav {
  margin-top: 20px !important;
}

.header-area.header-sticky .nav li a.active {
  color: #f55858;
}

@media (max-width: 1200px) {
  .header-area .main-nav .nav li {
    padding-left: 12px;
    padding-right: 12px;
  }
  .header-area .main-nav:before {
    display: none;
  }
}

@media (max-width: 991px) {
  .header-area {
    padding: 0px 15px;
    height: 80px;
    box-shadow: none;
    text-align: center;
  }
  .header-area .container {
    padding: 0px;
  }
  .header-area .logo {
    margin-left: 30px;
  }
  .header-area .menu-trigger {
    display: block !important;
  }
  .header-area .main-nav {
    overflow: hidden;
  }
  .header-area .main-nav .nav {
    float: none;
    width: 100%;
    margin-top: 80px !important;
    display: none;
    -webkit-transition: all 0s ease 0s;
    -moz-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
    margin-left: 0px;
  }
  .header-area .main-nav .nav li:first-child {
    border-top: 1px solid #eee;
  }
  .header-area .main-nav .nav li {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #eee;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .header-area .main-nav .nav li a {
    height: 50px !important;
    line-height: 50px !important;
    padding: 0px !important;
    border: none !important;
    background: #fff !important;
    color: #3b566e !important;
  }
  .header-area .main-nav .nav li a:hover {
    background: #eee !important;
  }
  .header-area .main-nav .nav > li > a:hover,
  .header-area .main-nav .nav .submenu > ul > li > a:hover {
    color: #f55858;
  }
  .header-area .main-nav .nav li.submenu ul {
    position: relative;
    visibility: inherit;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
    top: 0px;
    width: 100%;
    box-shadow: none;
    height: 0px;
  }
  .header-area .main-nav .nav li.submenu ul li a {
    font-size: 12px;
    font-weight: 400;
  }
  .header-area .main-nav .nav li.submenu ul li a:hover:before {
    width: 0px;
  }
  .header-area .main-nav .nav li.submenu ul.active {
    height: auto !important;
  }
  .header-area .main-nav .nav li.submenu:after {
    color: #3b566e;
    right: 25px;
    font-size: 14px;
    top: 15px;
  }
  .header-area .main-nav .nav li.submenu:hover ul,
  .header-area .main-nav .nav li.submenu:focus ul {
    height: 0px;
  }
}

@media (min-width: 992px) {
  .header-area .main-nav .nav {
    display: flex !important;
  }
}

/* 
---------------------------------------------
welcome
--------------------------------------------- 
*/
#welcome {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  background-image: linear-gradient(40deg, #2e5bff 0%, #2effda 100%);
  background-repeat: no-repeat;
  /*
TRY IT ... with or without CENTER CENTER
  background-position: center center; 
*/
  background-size: cover;
  width: 100%;
  height: 105vh;
  margin-top: -10vh;
}

@keyframes shapAnimation3 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  20% {
    -webkit-transform: translate(-10px, -5px);
    transform: translate(-10px, -5px);
  }
  40% {
    -webkit-transform: translate(0, -10px);
    transform: translate(0, -10px);
  }
  60% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
  }
  80% {
    -webkit-transform: translate(10px, 10px);
    transform: translate(10px, 10px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.welcome-area .header-text {
  position: absolute;
  top: 55%;
  transform: translateY(-60%);
  text-align: left;
  width: 100%;
}

.welcome-area .header-text .left-text {
  align-self: center;
  z-index: 2;
}

.welcome-area .header-text h1 {
  font-weight: 400;
  font-size: 64px;
  line-height: 80px;
  letter-spacing: 1.4px;
  margin-bottom: 30px;
  color: #fff;
}

.welcome-area .header-text p {
  font-weight: 300;
  font-size: 17px;
  color: #fff;
  line-height: 30px;
  letter-spacing: 0.25px;
  margin-bottom: 40px;
  position: relative;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .welcome-area .header-text {
    top: 50% !important;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .welcome-area .header-text {
    top: 40% !important;
    transform: perspective(1px) translateY(-60%) !important;
  }
  .welcome-area .header-text h1 {
    font-size: 36px;
    line-height: 50px;
    color: #fff;
    margin-bottom: 15px;
  }
  .welcome-area .header-text h1 span {
    color: #fff;
  }
  .welcome-area .header-text p {
    color: #fff;
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .welcome-area .header-text {
    top: 40% !important;
    transform: perspective(1px) translateY(-60%) !important;
  }
  .welcome-area .header-text h1 {
    font-size: 36px;
    line-height: 50px;
    color: #fff;
    margin-bottom: 15px;
  }
  .welcome-area .header-text h1 span {
    color: #fff;
  }
  .welcome-area .header-text p {
    color: #fff;
    margin-bottom: 30px;
  }
}

@media (max-width: 820px) {
  .welcome-area .header-text {
    top: 30% !important;
    transform: perspective(1px) translateY(-60%) !important;
  }
  .welcome-area .header-text h1 {
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 15px;
  }
  .welcome-area .header-text p {
    margin-bottom: 30px;
  }
}

@media (max-width: 765px) {
  .welcome-area {
    margin-bottom: 100px;
  }
  .welcome-area .header-text {
    top: 40% !important;
    transform: perspective(1px) translateY(-50%) !important;
    text-align: center;
  }
  .welcome-area .header-text .buttons {
    display: none;
  }
  .welcome-area .header-text h1 {
    font-weight: 600;
    font-size: 24px !important;
    line-height: 30px !important;
    margin-bottom: 20px !important;
  }
  .welcome-area .header-text h1 span {
    color: #fff;
  }
  .welcome-area .header-text p {
    text-align: center;
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 40px;
  }
  .welcome-area .header-text img {
    width: 80%;
    margin-top: 20px;
  }
}

@media (max-width: 576px) {
  .welcome-area {
    margin-bottom: 100px;
  }
  .welcome-area .header-text {
    top: 30% !important;
    transform: perspective(1px) translateY(-50%) !important;
    text-align: center;
  }
  .welcome-area .header-text .buttons {
    display: none;
  }
  .welcome-area .header-text h1 {
    font-weight: 600;
    font-size: 24px !important;
    line-height: 30px !important;
    margin-bottom: 20px !important;
  }
  .welcome-area .header-text h1 span {
    color: #fff;
  }
  .welcome-area .header-text p {
    text-align: center;
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 40px;
  }
  .welcome-area .header-text img {
    width: 80%;
    margin-top: 20px;
  }
}

/*
---------------------------------------------
about
---------------------------------------------
*/

#about {
  padding: 120px 0px;
  /* border-bottom: 1px solid #eee; */
}

@media (max-width: 765px) {
  #about {
    padding: 20px 0px 120px 0px;
  }
}

#about .right-text {
  align-self: center;
}

#about .right-text p {
  margin-bottom: 40px;
}

#about2 {
  padding: 120px 0px;
  clip-path: polygon(0 6rem, 100% 0%, 100% 100%, 0 100%);
  background: #f3fbff;
}

#about2 .right-image {
  align-self: center;
}

#about h5,
#about2 h5 {
  font-size: 26px;
  font-weight: 700;
  color: #1e1e1e;
  line-height: 40px;
  margin-bottom: 25px;
  letter-spacing: 0.5px;
}

#about p,
#about2 p {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.5px;
}

#about2 ul li img {
  display: inline;
  float: left;
}

#about2 ul li .text {
  margin-left: 80px;
}

#about2 ul li .text h6 {
  padding-top: 12px;
  font-size: 21px;
  font-weight: 500;
  color: #1e1e1e;
  margin-bottom: 20px;
}

/* 
---------------------------------------------
services
--------------------------------------------- 
*/
#services {
  position: relative;
  padding: 120px 0px 90px 0px;
  background: rgba(0, 136, 232, 1);
  background: -moz-linear-gradient(
    -45deg,
    rgba(0, 136, 232, 1) 0%,
    rgba(0, 182, 198, 1) 0%,
    rgba(0, 136, 232, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right bottom,
    color-stop(0%, rgba(0, 136, 232, 1)),
    color-stop(0%, rgba(0, 182, 198, 1)),
    color-stop(100%, rgba(0, 136, 232, 1))
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(0, 136, 232, 1) 0%,
    rgba(0, 182, 198, 1) 0%,
    rgba(0, 136, 232, 1) 100%
  );
  background: -o-linear-gradient(
    -45deg,
    rgba(0, 136, 232, 1) 0%,
    rgba(0, 182, 198, 1) 0%,
    rgba(0, 136, 232, 1) 100%
  );
  background: -ms-linear-gradient(
    -45deg,
    rgba(0, 136, 232, 1) 0%,
    rgba(0, 182, 198, 1) 0%,
    rgba(0, 136, 232, 1) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(0, 136, 232, 1) 0%,
    rgba(0, 182, 198, 1) 0%,
    rgba(0, 136, 232, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0088e8', endColorstr='#0088e8', GradientType=1 );
}

.service-item {
  cursor: pointer;
  display: block;
  background: #ffffff;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.1);
  padding: 40px 30px;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
}

@media screen and (max-width: 920px) {
  .service-item {
    margin: 0px 30px !important;
  }
}

.service-item:hover .icon,
.service-item:hover a.main-button {
  background-color: #0088e8;
}

.service-item .icon {
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 100px;
  height: 100px;
  line-height: 100px;
  margin: auto;
  position: relative;
  margin-bottom: 30px;
  background: #00b4c6;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: relative;
}

.service-item .icon img {
  max-width: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.service-item .service-title {
  font-weight: 500;
  font-size: 18px;
  color: #1e1e1e;
  letter-spacing: 0.7px;
  margin-bottom: 15px;
  position: relative;
  z-index: 2;
}

.service-item p {
  font-weight: 400;
  font-size: 13px;
  color: #777;
  letter-spacing: 0.5px;
  line-height: 25px;
  position: relative;
  z-index: 2;
}

.service-item a.main-button {
  margin-top: 30px;
  display: inline-block;
}

.owl-carousel .owl-dots {
  margin-top: 60px;
  text-align: center;
  align-self: center;
}

.owl-carousel button.owl-dot span {
  border-radius: 50%;
  background-color: #fff;
  width: 5px;
  height: 5px;
  display: inline-block;
  margin: 0px 5px;
}

.owl-carousel button.active span {
  width: 8px;
  height: 8px;
  padding-top: 1.5px;
}

.owl-carousel {
  position: relative;
}

.owl-carousel .owl-nav button.owl-next span {
  top: 32%;
  border-radius: 50%;
  font-size: 36px;
  color: #fff;
  right: -80px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  display: inline-block;
  background-color: rgba(250, 250, 250, 0.15);
  position: absolute;
}

.owl-carousel .owl-nav button.owl-prev span {
  top: 32%;
  border-radius: 50%;
  font-size: 36px;
  color: #fff;
  left: -80px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  display: inline-block;
  background-color: rgba(250, 250, 250, 0.15);
  position: absolute;
}

@media screen and (max-width: 1320px) {
  .owl-carousel .owl-nav {
    display: none !important;
  }
}

/*
---------------------------------------------
accordions
---------------------------------------------
*/

#frequently-question {
  padding: 120px 0px;
}

#frequently-question .section-heading p {
  margin-bottom: 80px;
}

#frequently-question .left-text {
  align-self: center !important;
}

#frequently-question .left-text h5 {
  font-size: 26px;
  font-weight: 700;
  color: #1e1e1e;
  line-height: 40px;
  margin-bottom: 25px;
  letter-spacing: 0.5px;
}

#frequently-question .left-text p {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.5px;
}

#frequently-question .left-text span {
  font-size: 16px;
  color: #4a4a4a;
  margin-bottom: 50px;
  display: block;
}

#frequently-question .left-text span a {
  font-weight: 500;
}

@media (max-width: 765px) {
  #frequently-question .left-text {
    margin-bottom: 60px;
  }
}

.accordions {
  background-color: #f7f7f7;
}
.accordion-head {
  padding: 20px;
  font-size: 20px;
  cursor: pointer;
  transition: color 200ms ease-in-out;
  border-bottom: 1px solid #fff;
}
@media screen and (min-width: 768px) {
  .accordion-head {
    padding: 1rem;
    font-size: 1.2rem;
  }
}
.accordion-head .icon {
  float: right;
  transition: transform 200ms ease-in-out;
}
.accordion-head.is-open {
  color: #0088e8;
  border-bottom: none;
}
.accordion-head.is-open .icon {
  transform: rotate(45deg);
}
.accordion-body {
  overflow: hidden;
  height: 0;
  transition: height 300ms ease-in-out;
  border-bottom: 1px solid #fff;
}
.accordion-body > .content {
  padding: 20px;
  padding-top: 0;
}

/* 
---------------------------------------------
contact
--------------------------------------------- 
*/

#contact-us .container-fluid {
  padding-right: 0px;
  padding-left: 0px;
  overflow-x: hidden;
}

#contact-us .col-lg-6 {
  padding-left: 0px;
  padding-right: 0px;
}

#contact-us h5 {
  font-weight: 500;
  font-size: 18px;
  color: #1e1e1e;
  letter-spacing: 0.25px;
  line-height: 26px;
}

.contact-text {
  font-weight: 400;
  font-size: 14px;
  color: #6f8ba4;
  letter-spacing: 0.6px;
  line-height: 26px;
}

.contact-text p {
  margin-bottom: 28px;
}

.contact-field {
  background-color: rgba(250, 250, 250, 0.3);
}

.contact-form {
  padding: 100px;
  background: rgba(0, 136, 232, 1);
  background: -moz-linear-gradient(
    -45deg,
    rgba(0, 136, 232, 1) 0%,
    rgba(0, 182, 198, 1) 0%,
    rgba(0, 136, 232, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right bottom,
    color-stop(0%, rgba(0, 136, 232, 1)),
    color-stop(0%, rgba(0, 182, 198, 1)),
    color-stop(100%, rgba(0, 136, 232, 1))
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(0, 136, 232, 1) 0%,
    rgba(0, 182, 198, 1) 0%,
    rgba(0, 136, 232, 1) 100%
  );
  background: -o-linear-gradient(
    -45deg,
    rgba(0, 136, 232, 1) 0%,
    rgba(0, 182, 198, 1) 0%,
    rgba(0, 136, 232, 1) 100%
  );
  background: -ms-linear-gradient(
    -45deg,
    rgba(0, 136, 232, 1) 0%,
    rgba(0, 182, 198, 1) 0%,
    rgba(0, 136, 232, 1) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(0, 136, 232, 1) 0%,
    rgba(0, 182, 198, 1) 0%,
    rgba(0, 136, 232, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0088e8', endColorstr='#0088e8', GradientType=1 );
}

@media (max-width: 765px) {
  .contact-form {
    padding: 60px;
    margin-top: -7px;
  }
}

.contact-form input,
.contact-form textarea {
  color: #777;
  font-size: 14px;
  border: none;
  width: 100%;
  height: 50px;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 30px;
}

.contact-form textarea {
  height: 150px;
  resize: none;
  padding: 20px;
}

.contact-form {
  text-align: center;
}

.contact-form ::-webkit-input-placeholder {
  /* Edge */
  color: #fff;
}

.contact-form :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

.contact-form ::placeholder {
  color: #fff;
}

/* 
---------------------------------------------
footer
--------------------------------------------- 
*/
footer {
  background-color: #fff;
  padding: 60px 0px;
}

footer .social {
  overflow: hidden;
  text-align: right;
}

footer .social li {
  margin: 0px 5px;
  display: inline-block;
}

footer .social li a {
  color: #fff;
  text-align: center;
  background-color: #00b4c6;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  display: inline-block;
  font-size: 16px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

footer .social li a:hover {
  background-color: #0088e8;
  color: #fff;
}

footer .copyright {
  margin-top: 10px;
  font-weight: 400;
  font-size: 15px;
  color: #4a4a4a;
  letter-spacing: 0.88px;
  text-transform: capitalize;
}

@media (max-width: 991px) {
  footer .copyright {
    text-align: center;
  }
  footer .social {
    text-align: center;
    margin-top: 15px;
  }
}

/* 
---------------------------------------------
preloader
--------------------------------------------- 
*/
#preloader {
  overflow: hidden;
  background-image: linear-gradient(145deg, #00b4c6 0%, #0088e8 100%);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 9999;
  color: #fff;
}

#preloader .jumper {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  position: absolute;
  margin: auto;
  width: 50px;
  height: 50px;
}

#preloader .jumper > div {
  background-color: #fff;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  opacity: 0;
  width: 50px;
  height: 50px;
  -webkit-animation: jumper 1s 0s linear infinite;
  animation: jumper 1s 0s linear infinite;
}

#preloader .jumper > div:nth-child(2) {
  -webkit-animation-delay: 0.33333s;
  animation-delay: 0.33333s;
}

#preloader .jumper > div:nth-child(3) {
  -webkit-animation-delay: 0.66666s;
  animation-delay: 0.66666s;
}

@-webkit-keyframes jumper {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  5% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes jumper {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  5% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
