:local(.formSaveToLibrary) {
  width: 100%;
  text-align: unset;
  font-size: 22px;
  .ant-avatar {
    margin-right: 5px;
  }
  .btn-choose-folder {
    font-size: 22px;
  }

  .ant-form-item {
    margin-bottom: 0;
    .ant-radio-group {
      display: grid;
      .ant-radio-wrapper {
        margin-bottom: 8px;
      }
    }
  }
}
