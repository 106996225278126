@import '../../assets//sass/variables.scss';

:local(.editItineraryForm) {
  .warning-limit {
    span {
      font-size: 13px;
      color: #dc3545;
      &.anticon-warning {
        vertical-align: text-top;
      }
    }
  }

  .custom-control-inline {
    padding-bottom: 0.5rem;
  }

  .avatar-uploader {
    @media only screen and (max-width: $screen-xs-max) {
      margin-top: 10px;
      display: flex;
      justify-content: flex-start;
    }
  }

  .group-button {
    @media only screen and (max-width: $screen-xs-max) {
      display: flex !important;
      flex-direction: column;
      .MuiFormControlLabel-root {
        margin-left: 0 !important;
      }
    }
    .private-radio {
      display: inline;
    }
  }

  .wrapper3 {
    display: inline-block;
  }

  .wrapper3 .arrow {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    //left: 0;
  }
  .wrapper3 .arrow:before,
  .wrapper3 .arrow:after {
    background-color: transparent;
    width: 2px;
    height: 3px;
    display: inline-block;
    position: absolute;
    border-bottom: 11px solid #45bbff;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  .wrapper3 .arrow:before {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  .wrapper3 .arrow:after {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  .wrapper3.active .arrow {
    vertical-align: bottom;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform: translate(0, -6px);
    transform: translate(0, -6px);
  }
  .wrapper3.active .arrow:before {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .wrapper3.active .arrow:after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .arrow {
    margin-left: 1rem;
    width: 13px;
    height: 13px;
    display: inline-block;
    // position: relative;
    // bottom: -5px;
    // left: -10px;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
    // margin-top: 2px;
    // text-align: left;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    // float: right;
  }
  .arrow:before,
  .arrow:after {
    position: absolute;
    content: '';
    display: inline-block;
    width: 12px;
    height: 3px;
    background-color: #45bbff;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
  }
  .arrow:after {
    position: absolute !important;
    // -webkit-transform: rotate(90deg);
    //         transform: rotate(90deg);
    // top: -5px;
    left: 7px !important;
  }

  // .col-muilti-day-edit {
  //   @media screen and (max-width: $screen-sm) {
  //     div.DayPickerInput {
  //       width: 46%;
  //     }
  //   }
  //   @media screen and (min-width: $screen-md) {
  //     div.DayPickerInput {
  //       width: 48%;
  //     }
  //   }
  // }

  .form-control {
    height: calc(1.5em + 1.75rem + 2px);
  }

  .form-input-budget-mobile {
    padding-right: 7px;
    @media screen and (max-width: $screen-sm) {
      padding-right: 15px !important;
      margin-bottom: 1rem !important;
    }
  }

  .form-input-pax-mobile {
    padding-left: 7px;
    @media screen and (max-width: $screen-sm) {
      padding-left: 15px !important;
    }
  }

  .destination-disabled {
    color: rgb(84, 84, 84);
    cursor: default;
    background-color: rgb(235, 235, 228);
  }

  .row-destination-added {
    .input-group-append.input-group-btn.currency {
      align-items: center;
      width: 50%;
      .ant-select-selection--single {
        background: #cccccc;
        color: black;
        width: 100%;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-radius: unset;
        border-right: unset;
        height: auto;
        .ant-select-selection__rendered {
          line-height: calc(1.5em + 1.75rem + 2px);
        }
      }
    }
    .rbt.clearfix.open {
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }

    .form-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .btn-outline-primary {
      border-left: 0;
      border-color: #ced4da;
      background: #fff;
      > svg {
        fill: red;
      }
      &:hover {
        background: red;
        > svg {
          fill: #fff;
        }
      }
    }
  }

  .btn-add-destination {
    color: black;
    background: #fff;
    border-style: dashed;
    border-color: #036cca;
    &:hover {
      color: #036cca !important;
    }
  }
  .warning {
    color: #f44336;
    font-style: oblique;
  }

  .card-header {
    background-color: unset;
    border-bottom: unset;
    color: #036cca;
    font-size: 2rem;
    text-align: center;
  }

  .card {
    //margin: 1rem 0;
    // background-color: #fff;
    // box-shadow: 1px 0 30px rgba(114, 182, 234, 0.2);
    border-radius: 8px;
    border: unset;
    padding: 1rem 0;
    .card-body {
      padding: 0;
      .optional {
        cursor: pointer;
        .label-optional {
          cursor: pointer !important;
          font-weight: bold;
        }
      }
    }
  }

  .react-datepicker-wrapper {
    display: block;
  }

  .react-datepicker__input-container {
    display: unset;
  }

  .react-datepicker__input-container > input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  div[role='progressbar'] {
    color: #ffffff !important;
    width: unset !important;
    height: unset !important;
  }

  .color-primary {
    background-color: #8bc24a;
    border-color: #8bc24a;
    &:hover {
      background-color: #4f7b1b;
    }
  }
}
.avatar-uploader {
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
  }
}
@media screen {
  .antd-img-crop-modal {
    .ant-modal-body {
      .antd-img-crop-container {
        height: 50vh !important;
      }
    }
  }
}
