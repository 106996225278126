@import '../../assets/sass/variables.scss';

:local(.contentTopDestinations) {
  .content-center {
    padding: 20px 15px;
    background-color: white;
    min-height: 100vh;
  }
  .row-itineraries {
    margin-top: 0;
    .line-mobile {
      @media only screen and (max-width: $screen-sm-max) {
        border-bottom: 1px solid #cccccc;
        margin-bottom: 40px;
      }
    }
    .flex-row-attraction {
      .ant-row {
        display: flex;
        flex-flow: row wrap;
      }
    }
  }
}
