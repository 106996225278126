.form-forget-password {
  z-index: 100;
  text-align: right;
  .label {
    h3.ant-typography,
    .ant-typography h3 {
      margin-bottom: 0px;
      text-align: center;
    }
    span {
      font-size: 16px;
      line-height: initial;
      text-align: left;
      margin: 15px 0px;
      width: 100%;
      float: left;
    }
  }
  .has-error .ant-form-explain, .has-error .ant-form-split {
    text-align: left;
  }
  .success-alert {
    margin-top: 25px;
    text-align: left;
    .email {
      text-decoration: underline;
    }
  }
}
