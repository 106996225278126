@import "../../../assets/sass/variables.scss";
.history-search {
  display: flex;
  align-items: center;
  .icon-history{
    margin-right: 5px;
  }
}
.search-bar {
  width: 35%;
  margin-left: 20px;
  @media only screen and (max-width: $screen-md-max) {
    width: 100%;
    margin-left: 10px;
  }
  @media only screen and (max-width: $screen-sm-max) {
    margin: 0;
    padding: 10px 10px 0;
  }
  .section-search {
    .section-search__input {
      display: flex;
      align-items: center;
      .ant-select-auto-complete.ant-select-lg {
        .ant-input {
          border-radius: 50px;
          background: #F0F2F5;
          &:focus{
            background: none;
          }
        }
      }
    }
  }

  .menu-and-logo .row-itineraries{
    margin-left: 20px;
  }

  .history-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon-history {
      display: flex;
      flex: 0;
      align-items: center;
      margin: 0 13px 0 1px;
    }
    .history-word {
      flex: auto;
      padding: 6px 0;
      float: left;
    }
    .history-action {
      display: flex;
      flex: 0;
      float: right;
    }
  }

  .ant-select-dropdown {
    box-shadow: 0 4px 6px rgba(32,33,36,.28);
  }
}
