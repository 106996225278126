@import "../../assets/sass/variables.scss";
.DayPicker {
  font-size: 0.75rem;
}

.InputFromTo {
  display: flex;
  .DayPickerInput {
    flex: 1 0 49%;
    &:nth-child(odd) {
      margin-right: 1%;
    }
    &:nth-child(even) {
      margin-left: 1%;
      > .DayPickerInput-OverlayWrapper {
        left: -10px;
        @media (max-width: $screen-sm) {
          left: -120px;
        }
      }
    }
  }

  .DayPicker-Day--highlighted {
    background-color: orange;
    color: white;
  }

  input {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

.InputFromTo-to {
  input.day-picker-disabled {
    color: rgb(84, 84, 84);
    cursor: default;
    background-color: rgb(235, 235, 228);
  }
}
