@import "../../assets/sass/variables.scss";
.custom-up-files {
  .DropzoneArea-dropzoneTextStyle-362 {
    > .DropzoneArea-dropzoneParagraph-364 {
      font-size: 1rem;
    }
    > .MuiSvgIcon-root.DropzoneArea-uploadIconSize-363 {
      width: 2rem;
      height: 2rem;
    }
  }

  .MuiGrid-spacing-xs-8 {
    width: 100%;
    margin: 0;
    > .MuiGrid-item {
      padding: 1rem;
    }
  }
}

.row-duration {
  display: flex;
}

@media (min-width: $screen-xs) and (max-width: $screen-sm){
  .row-duration {
    margin-top: 1rem;
    display: unset;
  }
}


.section {
  font-weight: bold;
}

.show-more {
  color: #45bbff;
  cursor: pointer;
  &:hover {
    color: #5fc4ff;
  }
}

// :local(.activityTab) {
//   .MuiFormLabel-root.Mui-error {
//     color: #f44336;
//   }

//   .MuiSnackbar-root {
//     position: unset;
//   }

//   .MuiGrid-spacing-xs-8 > .MuiGrid-item {
//     padding: 0px;
//     margin: 0 auto;
//     margin-top: 1.5rem;
//   }

//   // .MuiSelect-root {
//   //   width: 50%;
//   // }

//   // #select-day .Mui-focused {
//   //   width: 50%;
//   // }

//   // #select-day .MuiInput-underline:before {
//   //   width: 50%;
//   // }
// }
