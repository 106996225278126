@import "../../assets/sass/variables.scss";
@import url("https://fonts.googleapis.com/css?family=Special+Elite");

:local(.itemView) {
  padding-bottom: 1.5rem;
  @media only screen and (max-width: $screen-xs-max) {
    padding-bottom: 0;
  }
  > div:nth-child(1) {
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    @media only screen and (max-width: $screen-xs-max) {
      flex-direction: column;
    }
  }
  .img-fluid-thumbnail {
    border-radius: 4px;
    width: 100%;
    background-color: #3e3e3e;
    background-image: none;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin: 0.5rem 0;
    padding-bottom: 65%;
  }

  blockquote {
    font-size: 1rem;
    line-height: 1;
    position: relative;
    padding: 0.5rem;
    background: #ddf3d0;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  blockquote::before {
    font-family: "Special Elite", cursive;
    content: open-quote;
    font-size: 3rem;
    font-weight: bold;
    color: #04a67b;
    vertical-align: -0.3em;
  }

  blockquote::after {
    font-family: "Special Elite", cursive;
    content: close-quote;
    font-size: 3rem;
    font-weight: bold;
    color: #04a67b;
    vertical-align: -0.3em;
    position: absolute;
    bottom: -14px;
    right: 6px;
  }

  .quote {
    font-size: 1.1rem;
    line-height: 1.5;
    margin-top: -40px;
    margin-left: 30px;
    margin-right: 20px;
    font-style: italic;
  }

  .title-action {
    display: flex;
    .time {
      display: block;
      font-size: 17px !important;
      color: #9a9a9a;
      margin-right: 10px;
      > svg {
        height: 1.3rem;
        width: 1.3rem;
        fill: #9a9a9a !important;
        margin-right: 5px;
      }
    }

    .status {
      color: #fca81b;
    }
  }

  > div {
    > div:first-of-type {
      margin-left: 0px !important;
      background: unset !important;
      border: unset !important;
      width: 100% !important;
      height: auto !important;
      // left: -19px !important;
      border-radius: 0 !important;
      position: static !important;
      flex: 0 40%;
      /*@media only screen and (max-width: $screen-xs-max) {
        flex-direction: column;
      }*/
      > span {
        width: 100% !important;
        height: auto !important;
        align-self: start !important;
        // height: 70px !important;
        > img {
          width: 70px !important;
          height: 70px !important;
          object-fit: cover;
        }
        > span {
          top:-10px !important;
          font-size: 22px;
          width: 40px !important;
          height: 40px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          right: -10px !important;
        }
      }
    }
  }
  .each-event {
    margin-left: 2rem;
    flex: 1;
    @media only screen and (max-width: $screen-xs-max) {
      width: calc(100% - 2rem);
    }
    .content-event {
      display: flex;
      flex-flow: row wrap;
      > .content {
        br {
          display: none;
        }

        span {
          margin-right: 4px;
        }

        color: #4d636e;
        font-size: 1rem;
        //font-weight: 600;
        margin-right: 10px;
        > .value {
          font-weight: bold;
          color: black;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    > div + div {
      > div:first-of-type {
        color: #4d636e;
        font-size: 1rem;
        //font-weight: 600;
      }

      > div + div {
        color: #052530;
        font-size: 1.2rem;
        //font-weight: 700;
      }
    }

    > div + div + div {
      background-color: unset !important;
      box-shadow: unset !important;
      padding: 0 !important;
      color: #052530;
      font-size: 1rem;
      font-weight: 400;
      display: grid;
      width: 100% !important;
    }
    .description-event {
      figure {
        >iframe {
          width: 100%;
        }
      }
    }
  }

  .sidebar-idea-container {
    margin: 1rem;
    background-color: transparent;
    width: 100%;
  }
  // .idea-tile-horizontal {
  //   cursor: move;
  // }
  // .idea-tile-horizontal._can-view-details {
  //   cursor: move;
  // }
  .idea-tile-horizontal {
    border-radius: 0.25rem;
    border: 1px solid #ecf0f2;
    background-color: #fff;
    box-shadow: 0 0.25rem 2rem -0.5rem rgba(28, 75, 102, 0.3);
    height: 5.4rem;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    box-orient: horizontal;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    flex-direction: row;
    -ms-flex-direction: row;
    -webkit-transform: scale(1.001);
    -moz-transform: scale(1.001);
    -ms-transform: scale(1.001);
    -o-transform: scale(1.001);
    transform: scale(1.001);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition-property: -webkit-transform, box-shadow;
    -moz-transition-property: -moz-transform, box-shadow;
    transition-property: transform, box-shadow;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
  }
  .idea-tile-horizontal > figure {
    position: relative;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 0 5rem;
    -moz-flex: 0 0 5rem;
    -ms-flex: 0 0 5rem;
    flex: 0 0 5rem;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    min-height: 4rem;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    background-image: url("../../assets/media/images/generic-activities-img.jpg");
  }

  .idea-tile-horizontal > figure {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
  }

  figure {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  .idea-tile-horizontal > figure .btn {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
    opacity: 0;
  }

  .idea-tile-horizontal._can-view-details:hover figure {
    position: relative;
  }

  .idea-tile-horizontal._can-view-details:hover figure .btn {
    opacity: 1;
  }

  .idea-tile-horizontal > figure > * {
    position: relative;
    z-index: 10;
  }
  ._btn-small {
    height: 2.1rem;
    padding-left: 0.35rem;
    padding-right: 0.35rem;
  }
  ._btn-outline-white {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
    border: 1px solid #d7dfe3;
  }

  ._btn-outline-white:focus,
  ._btn-outline-white:hover {
    color: #fff;
    border: 1px solid #fff;
  }
  ._btn-outline-white:focus,
  ._btn-outline-white:hover {
    color: #fff;
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 0.125);
  }

  ._btn-outline-white,
  ._btn-outline-white:hover {
    border: 1px solid springgreen !important;
  }

  .btn {
    cursor: pointer;
    position: relative;
    text-transform: initial;
    border: 1px solid transparent;
    overflow: hidden;
    text-align: center;
    border-radius: 0.25rem;
    font-family: proxima-nova, sans-serif;
    font-weight: 400;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    font-size: 1rem;
    line-height: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    height: 2.9rem;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    -webkit-transition-property: -webkit-transform, background-color, border,
      color, box-shadow;
    -moz-transition-property: -moz-transform, background-color, border, color,
      box-shadow;
    transition-property: transform, background-color, border, color, box-shadow;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
    font-weight: 400;
  }

  .idea-tile-horizontal._can-view-details:hover figure:after {
    position: absolute;
    content: "";
    background-color: rgba(5, 37, 48, 0.6);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .idea-tile-horizontal > figure:after {
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    -webkit-transition-property: background-color;
    -moz-transition-property: background-color;
    transition-property: background-color;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
    position: absolute;
    content: "";
    background-color: rgba(5, 37, 48, 0);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  *,
  ::after,
  ::before {
    box-sizing: inherit;
  }

  .idea-tile-horizontal ._c-info {
    padding: 0.25rem 0;
    overflow: hidden;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-flex-direction: column;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    > .MuiSkeleton-root {
      padding: 0.25rem 0.5rem !important;
      margin-left: 0.5rem;
    }
  }
  .idea-tile-horizontal ._c-info li:first-child {
    color: #052530;
    font-weight: 700;
  }
  .idea-tile-horizontal ._c-info li {
    padding: 0.25rem 0.5rem;
    color: #7d97a4;
    font-size: 0.9rem;
  }
  .idea-tile-horizontal ._c-info li span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .idea-tile-horizontal ._c-info li span p {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .click-to-add {
    padding: 2rem 0.5rem;
    cursor: pointer;
  }

  .click-to-remove {
    padding: 2rem 0.2rem;
    cursor: pointer;
  }

  @media only screen and (min-width: 75em) {
    .click-to-add > svg {
      fill: #7c8e9c;
      width: 1.5rem;
      height: 1.5rem;
      &.isChecked {
        fill: #4db776;
      }
    }

    .click-to-remove > svg {
      fill: #de014f;
      width: 1.5rem;
      height: 1.5rem;
      &.isChecked {
        fill: #4db776;
      }
    }
  }

  .click-to-add {
    &:hover {
      > svg {
        fill: #45bbff;
        -webkit-transition: 0.35s;
        -moz-transition: 0.35s;
        transition: 0.35s;
        cursor: pointer;
      }
    }
  }

  //////////////////////////////////
  .MuiButtonBase-root.MuiIconButton-root.MuiExpansionPanelSummary-expandIcon.MuiIconButton-edgeEnd {
    display: none;
  }

  .row.content-view {
    position: relative;
  }

  .col-3 > svg {
    fill: black;
    width: 1.2rem;
    height: 1.2rem;
  }

  .thumbnail-places {
    // padding: 0;
    // flex: 0 0 15%;
    // max-width: 15%;
    > img {
      width: 70px;
      height: 70px;
      flex-shrink: 0;
      -webkit-box-flex: 0;
      flex-grow: 0;
      border-radius: 50%;
      border: 1px solid #fff;
      object-fit: cover;
    }
  }

  .time-start {
    font-weight: bold;
    // padding: 0;
    font-size: 0.9rem;
  }

  .btn-secondary {
    color: #0099ff !important;
    background-color: transparent;
    border: none;
  }

  .btn-primary {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
    background-color: #0099ff;
    color: white;
    box-shadow: none;
    &:hover {
      background-color: #1389d8;
      color: white;
    }
  }

  .bullet {
    display: "inline-block";
    margin: "0 2px";
    transform: "scale(0.8)";
  }

  .dropdown-width {
    width: 350px;
  }

  .vertical-divider {
    border-right: 1px solid #e7e7e7;
  }

  .category-icon {
    position: absolute;
    right: -0.5rem;
    top: -13px;
    background-color: #fea700;
    border: 1px solid #fff;
    z-index: 20;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
  }

  .category-icon > svg {
    width: 1rem;
    height: 1rem;
    fill: #fff;
  }

  .card {
    width: 100%;
    margin: 0 auto;
    position: unset;
    margin-left: 0.5rem !important;
  }

  .MuiExpansionPanel-root.Mui-expanded {
    margin: 0 auto;
  }

  .selectedTranspot {
    color: #0099ff;
  }

  .dropdown-item {
    padding: 0.25rem 0.5rem;
    &:active {
      background-color: transparent;
      color: #0099ff;
    }
  }

  .row.setting-transport {
    display: flex;
    justify-content: flex-end;
    margin-top: 3.5px;
    cursor: default;
  }

  .icon-transportation-pdf {
    display: -webkit-inline-box;
    position: absolute;
    top: 0.4rem;
    border-radius: 50%;
    width: 30px;
    height: 20px;
    background: #fff;
    border: 1px solid rgb(255, 255, 255);
    display: flex;
    left: 6.2rem;
    > span {
      display: flex;
      width: 32px;
      height: 32px;
      position: relative;
      justify-content: center;
      cursor: pointer;
      align-self: center;
      align-items: center;
      > svg {
        fill: #0099ff;
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .icon-transportation {
    display: -webkit-inline-box;
    position: absolute;
    background: #fff;
    border: 1px solid rgb(255, 255, 255);
    display: flex;
    left: 35px;
    > span {
      display: flex;
      width: 32px;
      height: 32px;
      position: relative;
      justify-content: center;
      align-self: center;
      align-items: center;
      > svg {
        fill: #9a9a9a;
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .left-distant > p {
    position: absolute;
    left: 70px;
    top: 0.4rem;
    font-size: 0.8rem;
    font-weight: 400;
    color: #9a9a9a;
  }

  .description-transportation {
    left: 13rem !important;
  }

  .right-setting-column {
    padding-left: 0.1rem;
  }

  .dropdown {
    left: 1rem;
  }

  .setting-icon > svg {
    fill: #45bbff;
  }

  .MuiIconButton-label > svg {
    fill: currentColor;
    width: 1em;
    height: 1em;
  }

  .MuiExpansionPanel-root:before {
    opacity: 0;
  }

  .MuiExpansionPanelDetails-root {
    padding: 0px 24px 24px !important;
  }

  .MuiExpansionPanelSummary-content {
    margin: 0;
    display: unset;
  }

  .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 0;
    display: unset;
  }

  .report-section {
    font-size: 13px;
    text-align: end;
    > span {
      cursor: pointer;
    }
  }

  .place-title {
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 1.1rem;
    cursor: pointer;
    color: #333333;
    font-weight: 400;
    &:hover {
      color: #1a73e8;
    }
    svg {
      fill: #1a73e8;
      vertical-align: text-top;
    }
  }

  .place-address {
    margin-bottom: 0;
    //font-size: 0.9rem;
    // color: #4d636e;
    // font-size: 1rem;
    // font-weight: 600;
    color: #9a9c9d;
    font-size: 14px;
    font-weight: 400;
    > p {
      margin-bottom: 0 !important;
    }
  }
}

:local(.itemInforView) {
  //padding-bottom: 1.5rem;
  //background-color: #fff;
  // background-color: #f7f7f7;

  > div:first-child {
    padding: 20px !important;
    background-color: #f7f7f7;
  }

  blockquote {
    font-size: 1rem;
    line-height: 1;
    position: relative;
    padding: 0.5rem;
    background: #ddf3d0;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  blockquote::before {
    font-family: "Special Elite", cursive;
    content: open-quote;
    font-size: 3rem;
    font-weight: bold;
    color: #04a67b;
    vertical-align: -0.3em;
  }

  blockquote::after {
    font-family: "Special Elite", cursive;
    content: close-quote;
    font-size: 3rem;
    font-weight: bold;
    color: #04a67b;
    vertical-align: -0.3em;
    position: absolute;
    bottom: -14px;
    right: 6px;
  }

  .quote {
    font-size: 1.1rem;
    line-height: 1.5;
    margin-top: -40px;
    margin-left: 30px;
    margin-right: 20px;
    font-style: italic;
  }

  .time {
    display: block;
    font-size: 17px !important;
    color: #9a9a9a;
    margin-right: 10px;
    > svg {
      height: 1.3rem;
      width: 1.3rem;
      fill: #9a9a9a !important;
      margin-right: 5px;
    }
  }

  > div {
    > div:first-of-type {
      margin-left: 0px !important;
      background: unset !important;
      border: unset !important;
      width: 30% !important;
      height: auto !important;
      // left: -19px !important;
      border-radius: 0 !important;
      > span {
        width: 100% !important;
        // height: 70px !important;
        > img {
          width: 70px !important;
          height: 70px !important;
          object-fit: cover;
        }
      }
    }
  }
  .each-event {
    margin-left: 3rem;
    .content-event {
      display: flex;
      flex-flow: row wrap;
      > .content {
        br {
          display: none;
        }

        span {
          margin-right: 4px;
        }

        color: #4d636e;
        font-size: 1rem;
        //font-weight: 600;
        margin-right: 10px;
        > .value {
          font-weight: bold;
          color: black;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    > div + div {
      > div:first-of-type {
        color: #4d636e;
        font-size: 1rem;
        //font-weight: 600;
      }

      > div + div {
        color: #052530;
        font-size: 1.2rem;
        //font-weight: 700;
      }
    }

    > div + div + div {
      background-color: unset !important;
      box-shadow: unset !important;
      padding: 0 !important;
      color: #052530;
      font-size: 1rem;
      font-weight: 400;
      display: grid;
      width: 100% !important;
    }
  }

  .sidebar-idea-container {
    margin: 1rem;
    background-color: transparent;
    width: 100%;
  }
  // .idea-tile-horizontal {
  //   cursor: move;
  // }
  // .idea-tile-horizontal._can-view-details {
  //   cursor: move;
  // }
  .idea-tile-horizontal {
    border-radius: 0.25rem;
    border: 1px solid #ecf0f2;
    background-color: #fff;
    box-shadow: 0 0.25rem 2rem -0.5rem rgba(28, 75, 102, 0.3);
    height: 5.4rem;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    box-orient: horizontal;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    flex-direction: row;
    -ms-flex-direction: row;
    -webkit-transform: scale(1.001);
    -moz-transform: scale(1.001);
    -ms-transform: scale(1.001);
    -o-transform: scale(1.001);
    transform: scale(1.001);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition-property: -webkit-transform, box-shadow;
    -moz-transition-property: -moz-transform, box-shadow;
    transition-property: transform, box-shadow;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
  }
  .idea-tile-horizontal > figure {
    position: relative;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 0 5rem;
    -moz-flex: 0 0 5rem;
    -ms-flex: 0 0 5rem;
    flex: 0 0 5rem;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    min-height: 4rem;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    background-image: url("../../assets/media/images/generic-activities-img.jpg");
  }

  .idea-tile-horizontal > figure {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
  }

  figure {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  .idea-tile-horizontal > figure .btn {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
    opacity: 0;
  }

  .idea-tile-horizontal._can-view-details:hover figure {
    position: relative;
  }

  .idea-tile-horizontal._can-view-details:hover figure .btn {
    opacity: 1;
  }

  .idea-tile-horizontal > figure > * {
    position: relative;
    z-index: 10;
  }
  ._btn-small {
    height: 2.1rem;
    padding-left: 0.35rem;
    padding-right: 0.35rem;
  }
  ._btn-outline-white {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
    border: 1px solid #d7dfe3;
  }

  ._btn-outline-white:focus,
  ._btn-outline-white:hover {
    color: #fff;
    border: 1px solid #fff;
  }
  ._btn-outline-white:focus,
  ._btn-outline-white:hover {
    color: #fff;
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 0.125);
  }

  ._btn-outline-white,
  ._btn-outline-white:hover {
    border: 1px solid springgreen !important;
  }

  .btn {
    cursor: pointer;
    position: relative;
    text-transform: initial;
    border: 1px solid transparent;
    overflow: hidden;
    text-align: center;
    border-radius: 0.25rem;
    font-family: proxima-nova, sans-serif;
    font-weight: 400;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    font-size: 1rem;
    line-height: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    height: 2.9rem;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    -webkit-transition-property: -webkit-transform, background-color, border,
      color, box-shadow;
    -moz-transition-property: -moz-transform, background-color, border, color,
      box-shadow;
    transition-property: transform, background-color, border, color, box-shadow;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
    font-weight: 400;
  }

  .idea-tile-horizontal._can-view-details:hover figure:after {
    position: absolute;
    content: "";
    background-color: rgba(5, 37, 48, 0.6);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .idea-tile-horizontal > figure:after {
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    -webkit-transition-property: background-color;
    -moz-transition-property: background-color;
    transition-property: background-color;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
    position: absolute;
    content: "";
    background-color: rgba(5, 37, 48, 0);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  *,
  ::after,
  ::before {
    box-sizing: inherit;
  }

  .idea-tile-horizontal ._c-info {
    padding: 0.25rem 0;
    overflow: hidden;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-flex-direction: column;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    > .MuiSkeleton-root {
      padding: 0.25rem 0.5rem !important;
      margin-left: 0.5rem;
    }
  }
  .idea-tile-horizontal ._c-info li:first-child {
    color: #052530;
    font-weight: 700;
  }
  .idea-tile-horizontal ._c-info li {
    padding: 0.25rem 0.5rem;
    color: #7d97a4;
    font-size: 0.9rem;
  }
  .idea-tile-horizontal ._c-info li span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .idea-tile-horizontal ._c-info li span p {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .click-to-add {
    padding: 2rem 0.5rem;
    cursor: pointer;
  }

  .click-to-remove {
    padding: 2rem 0.2rem;
    cursor: pointer;
  }

  @media only screen and (min-width: 75em) {
    .click-to-add > svg {
      fill: #7c8e9c;
      width: 1.5rem;
      height: 1.5rem;
      &.isChecked {
        fill: #4db776;
      }
    }

    .click-to-remove > svg {
      fill: #de014f;
      width: 1.5rem;
      height: 1.5rem;
      &.isChecked {
        fill: #4db776;
      }
    }
  }

  .click-to-add {
    &:hover {
      > svg {
        fill: #45bbff;
        -webkit-transition: 0.35s;
        -moz-transition: 0.35s;
        transition: 0.35s;
        cursor: pointer;
      }
    }
  }

  //////////////////////////////////
  .MuiButtonBase-root.MuiIconButton-root.MuiExpansionPanelSummary-expandIcon.MuiIconButton-edgeEnd {
    display: none;
  }

  .row.content-view {
    position: relative;
  }

  svg {
    fill: #fff;
  }

  .col-3 > svg {
    fill: black;
    width: 1.2rem;
    height: 1.2rem;
  }

  .thumbnail-places {
    // padding: 0;
    // flex: 0 0 15%;
    // max-width: 15%;
    > img {
      width: 70px;
      height: 70px;
      flex-shrink: 0;
      -webkit-box-flex: 0;
      flex-grow: 0;
      border-radius: 50%;
      border: 1px solid #fff;
      object-fit: cover;
    }
  }

  .time-start {
    font-weight: bold;
    // padding: 0;
    font-size: 0.9rem;
  }

  .btn-secondary {
    color: #0099ff !important;
    background-color: transparent;
    border: none;
  }

  .btn-primary {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
    background-color: #0099ff;
    color: white;
    box-shadow: none;
    &:hover {
      background-color: #1389d8;
      color: white;
    }
  }

  .bullet {
    display: "inline-block";
    margin: "0 2px";
    transform: "scale(0.8)";
  }

  .dropdown-width {
    width: 350px;
  }

  .vertical-divider {
    border-right: 1px solid #e7e7e7;
  }

  .category-icon {
    position: absolute;
    right: -0.5rem;
    top: -13px;
    background-color: #fea700;
    border: 1px solid #fff;
    z-index: 20;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
  }

  .category-icon > svg {
    width: 1rem;
    height: 1rem;
    fill: #fff;
  }

  .card {
    width: 100%;
    margin: 0 auto;
    position: unset;
    margin-left: 0.5rem !important;
  }

  .MuiExpansionPanel-root.Mui-expanded {
    margin: 0 auto;
  }

  .selectedTranspot {
    color: #0099ff;
  }

  .dropdown-item {
    padding: 0.25rem 0.5rem;
    &:active {
      background-color: transparent;
      color: #0099ff;
    }
  }

  .row.setting-transport {
    display: flex;
    justify-content: flex-end;
    margin-top: 3.5px;
    cursor: default;
  }

  .icon-transportation-pdf {
    display: -webkit-inline-box;
    position: absolute;
    top: 0.4rem;
    border-radius: 50%;
    width: 30px;
    height: 20px;
    background: #fff;
    border: 1px solid rgb(255, 255, 255);
    display: flex;
    left: 6.2rem;
    > span {
      display: flex;
      width: 32px;
      height: 32px;
      position: relative;
      justify-content: center;
      cursor: pointer;
      align-self: center;
      align-items: center;
      > svg {
        fill: #0099ff;
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .icon-transportation {
    display: -webkit-inline-box;
    position: absolute;
    background: #fff;
    border: 1px solid rgb(255, 255, 255);
    display: flex;
    left: 35px;
    > span {
      display: flex;
      width: 32px;
      height: 32px;
      position: relative;
      justify-content: center;
      align-self: center;
      align-items: center;
      > svg {
        fill: #9a9a9a;
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .left-distant > p {
    position: absolute;
    left: 70px;
    top: 0.4rem;
    font-size: 0.8rem;
    font-weight: 400;
    color: #9a9a9a;
  }

  .description-transportation {
    left: 13rem !important;
  }

  .right-setting-column {
    padding-left: 0.1rem;
  }

  .dropdown {
    left: 1rem;
  }

  .setting-icon > svg {
    fill: #45bbff;
  }

  .MuiIconButton-label > svg {
    fill: currentColor;
    width: 1em;
    height: 1em;
  }

  .MuiExpansionPanel-root:before {
    opacity: 0;
  }

  .MuiExpansionPanelDetails-root {
    padding: 0px 24px 24px !important;
  }

  .MuiExpansionPanelSummary-content {
    margin: 0;
    display: unset;
  }

  .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 0;
    display: unset;
  }

  .place-title {
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: bold;
  }

  .place-address {
    margin-bottom: 0;
    color: #9a9c9d;
    font-size: 14px;
    > p {
      margin-bottom: 0 !important;
    }
  }
}
