:local(.appHeader) {
  .MuiToolbar-regular {
    @media (min-width: 600px) {
      min-height: 64px !important;
    }
  }

  .MuiAppBar-colorPrimary {
    background-color: #ffff;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
  }

  header a {
    color: #ffffff;
    text-decoration: none;
    &:hover {
      color: #bdbdbd;
    }

    .main-logo {
      height: 90px;
      width: 100%;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
