@import "../../assets/sass/variables.scss";

.btn-cus-bot{
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

:local(.contentDetail) {
  .warning-limit {
    span {
      font-size: 13px;
      color: #dc3545;
      &.anticon-warning {
        vertical-align: text-top;
      }
    }
  }
  .container {
    box-shadow: none;
  }
  padding: 0 10px 50px;
  &#center-column {
    padding-top: 10px;
    @media only screen and (max-width: $screen-md-max) {
      display: block;
    }
  }
  .time-line-mobile-view{
    margin-top: 20px;
    .ant-page-header{
      padding: 0;
      .ant-page-header-heading{
        display: flex;
        align-items: flex-start;
        .ant-page-header-heading-sub-title{
          flex: 1 1 auto;
        }
        .ant-page-header-heading-title {
          flex: 0 0 15%;
          @media only screen and (max-width: $screen-md-max) {
            flex: 0 0 40%;
          }
          @media only screen and (max-width: $screen-xs-max) {
            flex: 0 0 25%;
          }
          > img{
            width: 100%;
          }
        }
        .ant-page-header-heading-extra{
          width: auto;
          overflow: unset;
          float: right;
          display: flex;
          padding-top: 0;
          .icon-quote{
            width: 20px;
          }
        }
      }
      .ant-page-header-content{
        padding-top: 5px;
        .small-detail{
          .content-event-detail{
            font-size: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .content{
              border-right: 1px solid #c6c8ca;
              flex: 1 1 auto;
              text-align: center;
              &:first-child {
                text-align: left;
              }
              &:last-child{
                border-right: 0;
                text-align: right;
              }
              .value {
                font-weight: bold;
              }
            }
          }
        }
        .timing{
          font-size: 13px;
        }
        .detail-content{
          margin-top: 10px;
          .ant-collapse-header{
            padding-top: 5px;
            padding-bottom: 5px;
          }
          .ant-carousel{
            .slick-list {
              .slick-slide {
                .imgSlider{
                  .image{
                    width: 100%;
                    height: 100px;
                    object-fit: cover;
                    object-position: center;
                  }
                }
              }
            }
            .slick-dots {
              li {
                button{
                  &:before{
                    content: "";
                  }
                }
              }
            }
          }
        }
        .setting-transport {
          blockquote {
            font-size: 1rem;
            line-height: 1;
            position: relative;
            padding: 0.5rem;
            background: #ddf3d0;
            border-top-left-radius: 20px;
            border-bottom-right-radius: 20px;
          }

          blockquote::before {
            font-family: "Special Elite", cursive;
            content: open-quote;
            font-size: 2rem;
            font-weight: bold;
            color: #04a67b;
            vertical-align: -0.3em;
          }

          blockquote::after {
            font-family: "Special Elite", cursive;
            content: close-quote;
            font-size: 2rem;
            font-weight: bold;
            color: #04a67b;
            vertical-align: -0.3em;
            position: absolute;
            bottom: -14px;
            right: 6px;
          }

          .quote {
            font-size: 0.9rem;
            line-height: 1.5;
            margin-top: -40px;
            margin-left: 30px;
            margin-right: 20px;
            font-style: italic;
          }
        }
        .last-content-page{
          margin-top: 10px;
          .ant-row-flex{
            flex-wrap: nowrap;
            .ant-btn{
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
    .places-hover{
      padding: 0;
      margin-bottom: 20px;
      .ant-timeline-item-tail{
        display: block;
        border-left-style: dotted;
      }
      .ant-timeline-item-content{
        background-color: unset;
        padding: 5px;
        border: none;
      }
      .ant-timeline-item-head {
        display: none;
      }
    }
  }
  .trip-day-header-labels{
    flex-direction: column;
    align-items: stretch;
    padding-top: 10px;
    padding-bottom: 10px;
    .trip-day-header-title {
      font-size: 16px;
    }
    .description-day {
      @media only screen and (max-width: $screen-xs-max) {
        font-size: 0.8rem;
      }
    }
  }
  .btn-expand-collapse {
    font-size: 12px;
  }
}

.draw-content{
  .btn-add {
    display: none;
  }
  &.ant-drawer-open {
    .btn-add {
      display: block;
    }
  }
  .folder-menu{
    color: #e9870a;
  }
  .ant-drawer-wrapper-body {
    background: rgb(225, 238, 245);
    .ant-drawer-body {
      padding: 0;
      .ant-page-header {
        padding: 0;
        .ant-page-header-heading {
          padding: 5px 24px;
          display: flex;
          align-items: center;
          background: white;
          .ant-page-header-back {
            font-size: 18px;
          }
          .ant-page-header-heading-title{
            font-size: 14px;
            line-height: normal;
            flex: 1;
            text-align: left;
          }
          .ant-page-header-heading-extra {
            margin: 7px 0;
          }
        }
        .ant-page-header-content{
          padding-bottom: 16px;
          padding-left: 24px;
          padding-right: 24px;
          .btn-add {
            color: white;
            background: #e79e3d;
            position: fixed;
            bottom: 0;
            right: 0;
            transform: translate(-20%, -50%);
            z-index: 2;
            &:hover {
              opacity: 1;
              background: #e79e3d;
            }
          }
        }
      }
      #form-search {
        .click-to-add {
          padding: 0 1rem;
          display: flex;
          align-items: center;
          svg {
            font-size: 36px;
            @media only screen and (max-width: $screen-xs-max) {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}
.on-mobile {
  .MuiDialog-container{
    height: auto;
    @media only screen and (max-width: $screen-md-max) {
      height: auto;
    }
  }
  .MuiDialog-paperWidthSm{
    max-width: 100%;
    margin: 0;
    width: 80%;
    @media only screen and (max-width: $screen-xs-max) {
      width: 100%;
    }
  }
}
.edit-on-mobile {
  .MuiDialog-container {
    .MuiDialog-paperFullWidth{
      max-width: none;
    }
  }
}
.secondary-action {
  .save-itinerary {
    flex: 1 1 70%;
  }
  .btn-save-to-folder{
    flex: 0 1 30%;
  }
}
