@charset "UTF-8";
:local(.contentAttraction) .content-left {
  background: #e1eef5;
  min-height: calc(100vh - 94px - 53px);
  display: flex;
  flex-direction: column;
  padding: 0; }
  @media only screen and (max-width: 991px) {
    :local(.contentAttraction) .content-left {
      min-height: auto; } }

:local(.contentAttraction) .content-right {
  padding: 0;
  background: #e1eef5;
  min-height: calc(100vh - 94px - 53px); }
  @media only screen and (max-width: 991px) {
    :local(.contentAttraction) .content-right {
      display: none; } }
  :local(.contentAttraction) .content-right .top-right-container {
    margin: 15px; }

:local(.contentAttraction) .container {
  max-width: 100%;
  background: #fff;
  margin: 0 auto;
  box-shadow: 1px 5px 30px rgba(114, 182, 234, 0.2); }

:local(.contentAttraction) .section-reviews {
  float: left;
  width: 100%; }
  :local(.contentAttraction) .section-reviews > p {
    overflow: hidden; }
    :local(.contentAttraction) .section-reviews > p:after {
      background-color: #e1eef5;
      content: "";
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 100%;
      left: 0.5em;
      margin-right: -50%; }
  :local(.contentAttraction) .section-reviews .ant-list-item-meta-title a {
    font-weight: bold;
    font-size: 18px;
    text-transform: capitalize; }
  :local(.contentAttraction) .section-reviews .ant-list-split .ant-list-item {
    border-bottom: none; }

:local(.contentAttraction) .each-row-itineraries .ant-list-pagination {
  margin-bottom: 24px; }

:local(.contentAttraction) .each-row-itineraries .content-summary {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden; }

:local(.contentAttraction) #left-column {
  padding: 15px; }
  :local(.contentAttraction) #left-column .add-to-plan {
    color: white;
    box-shadow: none;
    background-color: #e9870a;
    border-color: #e9870a;
    margin-bottom: 1rem;
    font-size: 1.5rem; }
    :local(.contentAttraction) #left-column .add-to-plan:hover {
      background-color: #ca770d;
      box-shadow: none; }
    :local(.contentAttraction) #left-column .add-to-plan span {
      color: #fff !important; }
      :local(.contentAttraction) #left-column .add-to-plan span > svg {
        width: 1rem;
        height: 1rem; }
  :local(.contentAttraction) #left-column .info-summary {
    font-size: 0.9rem;
    display: -webkit-inline-box; }
    :local(.contentAttraction) #left-column .info-summary:last-of-type {
      margin-bottom: 1rem; }
    :local(.contentAttraction) #left-column .info-summary > svg {
      margin-right: 0.5rem;
      fill: #01d28f; }
  :local(.contentAttraction) #left-column #map {
    height: 300px;
    /* The height is 400 pixels */
    width: 100%;
    /* The width is the width of the web page */
    margin-bottom: 1rem; }
  :local(.contentAttraction) #left-column p.location {
    margin: 15px 0 10px 0;
    font-weight: bold;
    font-size: 20px; }
  :local(.contentAttraction) #left-column .section-listed {
    float: right; }
    :local(.contentAttraction) #left-column .section-listed .listed {
      font-weight: bold;
      font-size: 20px;
      text-align: right; }
    :local(.contentAttraction) #left-column .section-listed .listed-item:last-of-type {
      margin-bottom: 1rem; }
    :local(.contentAttraction) #left-column .section-listed .listed-item .each-listed-item {
      display: flex; }
      :local(.contentAttraction) #left-column .section-listed .listed-item .each-listed-item .each-listed-item-content {
        text-align: right;
        padding: 0 15px; }
        :local(.contentAttraction) #left-column .section-listed .listed-item .each-listed-item .each-listed-item-content .rating {
          color: rgba(0, 0, 0, 0.65);
          font-size: 14px; }
        :local(.contentAttraction) #left-column .section-listed .listed-item .each-listed-item .each-listed-item-content .recommend {
          color: rgba(0, 0, 0, 0.65);
          font-size: 14px; }
      :local(.contentAttraction) #left-column .section-listed .listed-item .each-listed-item .img-fluid {
        padding: 30px;
        border-radius: 50%; }
      :local(.contentAttraction) #left-column .section-listed .listed-item .each-listed-item > svg {
        vertical-align: text-top; }
  :local(.contentAttraction) #left-column blockquote {
    margin: 1rem 0;
    font-size: 1rem;
    line-height: 1;
    position: relative;
    padding: 0.5rem;
    background: #ddf3d0;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px; }
  :local(.contentAttraction) #left-column blockquote::before {
    font-family: "Special Elite", cursive;
    content: open-quote;
    font-size: 3rem;
    font-weight: bold;
    color: #04a67b;
    vertical-align: -0.3em; }
  :local(.contentAttraction) #left-column blockquote::after {
    font-family: "Special Elite", cursive;
    content: close-quote;
    font-size: 3rem;
    font-weight: bold;
    color: #04a67b;
    vertical-align: -0.3em;
    position: absolute;
    bottom: -14px;
    right: 6px; }
  :local(.contentAttraction) #left-column .quote {
    font-size: 1.1rem;
    line-height: 1.5;
    margin-top: -40px;
    margin-left: 30px;
    margin-right: 20px;
    font-style: italic; }
  :local(.contentAttraction) #left-column .cardList .ant-card-meta-title {
    overflow: unset;
    white-space: unset;
    text-overflow: unset; }
    :local(.contentAttraction) #left-column .cardList .ant-card-meta-title .option-pref {
      font-size: 15px;
      color: #e33c59; }
    :local(.contentAttraction) #left-column .cardList .ant-card-meta-title .option-age {
      font-size: 15px;
      color: #31a2ec; }
    :local(.contentAttraction) #left-column .cardList .ant-card-meta-title .option-type {
      font-size: 15px;
      color: #f3aa30; }
  :local(.contentAttraction) #left-column .cardList .ant-skeleton-header {
    padding: 0; }
  :local(.contentAttraction) #left-column .cardList .cardBlock {
    width: auto !important;
    margin: 0.5rem; }
    :local(.contentAttraction) #left-column .cardList .cardBlock .total-reviews {
      font-size: 12px; }
    :local(.contentAttraction) #left-column .cardList .cardBlock .content {
      margin-top: 5px;
      min-height: 80px; }
    :local(.contentAttraction) #left-column .cardList .cardBlock:hover .cartImage .hover-info {
      display: flex;
      opacity: 1; }
    :local(.contentAttraction) #left-column .cardList .cardBlock:hover .cartImage::after {
      position: absolute;
      content: "";
      background-color: rgba(5, 37, 48, 0.6);
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1; }
  :local(.contentAttraction) #left-column .cardList .cartTitle {
    color: #ff4d55;
    font-size: 20px; }
  :local(.contentAttraction) #left-column .cardList .cartDes {
    font-size: 14px; }
    :local(.contentAttraction) #left-column .cardList .cartDes p {
      max-height: 40px;
      height: 40px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical; }
  :local(.contentAttraction) #left-column .cardList .cartImage {
    position: relative;
    height: 150px;
    background: rgba(0, 0, 0, 0.075) no-repeat center;
    background-size: cover; }
    :local(.contentAttraction) #left-column .cardList .cartImage .hover-info {
      -webkit-transition-property: opacity;
      transition-property: opacity;
      -webkit-transition-duration: 0.25s;
      transition-duration: 0.25s;
      opacity: 0;
      z-index: 10;
      bottom: 10px;
      cursor: pointer;
      position: absolute;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transform: scale(1);
      transform: scale(1);
      font-size: 1rem;
      line-height: 1;
      display: -moz-flex;
      display: flex;
      flex-flow: row wrap;
      box-align: center;
      -moz-align-items: center;
      -ms-align-items: center;
      -o-align-items: center;
      align-items: center;
      -ms-flex-align: center;
      box-pack: center;
      -ms-flex-pack: center;
      height: 2.9rem;
      padding-left: 0.7rem;
      padding-right: 0.7rem;
      -webkit-transition-property: -webkit-transform, background-color, border, color, box-shadow;
      -webkit-transition-property: background-color, border, color, box-shadow, -webkit-transform;
      transition-property: background-color, border, color, box-shadow, -webkit-transform;
      transition-property: transform, background-color, border, color, box-shadow;
      transition-property: transform, background-color, border, color, box-shadow, -webkit-transform;
      -webkit-transition-duration: 0.25s;
      transition-duration: 0.25s; }
    :local(.contentAttraction) #left-column .cardList .cartImage::after {
      border-bottom-left-radius: 0.25rem;
      border-top-left-radius: 0.25rem;
      -webkit-transition-property: background-color;
      -moz-transition-property: background-color;
      transition-property: background-color;
      -webkit-transition-duration: 0.25s;
      -moz-transition-duration: 0.25s;
      transition-duration: 0.25s;
      position: absolute;
      content: "";
      background-color: rgba(5, 37, 48, 0);
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1; }
    :local(.contentAttraction) #left-column .cardList .cartImage:before {
      content: "";
      display: block;
      padding-top: 75%; }
    :local(.contentAttraction) #left-column .cardList .cartImage .card-tag-left {
      position: absolute;
      left: 10px;
      top: 10px;
      margin: 0; }
    :local(.contentAttraction) #left-column .cardList .cartImage .card-tag-right {
      position: absolute;
      right: 10px;
      top: 10px;
      margin: 0; }
  :local(.contentAttraction) #left-column .cardList .cartPrice {
    position: absolute;
    bottom: 15px;
    left: 0;
    padding: 5px 7px;
    font-size: 15px;
    background-color: rgba(80, 80, 80, 0.72);
    color: white; }
  :local(.contentAttraction) #left-column .cardList .slick-slider .slick-dots li.slick-active button {
    background: #e9870a; }
  :local(.contentAttraction) #left-column .cardList .slick-slider .slick-dots li button {
    background: #cccccc;
    height: 5px; }
    :local(.contentAttraction) #left-column .cardList .slick-slider .slick-dots li button:hover {
      background: #e9870a; }
    :local(.contentAttraction) #left-column .cardList .slick-slider .slick-dots li button:before {
      content: ""; }
  :local(.contentAttraction) #left-column .cardList .slick-slider .slick-prev {
    left: 15px; }
    :local(.contentAttraction) #left-column .cardList .slick-slider .slick-prev:before {
      content: ""; }
  :local(.contentAttraction) #left-column .cardList .slick-slider .slick-next {
    right: 15px; }
    :local(.contentAttraction) #left-column .cardList .slick-slider .slick-next:before {
      content: ""; }
  :local(.contentAttraction) #left-column .cardList .slick-slider .slick-prev,
  :local(.contentAttraction) #left-column .cardList .slick-slider .slick-next {
    font-size: 0;
    line-height: 0;
    background: #e9870a;
    color: transparent;
    padding: 0;
    height: 36px;
    width: 36px;
    border-radius: 36px;
    top: calc((50%) / 2);
    box-shadow: 0 0 7px -4px #000;
    border: none;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    text-align: center; }
    :local(.contentAttraction) #left-column .cardList .slick-slider .slick-prev > i,
    :local(.contentAttraction) #left-column .cardList .slick-slider .slick-next > i {
      font-size: 16px;
      line-height: 40px;
      transition: all 0.3s;
      color: #fff;
      line-height: 35px; }
    :local(.contentAttraction) #left-column .cardList .slick-slider .slick-prev:hover,
    :local(.contentAttraction) #left-column .cardList .slick-slider .slick-next:hover {
      box-shadow: 0 0 5px 0 rgba(246, 93, 55, 0.5); }
      :local(.contentAttraction) #left-column .cardList .slick-slider .slick-prev:hover > i,
      :local(.contentAttraction) #left-column .cardList .slick-slider .slick-next:hover > i {
        color: #ffffff; }
  :local(.contentAttraction) #left-column .cardList .slick-slider .slick-disabled {
    color: transparent;
    background-color: rgba(191, 127, 46, 0.68); }
  :local(.contentAttraction) #left-column .cardList .ant-card-actions {
    display: none; }
  :local(.contentAttraction) #left-column .img-fluid {
    width: 100%;
    height: 300px;
    background-color: #3e3e3e;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin: 0.5rem 0; }
    :local(.contentAttraction) #left-column .img-fluid.small {
      padding: 30px 0;
      width: unset;
      height: unset; }
  :local(.contentAttraction) #left-column .point-rating {
    padding-right: 0.5rem; }
  :local(.contentAttraction) #left-column .title-attraction {
    color: #1087e9;
    margin-bottom: 0;
    font-weight: initial; }
  :local(.contentAttraction) #left-column .recommend-by {
    color: #9a9a9a;
    margin-bottom: 1rem; }
  :local(.contentAttraction) #left-column .trix-content-temp {
    line-height: 1.5; }
    :local(.contentAttraction) #left-column .trix-content-temp * {
      box-sizing: border-box; }
    :local(.contentAttraction) #left-column .trix-content-temp h1 {
      font-size: 1.2em;
      line-height: 1.2;
      margin: 0; }
    :local(.contentAttraction) #left-column .trix-content-temp pre {
      display: inline-block;
      width: 100%;
      vertical-align: top;
      font-family: monospace;
      font-size: 0.9em;
      margin: 0;
      padding: 0.5em;
      white-space: pre;
      background-color: #eee;
      overflow-x: auto; }
    :local(.contentAttraction) #left-column .trix-content-temp ul,
    :local(.contentAttraction) #left-column .trix-content-temp ol,
    :local(.contentAttraction) #left-column .trix-content-temp li {
      margin: 0;
      padding: 0; }
      :local(.contentAttraction) #left-column .trix-content-temp ul li,
      :local(.contentAttraction) #left-column .trix-content-temp ol li,
      :local(.contentAttraction) #left-column .trix-content-temp li li {
        margin-left: 1em; }
    :local(.contentAttraction) #left-column .trix-content-temp img {
      max-width: 100%;
      height: auto; }
    :local(.contentAttraction) #left-column .trix-content-temp .attachment {
      display: inline-block;
      position: relative;
      max-width: 100%;
      margin: 0;
      padding: 0; }
      :local(.contentAttraction) #left-column .trix-content-temp .attachment a {
        color: inherit;
        text-decoration: none; }
        :local(.contentAttraction) #left-column .trix-content-temp .attachment a:hover, :local(.contentAttraction) #left-column .trix-content-temp .attachment a:visited:hover {
          color: inherit; }
    :local(.contentAttraction) #left-column .trix-content-temp .attachment__caption {
      padding: 0;
      text-align: center; }
      :local(.contentAttraction) #left-column .trix-content-temp .attachment__caption .attachment__name + .attachment__size::before {
        content: " · "; }
    :local(.contentAttraction) #left-column .trix-content-temp .attachment--preview {
      width: 100%;
      text-align: center; }
      :local(.contentAttraction) #left-column .trix-content-temp .attachment--preview .attachment__caption {
        color: #666;
        font-size: 0.9em;
        line-height: 1.2; }
    :local(.contentAttraction) #left-column .trix-content-temp .attachment--file {
      color: #333;
      line-height: 1;
      margin: 0 2px 2px 0;
      padding: 0.4em 1em;
      border: 1px solid #bbb;
      border-radius: 5px; }
    :local(.contentAttraction) #left-column .trix-content-temp .attachment-gallery {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      margin: 0;
      padding: 0; }
      :local(.contentAttraction) #left-column .trix-content-temp .attachment-gallery .attachment {
        flex: 1 0 33%;
        padding: 0 0.5em;
        max-width: 33%; }
      :local(.contentAttraction) #left-column .trix-content-temp .attachment-gallery.attachment-gallery--2 .attachment, :local(.contentAttraction) #left-column .trix-content-temp .attachment-gallery.attachment-gallery--4 .attachment {
        flex-basis: 50%;
        max-width: 50%; }

:local(.contentAttraction) #right-column .section-history .ant-list-item {
  padding: 1rem 1rem 0.5rem 1rem;
  border: none; }
  :local(.contentAttraction) #right-column .section-history .ant-list-item:not(:first-of-type) {
    padding: 0 1rem 0.5rem 1rem; }
  :local(.contentAttraction) #right-column .section-history .ant-list-item .title-attraction {
    margin-bottom: 0;
    font-weight: initial;
    font-size: 1rem; }
  :local(.contentAttraction) #right-column .section-history .ant-list-item .author {
    font-style: italic;
    color: #9a9a9a;
    font-size: 0.8rem; }

:local(.contentAttraction) #right-column .img-fluid {
  padding: 65px 0;
  display: block;
  position: relative;
  background-color: #3e3e3e;
  background-image: none;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }
  :local(.contentAttraction) #right-column .img-fluid.small {
    padding: 30px 0; }

:local(.contentAttraction) #right-column .content-attraction {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden; }

:local(.contentAttraction) #right-column .related-section {
  border-bottom: 1px solid #9a9a9a;
  margin: 1rem 0;
  padding: 5px 0; }

:local(.contentAttraction) #right-column .each-attreaction-related {
  margin-bottom: 1rem; }
  :local(.contentAttraction) #right-column .each-attreaction-related .title-attraction-related {
    color: #1087e9; }
  :local(.contentAttraction) #right-column .each-attreaction-related .header-attraction-related {
    justify-content: space-between;
    display: flex; }
    :local(.contentAttraction) #right-column .each-attreaction-related .header-attraction-related .posted-by {
      display: flex;
      color: #9a9a9a; }
      :local(.contentAttraction) #right-column .each-attreaction-related .header-attraction-related .posted-by .small-thumb {
        width: 24px;
        height: 24px;
        margin-right: 0.5rem; }
    :local(.contentAttraction) #right-column .each-attreaction-related .header-attraction-related .posted-time {
      color: #9a9a9a; }
  :local(.contentAttraction) #right-column .each-attreaction-related .content-attraction-related {
    display: flex;
    margin-top: 0.5rem; }
    :local(.contentAttraction) #right-column .each-attreaction-related .content-attraction-related .img-thumb {
      margin-right: 1rem;
      width: 100%;
      height: 100px;
      background-color: #3e3e3e;
      background-image: none;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover; }
    :local(.contentAttraction) #right-column .each-attreaction-related .content-attraction-related .content-summary {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden; }

:local(.breadcrumb) {
  padding: 0.5rem;
  background-color: #0598cc;
  color: #fff; }
  :local(.breadcrumb) .MuiPaper-root {
    background: unset; }
  :local(.breadcrumb) ol.MuiBreadcrumbs-ol {
    display: -webkit-box;
    white-space: nowrap; }
    :local(.breadcrumb) ol.MuiBreadcrumbs-ol li {
      font-size: 12px; }
    :local(.breadcrumb) ol.MuiBreadcrumbs-ol li.MuiBreadcrumbs-li:last-of-type {
      width: 70%; }
    :local(.breadcrumb) ol.MuiBreadcrumbs-ol p.MuiTypography-body1 {
      overflow: hidden;
      font-size: 12px;
      text-overflow: ellipsis;
      white-space: nowrap; }

:local(.filterForm) {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  padding: 0 15px; }
  @media only screen and (max-width: 575px) {
    :local(.filterForm) {
      display: block; } }
  :local(.filterForm) .ant-form-item {
    width: auto;
    margin: 0; }
  :local(.filterForm) .category {
    min-width: 200px; }
    @media only screen and (max-width: 575px) {
      :local(.filterForm) .category {
        min-width: 100px; } }
