@import "../../../../assets/sass/variables.scss";
.ant-scrolling-effect {
  width: auto !important;
}
.profile-form,
.company-form,
.password-form {
  margin-bottom: -25px;
  .image-upload {
    position: relative;
  }
  .edit-icon {
    top: -15px;
    right: 5px;
    color: white;
    position: absolute;
  }
  .ant-input-number {
    width: 100%;
    .ant-input-number-input-wrap {
      width: 100%;
    }
  }
  .ant-form-item {
    text-align: left;
    margin-bottom: 10px !important;
    label {
      margin-bottom: 0px;
    }
    .ant-form-item-label {
      line-height: 20px;
    }
  }
  .bottom-submit {
    text-align: right;
  }
  .ant-input-group-addon {
    cursor: not-allowed;
  }
}

.container-profile {
  margin-top: -20px;
  height: auto;
  overflow-y: hidden;
  /* left content */
  .left-content {
    padding: 25px 10px 0px 10px;
    @media screen and (max-width: $screen-sm) {
      border-right: 0px;
    }
    .children {
      background-color: white;
      float: left;
      padding: 10px;
      width: 100%;
      border-radius: 5px;
      .basic-info {
        margin: 0 auto;
        max-width: 200px;
        @media screen and(max-width: $screen-md) {
          max-width: 180px;
        }
        .content-children {
          width: 100%;
          float: left;
          margin-bottom: 10px;
          .name {
            font-weight: bold;
            margin-bottom: 10px;
            font-size: 17px;
            text-align: center;
          }
          .avatar-container {
            width: 180px;
            position: relative;
            margin-bottom: 10px;
            text-align: center;
            img {
              width: 180px;
              @media screen and(max-width: $screen-md) {
                max-width: 160px;
              }
            }
            .anticon-edit {
              position: absolute;
              right: 10px;
              top: 10px;
              color: white;
              background-color: #bcb3aa;
              padding: 3px;
            }
          }
          .account-type {
            text-align: center;
            margin-top: 10px;
            width: 180px;
            float: left;
            margin-bottom: 10px;
            .account-badge {
              padding: 5px 10px;
              max-width: fit-content;
              color: white;
              border-radius: 5px;
              margin: 0 auto;
              cursor: pointer;
            }
          }
          .member-since {
            width: 100%;
            float: left;
            margin-bottom: 10px;
          }
          .change-password {
            width: 180px;
            float: left;
            text-align: center;
          }
        }
      }
    }
  }
  .user-info {
    width: 100%;
    float: left;
    table.information {
      font-size: 15px;
      width: 100%;
      float: left;
      .verify {
        color: red;
        .verify-now {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .label {
        display: block;
        min-width: 135px;
        @media screen and (max-width: 1024px) {
          min-width: 80px;
        }
        @media screen and (max-width: $screen-md) {
          display: none;
        }
      }
      tr {
        td {
          padding-bottom: 10px;
        }
      }
    }
    .about-us {
      font-size: 15px;
      margin-top: 10px;
      label {
        margin-bottom: 10px;
        p {
          margin-top: 0px;
        }
      }
    }
  }
  .edit-container {
    background-color: #f5f3f0;
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    float: left;
    z-index: 1;
    h4 {
      vertical-align: middle;
      font-size: 17px;
      width: fit-content;
      display: inline-table;
      line-height: 32px;
      font-weight: bold;
    }
    button {
      position: absolute;
      right: 10px;
      background-color: white;
      color: rgb(15, 149, 222);
    }
  }

  .social {
    display: block;
  }
  .social-account-container {
    display: grid;
    .social-account {
      margin-bottom: 10px;
      i {
        font-size: 19px;
        margin-right: 10px;
      }
    }
  }

  /* right content */
  .right-content {
    @media screen and (max-width: $screen-sm) {
      height: auto;
      padding: 25px 0px 0px 10px;
    }
    padding: 25px 20px 0px 20px;
    .children {
      max-width: 1024px;
      margin: 0 auto;
      .content {
        float: left;
        width: 100%;
        background-color: white;
        padding: 10px;
        border-radius: 5px;
        min-height: 520px;
      }
      @media screen and (max-width: $screen-sm) {
        padding-left: 0px !important;
      }
      .banner {
        margin-bottom: 15px;
        position: relative;
        height: 250px;
        text-align: center;
        width: 100%;
        .ant-upload.ant-upload-select {
          width: 100%;
        }
        img {
          width: 100%;
          max-width: 1024px;
          height: 250px;
          object-fit: cover;
          object-position: center;
        }
        .anticon-edit {
          position: absolute;
          right: 10px;
          top: 10px;
          color: white;
          background-color: #bcb3aa;
          padding: 3px;
        }
      }
    }

  .company-info {
    width: 100%;
    float: left;
    h4 {
      font-size: 17px;
      font-weight: bold;
      width: fit-content;
      display: inline-table;
      vertical-align: middle;
      line-height: 32px;
    }
    .company-logo {
      max-width: 200px;
      position: absolute;
      top: 20px;
      right: 0 !important;
      left: auto;
      img {
        max-width: 200px;
        height: 150px;
        object-fit: cover;
        object-position: center;
      }
      .anticon-edit {
        position: absolute;
        right: 10px;
        top: 10px;
        color: white;
        background-color: #bcb3aa;
        padding: 3px;
      }
      @media screen and (max-width: $screen-md) {
        position: absolute;
        bottom: 0px;
        float: right;
        img {
          width: 100px;
          height: auto;
        }
      }
      @media screen and (max-width: $screen-sm) {
        position: relative;
      }
    }
  }

    table {
      position: relative;
      font-size: 15px;
      tr {
        td {
          width: max-content;
          padding-bottom: 10px;
          padding-left: 10px;
          @media screen and (max-width: $screen-md) {
            padding-left: 0px;
          }
        }
        .label {
          min-width: 125px;
          @media screen and (max-width: $screen-md) {
            display: none;
          }
        }
      }
      th {
        display: inline-flex;
      }
      th.icon {
        display: inline-grid;
      }
      .url {
        color: #1890ff;
      }
      p {
        white-space: pre-wrap;
      }
    }
  }
}
