:local(.listMyTyneries) {
  span.btn-login {
    cursor: pointer;
    color: #2697ff;
    font-weight: 800;
    &:hover {
      color: #0065c1;
      text-decoration-line: underline;
    }
  }

  .library-name {
    font-weight: bold;
    display: contents;
    > .anticon-edit {
      vertical-align: baseline;
      color: #0f95de;
    }
  }

}

:local(.formSaveToLibrary) {
  width: 100%;
  text-align: unset;
  font-size: 22px;
  .ant-avatar {
    margin-right: 5px;
  }
  .btn-choose-folder {
    font-size: 22px;
  }

  .ant-form-item {
    margin-bottom: 0;
    .ant-radio-group {
      display: grid;
      .ant-radio-wrapper {
        margin-bottom: 8px;
      }
    }
  }
}

:local(.loader) {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/media/images/page-loader.gif");
  background-repeat: no-repeat;
  background-position: center;
}
