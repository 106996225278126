.table-customize {
  tr:first-child > th:first-child {
    border-top-left-radius: 4px;
  }

  tr > th {
    padding: 5px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
    text-align: left;
    background: #fafafa;
    border-bottom: 1px solid #e8e8e8;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
  }

  tr {
    &:hover {
      background-color: #e6f7ff;
    }
    td[rowspan] {
      background-color: #fff;
      font-weight: bold;
      border: 1px solid #e8e8e8;
    }
    td {
      &.status-past {
        color: #ea6153;
        font-weight: bold;
        border-left: 5px solid #ea6153;
      }
      &.status-upcoming {
        color: #27ae60;
        font-weight: bold;
        border-left: 5px solid #27ae60;
      }
      &.status-progress {
        color: #2980b9;
        font-weight: bold;
        border-left: 5px solid #2980b9;
      }
    }
    font-size: 14px;
    -webkit-transition: all 0.3s, height 0s;
    transition: all 0.3s, height 0s;
  }

  thead > tr {
    &:hover {
      background-color: unset;
    }
  }

  .td-action {
    text-align: right;
  }
  th {
    font-weight: bold;
  }
  tr > th,
  tr > td {
    padding: 5px;
    overflow-wrap: break-word;
  }

  tr > td {
    border-bottom: 1px solid #e8e8e8;
    -webkit-transition: all 0.3s, border 0s;
    transition: all 0.3s, border 0s;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
  }
}
