@import "../../../assets/sass/variables.scss";

.title-tyneri {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  &:before, &:after {
    background-color: #ccc;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
    @media only screen and (max-width: $screen-sm-max) {
      content: unset;
    }
  }
  &:before{
    right: 0.5em;
    margin-left: -50%;
  }
  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
  small {
    position: absolute;
    right: 0;
    top: 20px;
    font-size: 20%;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    -webkit-text-fill-color: initial;
  }
}

.bottom-line {
  position: absolute;
  bottom: -5px;
}
