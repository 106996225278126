.list-tyneries {
  padding: 10px;
  .my-list-tyneri {
    .ant-row {
      display: flex;
      flex-flow: row wrap;
    }
  }
  .ant-list-items {
    li.item {
      margin-bottom: 15px;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.17);
      .ant-card-bordered { border: none; }
      .card-image {
        background-size: cover !important;
        background-position: center !important;
        min-height: 200px;
        position: relative;
        .tag-country {
          right: 0px;
          top: 10px;
          position: absolute;
          font-size: 14px;
        }
        .layer-background {
          box-shadow: none;
          position: absolute;
          bottom: 10px;
          width: auto;
          height: auto;
          right: 10px;
          .request-inquiries {
            position: relative;
            top: auto;
            right: auto;
            span { font-size: 14px; }
          }
        }
      }
      .description {
        display: inline-block;
        span {
          margin-left: 5px;
          float: left;
        }
        .pref {
          color: #e33c59;
        }
        .age {
          color: #31a2ec;
        }
        .plan {
          color: #f3aa30;
        }
      }
      .ant-card-meta-title {
        white-space: initial;
        h5 {
          font-size: 17px;
          color: rgb(15, 149, 222);
        }
      }
      .ant-card-body {
        padding: 10px;
      }
    }
  }
}
