@import "../../assets/sass/variables.scss";
.add-new-tyneri {
  @media screen and (max-width: $screen-sm) {
    padding: 0 15px;
    margin-bottom: 15px;
  }
  > button {
    font-size: 20px;
    @media screen and (max-width: $screen-md-max) {
      font-size: 14px;
      padding: 6px 8px;
    }
  }
}

.btn-tyneri-list {
  cursor: pointer;
}

.create-tyneri {
  --color: white;
  display: flex;
  align-items: center;
  color: var(--color);
  box-shadow: none;
  border: 1px solid var(--color);
  background: #ff5722 !important;
  padding: 0 15px;
  font-size: 18px;
  position: relative;
  overflow: hidden;
  border-radius: 50px;
  height: 40px;
  //height: 38px;
  @media only screen and (max-width: $screen-md-max) {
    font-size: 14px;
    padding: 0 10px;
  }
  @media only screen and (max-width: $screen-xs-max) {
    font-size: 20px;
  }
  b {
    color: inherit;
  }
  i {
    margin-right: 5px;
  }
}

:local(.contentTrendy) {
  .row-select-create{
    .create-hover {
      background: transparent;
      color: #F89901;
      border-color: #F89901;
      z-index: 1;
      &:hover {
        color: white;
      }
      &::before, &::after {
        background: #F89901;
      }
    }
  }
}
