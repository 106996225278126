@import "../../../../../../../assets/sass/variables.scss";

.stop-input {
  border-bottom: 0px;
  width: 100%;
  float: left;
  position: relative;
  z-index: 100000;
  .responsiveTable thead th {
    padding-left: 10px;
  }
  &__body {
    .day-activities {
      font-size: 16px;
      color: black;
      margin-bottom: 15px;
    }
  }
  &__body-row:first-child {
    border: 0px;
  }
  &__body-row {
    border-top: 20px solid #cdcdcd40;
    td {
      vertical-align: top;
      padding: 10px!important;
    }
    .text-editor  {
      width:50%
    }
  }
  .ant-row.content {
    animation: new-item-animation 0.3s linear forwards;
    margin-bottom: 20px;
    background-color: white;
    padding: 20px;
  }
  &__content {
    background-color: white;
    padding-bottom: 10px;
    margin-top: 10px !important;
    &__confirm-container {
      display: flex;
      line-height: 35px;
      justify-content: space-between;
    }
    &__confirm {
      text-align: right;
      @media screen and (max-width: $screen-lg) {
        text-align: left;
      }

      &__reject-text {
        color: red;
        font-weight: bold;
      }
    }
    &__dynamic-delete-button {
      vertical-align: 2px;
      line-height: 32px;
      font-size: 20px;
      color: red;
    }
  }

  &__add-stop {
    padding: 15px 0px;
    width: 100%;
    float: left;
    &__add-stop-span {
      float: right;
      cursor: pointer;
      color: #40a9ff;
      background-color: white;
      padding: 10px;
      cursor: pointer;
      .anticon.anticon-plus {
        vertical-align: 1px;
        margin-right: 4px;
      }
    }
  }
}
