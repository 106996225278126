:local(.topListsRelative) {
  padding: 15px;
  .header-top-lists-relative {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid #eeeeef;
    padding-bottom: 10px;
  }

  .each-card-top-list-relative {
    min-height: 5rem;
    margin: 0px 0px 16px;
    background: #fff;
    cursor: pointer;
    box-shadow: rgba(149, 157, 165, 0.2) 24px 25px 38px 0px;
    display: flex;
    border-radius: 20px;
    overflow: hidden;

    .img-card-top-list-relative {
      min-height: 4rem;
      flex: 0 0 5rem;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50%;
      background-image: url("https://maps.googleapis.com/maps/api/place/js/PhotoService.GetPhoto?1sATtYBwIegNZ3lm6sZ6-DR298ER9o4ofehsZZulaq5JXMi5JHPAHdRBzUMgM1wfXr3xvPKruV5h7a_DoUb9ZHmBlDUFxveIlDQaqNzUz4fGCOAdE734-g8Pucyb2bx3Kjl-AEHIiIl-oRwSR9NQr-sPb2na1R69HABTcJ9mQAxhC71wSz6CBQ&3u1137&5m1&2e1&callback=none&key=AIzaSyCX4OupxqxYmOi2MVZa_KraX2uQUXr2NEU&token=81641");
    }

    .content-card-top-list-relative {
      padding: 10px;
      flex: 1 1 auto;
      > a {
        &:hover {
          color: #40a9ff;
          > p {
            color: #40a9ff;
          }
        }
      }
      .content-card-top-list-relative__title {
        font-size: 16px;
        font-weight: bold;
      }
      .content-card-top-list-relative__author {
        font-size: 12px;
      }
    }
  }
}
