._1FcUHf_SAUayszvkCXyr5h {
    min-height: auto!important;
}
.reset-password {
  background: linear-gradient(40deg, #2e5bff, #2effda);
  height: 94vh;
  .reset-password-form {
    background-color: white;
    max-width: 400px;
    padding: 20px;
    border-radius: 10px;
    margin: 10% auto;
    .label {
      margin-bottom: 25px;
      span {
        font-size: 16px;
      }
    }
    .ant-form-item-label {
      margin-bottom: 0px;
      line-height: 0px;
    }
  }
}

.loading-container {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}
