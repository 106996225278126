.billing-container {
  padding: 10px;
  background-color: white;
  width: 100%;
  .ant-divider-horizontal {
    margin: 12px 0;
  }
  .ant-radio-wrapper {
    padding: 20px;
    color: white;
    border-radius: 20px;
    .title {
      font-size: 20px;
    }
    i {
      margin-left: 22px;
    }
  }
  .box-package {
    text-align: center;
    h4 {
      font-weight: normal;
    }
    .price-show {
      margin-top: 1.1em;
      font-size: 28px;
      line-height: 20px;
      display: inline-block;
      small {
        font-weight: normal;
        display: inline-block;
      }
    }
    .btn-stylist {
      &:not([disabled]) {
        a {
          color: white;
        }
      }
    }
  }
  .py-container {
    margin: 10px 0;
    .top-title {
      margin-top: 20px;
    }
  }
}
.form-card {
  .step-action {
    padding: 20px;
    border: 1px dashed #e9e9e9;
    background: #fafafa;
    .card-holder {
      display: flex;
      align-items: center;
      flex-direction: column;
      .list-action {
        margin: 0 20px;
        display: flex;
      }
      &.cview {
        align-items: flex-start;
        flex-direction: column;
        .list-action {
          margin-left: 0;
          margin-right: 0;
          margin-top: 20px;
        }
      }
      .holder-name {
        box-sizing: border-box;
        border: 1px solid transparent;
        border-radius: 4px;
        background-color: white;
        box-shadow: 0 1px 3px 3px #e6ebf1;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
      }
    }
    .ant-form-item {
      margin-bottom: 12px;
    }
    .StripeElement--webkit-autofill {
      background: transparent !important;
    }

    .StripeElement {
      width: 100%;
      flex: 1;
      box-sizing: border-box;
      height: 40px;
      padding: 10px 12px;
      border: 1px solid transparent;
      border-radius: 4px;
      background-color: white;
      box-shadow: 0 1px 3px 3px #e6ebf1;
      -webkit-transition: box-shadow 150ms ease;
      transition: box-shadow 150ms ease;
    }
  }
}
