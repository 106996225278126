@import "../../assets/sass/variables.scss";
:local(.boxDraft) {
  box-sizing: border-box;
  display: block;
  outline: 0;
  max-height: 80vh;
  overflow: auto;
  left: auto;
  right: 100px;
  bottom: 50px;
  min-width: 512px;
  max-width: calc(100vw - 256px);
  margin: auto;
  background: #fff;
  -webkit-box-shadow: 0px 9px 18px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 9px 18px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 9px 18px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 999;



  .bottom-draft {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    .bottom-draft__content {
      flex: 1;
      color: #333;
      padding-right: 20px;
    }
    button {
      border-radius: 20px;
      font-size: 1.25rem;
    }
  }

  @media only screen and (max-width: $screen-sm-max) {
    max-width: 100%;
    bottom: 0%;
    left: 0;
    right: 0;
    min-width: 100%;
    position: fixed;
    .bottom-draft {
      display: contents;
    }
  }
}
