:local(.itineraryDetail) {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/media/images/page-loader.gif");
  background-repeat: no-repeat;
  background-position: center;
}

:local(.wrapper) {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  #button {
    display: inline-block;
    background-color: #989897;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 4px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
  }
  #button::after {
    content: "\f077";
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    font-size: 1em;
    line-height: 50px;
    color: #fff;
  }
  #button:hover {
    cursor: pointer;
    background-color: #333;
  }
  #button:active {
    background-color: #555;
  }
  #button.show {
    opacity: 1;
    visibility: visible;
  }
}

:local(.newContainer) {
  padding-bottom: 20px;
  .tag-and-share {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tag-category {
      background: #EBEBEB;
      text-transform: uppercase;
      font-weight: bold;
      padding: 2px 5px;
      width: fit-content;
      border-radius: 6px;
      font-size: 14px;
    }
  }
  .head-title{
    .author{
      font-size: 14px;
      font-weight: normal;
      display: block;width: 100%;
    }
  }
  .content{
    .slide-images {
      .slick-prev, .slick-next {
        position: absolute;
        top: 50%;
        display: block;
        font-size: 24px;
        margin-top: -10px;
        background: transparent;
        border-radius: 50%;
        border: 1px solid #ffffff;
        padding: 10px;
        color: white;
        height: auto;
        width: auto;
        cursor: pointer;
        z-index: 1;
        &:before, &:after {
          content: '';
          opacity: 1;
          background: #F89901;
        }
        &.slick-disabled{
          opacity: 0.4;
          cursor: not-allowed;
        }
        &:hover {
          border: 1px solid #F89901;
        }
      }
      .slick-prev {
        left: 0;
      }
      .slick-next{
        right: 0;
      }
    }
    .location-info{
      margin-bottom: 30px;
      .info-summary{
        display: flex;
        align-items: center;
        >svg {
          margin-right: .5rem;
          fill: #ff5722;
        }
      }
    }
  }
  .list-like{
    .ant-spin-nested-loading {
      .ant-spin-container {
        > .ant-row{
          display: flex;
          flex-flow: row wrap;
        }
      }
    }
  }
}
