@import "../../assets/sass/variables.scss";

:local(.CardTopNew) {
  cursor: pointer;
  .sidebar-idea-container {
    margin: 1rem;
    background-color: transparent;
  }
  .idea-tile-horizontal {
    border-radius: 10px;
    border: none;
    box-shadow: none;
    background-color: #fff;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    box-orient: horizontal;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    flex-direction: row;
    -ms-flex-direction: row;
    -webkit-transform: scale(1.001);
    -moz-transform: scale(1.001);
    -ms-transform: scale(1.001);
    -o-transform: scale(1.001);
    transform: scale(1.001);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition-property: -webkit-transform, box-shadow;
    -moz-transition-property: -moz-transform, box-shadow;
    transition-property: transform, box-shadow;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
    @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
      flex-direction: column;
    }
  }
  .idea-tile-horizontal > figure {
    position: relative;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 0 6rem;
    -moz-flex: 0 0 6rem;
    -ms-flex: 0 0 6rem;
    flex: 0 0 6rem;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    min-height: 4rem;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    background-image: url("../../assets/media/images/generic-activities-img.jpg");
  }

  .idea-tile-horizontal > figure {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
  }

  figure {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  .idea-tile-horizontal > figure .btn {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
    opacity: 0;
  }
  .idea-tile-horizontal._can-view-details:hover {
    box-shadow: 0 0.25rem 2rem -0.5rem rgba(28, 75, 102, 0.3);
  }
  .idea-tile-horizontal._can-view-details:hover figure {
    position: relative;
  }

  .idea-tile-horizontal._can-view-details:hover figure .btn {
    opacity: 1;
  }

  ._btn-small {
    height: 2.1rem;
    padding-left: 0.35rem;
    padding-right: 0.35rem;
  }
  ._btn-outline-white {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
    border: 1px solid #d7dfe3;
  }

  ._btn-outline-white:focus,
  ._btn-outline-white:hover {
    color: #fff;
    border: 1px solid #fff;
  }
  ._btn-outline-white:focus,
  ._btn-outline-white:hover {
    color: #fff;
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 0.125);
  }

  ._btn-outline-white,
  ._btn-outline-white:hover {
    border: 1px solid springgreen !important;
  }

  .btn {
    cursor: pointer;
    position: relative;
    text-transform: initial;
    border: 1px solid transparent;
    overflow: hidden;
    text-align: center;
    border-radius: 0.25rem;
    font-family: proxima-nova, sans-serif;
    font-weight: 400;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    font-size: 1rem;
    line-height: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    height: 2.9rem;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    -webkit-transition-property: -webkit-transform, background-color, border,
      color, box-shadow;
    -moz-transition-property: -moz-transform, background-color, border, color,
      box-shadow;
    transition-property: transform, background-color, border, color, box-shadow;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
    font-weight: 400;
  }

  .idea-tile-horizontal._can-view-details:hover figure:after {
    position: absolute;
    content: "";
    background-color: rgba(5, 37, 48, 0.6);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .idea-tile-horizontal ._c-info {
    padding: 0.25rem 0;
    overflow: hidden;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-flex-direction: column;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;

    .location {
      > span {
        color: #9a9a9a;
        font-size: 0.8rem;
        font-style: italic;
        font-weight: normal;
      }
    }

    .title {
      > span {
        color: #052530;
        font-weight: 0;
      }
    }

    .created {
      > div {
        display: flex;
        color: #9a9a9a;
        font-size: 0.8rem;
        .created__name {
          color: $main-author-name;
        }
      }
    }

    .action {
      font-size: 12px;
      padding: 0 8px;
      display: flex;
      // .preview {
      //   color: $action-preview;
      // }
      .edit {
        color: $action-edit;
        padding-right: 15px;
      }
      .delete {
        color: $action-delete;
      }
    }
  }

  .idea-tile-horizontal ._c-info li {
    padding: 0.25rem 0.5rem;
    color: #7d97a4;
    font-size: 0.9rem;
  }

  .idea-tile-horizontal ._c-info li span {
    display: contents;
  }

  .zoom-place {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: 0.7s ease all;
    -webkit-transition: 0.7s ease all;
    -moz-transition: 0.7s ease all;
    margin: 1rem 0;
    overflow: visible;
  }

  .zoom-place:hover {
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
}
