@import "../../assets/sass/variables.scss";

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.slider-custom {
  .slick-list {
    @media only screen and (max-width: $screen-xs-max) {
      padding: 0 0 0 10%;
    }
  }
  .slick-next, .slick-prev{
    color: white;
    transition: all 0.2s linear;
    background: transparent;
  }
  .slick-prev:hover{
    outline: none;
    background-image: linear-gradient(-270deg, rgba(240,242,245,1),rgba(240,242,245,.3));
  }
  .slick-next:hover {
    background-image: linear-gradient(270deg,rgba(240,242,245,1),rgba(240,242,245,.3));
  }
  .slick-prev:hover,.slick-next:hover, {
    width: 80px;
  }
  .slick-prev {
    width: 30px;
    height: 100%;
    z-index: 200;
    left: -20px;
  }
  .slick-next {
    width: 30px;
    height: 100%;
    z-index: 200;
    right: -20px;
  }
  .slick-prev::before,
  .slick-next::before {
    content: "";
    border-color: black;
    padding: 6px;
  }
  .slick-prev::before {
    @extend.arrow;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  .slick-next::before {
    @extend.arrow;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}
