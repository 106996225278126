@import '../../../../assets/sass/variables.scss';

.quotation-container {
  padding: 10px;
  background-color: white;
  width: 100%;
  float: left;
  display: grid;
  &__new-quotation-btn {
    margin: 10px 0px;
    text-align: right;
    .link {
      background-color: #ffaf11;
      color: white;
      padding: 10px;
      border-radius: 5px;
      width: 150px;
      text-align: center;
    }
  }
  .action {
    .btn-edit {
      margin-right: 10px;
    }
  }

  &__tab {
    .ant-tabs-bar {
      border-bottom: 0px;
    }
    .ant-tabs-tab {
      margin: 0px;
      padding: 0px;
    }
    .tab-label-customize {
      border: none;
      box-shadow: none;
      -webkit-box-shadow: none;
      margin: 5px 10px;
      border-radius: 5px;
      outline: none;
      height: 40px;
      line-height: 40px;
      padding: 0 25px;
    }
    .ant-tabs-ink-bar {
      display: none !important;
    }
    .ant-tabs-tab-active {
      .tab-label-customize {
        border: none !important;
        color: white !important;
        box-shadow: 5px 5px 5px #999 !important;
        font-weight: bold !important;
      }
    }
  }
  .data_title {
    display: grid;
  }
  .email_sent {
    display: inline-block;
    color: $action-delete;
  }
}
