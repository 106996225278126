.html-content {
  margin-top: 10px;
}
.editor {
  width: 100%;
  display: inline-table;
  margin-top: 10px;
  .rdw-editor-wrapper {
    background: white;
    border: 1px solid #cdcdcd;
    .rdw-editor-main {
      min-height: 250px;
      max-height: 250px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .rdw-link-modal {
      overflow: auto;
      .rdw-link-modal-label {
        margin: 0px;
      }
      .rdw-link-modal-btn {
        height: auto;
      }
    }
  }
}
