.overview-itinerary-detail-option {
  margin: 10px 0;
  font-size: 13px;
  svg {
    height: 13px;
    width: 13px;
    vertical-align: text-top;
  }
  .section-link-lodging {
  }
  .section-tag-meal {
    display: flex;
    &.not-flex {
      display: unset;
    }
    div {
      margin-right: 10px;
      &:first-of-type {
        margin-left: 10px;
      }
      &:last-of-type {
        margin-right: 0;
      }
      &:before {
        content: attr(data-text);
      }
      &.section-tag-meal__breakfast {
        color: #f8ab23;
      }
      &.section-tag-meal__lunch {
        color: #03c8a6;
      }
      &.section-tag-meal__dinner {
        color: #f03adf;
      }
    }
  }
}
