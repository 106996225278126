//
// Variables
// --------------------------------------------------
//==Colors
$main-color: #39bbdb;
$main-color-2: #2dc3e8;
$main-color-3: #13b2da;
$body-color: #e9eaed;
$grey: #f8f7f5;
$grey-bold: #bcb3aa;
$grey-bolder: #89817f;
$green: #4caf50;
$blue: #94C2ED;
$orange: #E38968;
$toggler: #e4e4e4;


$main-author-name: #e99501;
$action-preview: #FFAF11;
$action-edit: rgb(15, 149, 222);
$action-delete: red;

// Media queries breakpoints
// Extra small screen / phone
$screen-xs: 480px;
$screen-xs-min: $screen-xs;

// Small screen / tablet
$screen-sm: 576px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 992px;
$screen-lg-min: $screen-lg;

// Extra large screen / full hd
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;

// Extra extra large screen / large desktop
$screen-xxl: 1600px;
$screen-xxl-min: $screen-xxl;

// provide a maximum
$screen-xs-max: ($screen-sm-min - 1px);
$screen-sm-max: ($screen-md-min - 1px);
$screen-md-max: ($screen-lg-min - 1px);
$screen-lg-max: ($screen-xl-min - 1px);
$screen-xl-max: ($screen-xxl-min - 1px);