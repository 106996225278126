@import "../../../../../assets/sass/variables.scss";

:local(.creditCard){
  max-width: 400px;
  height: 200px;
  @media only screen and (max-width: $screen-xl-max) {
    min-width: 400px;
  }
  @media only screen and (max-width: $screen-lg-max) {
    min-width: 100%;
  }
  @media only screen and (min-width: $screen-xxl-min) {
    min-width: 400px;
  }
  .flip {
    width: 100%;
    height: inherit;
    -webkit-transition: 0.7s;
    transition: 0.7s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-animation: flip 2.5s ease;
    animation: flip 2.5s ease;
    .front{
      position: absolute;
      width: inherit;
      height: inherit;
      border-radius: 15px;
      color: #fff;
      text-shadow: 0 1px 1px rgba(0,0,0,0.3);
      box-shadow: 0 1px 10px 1px rgba(0,0,0,0.3);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      background-image: -webkit-gradient(linear, left top, right top, from(#111), to(#555));
      background-image: linear-gradient(to right, #111, #555);
      overflow: hidden;
    }
    .front {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
    .strip-bottom,
    .strip-top {
      position: absolute;
      right: 0;
      height: inherit;
      background-image: -webkit-gradient(linear, left top, left bottom, from(#ff6767), to(#ff4545));
      background-image: linear-gradient(to bottom, #ff6767, #ff4545);
      box-shadow: 0 0 10px 0px rgba(0,0,0,0.5);
    }
    .strip-bottom {
      width: 200px;
      -webkit-transform: skewX(-15deg) translateX(50px);
      transform: skewX(-15deg) translateX(50px);
    }
    .strip-top {
      width: 180px;
      -webkit-transform: skewX(20deg) translateX(50px);
      transform: skewX(20deg) translateX(50px);
    }
    .logo {
      position: absolute;
      top: 30px;
      right: 25px;
    }
    .investor {
      position: relative;
      top: 30px;
      left: 25px;
      text-transform: uppercase;
    }
    .chip {
      position: relative;
      top: 30px;
      left: 25px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      width: 50px;
      height: 40px;
      border-radius: 5px;
      background-image: -webkit-gradient(linear, right top, left bottom, from(#ffecc7), to(#d0b978));
      background-image: linear-gradient(to bottom left, #ffecc7, #d0b978);
      overflow: hidden;
    }
    .chip .chip-line {
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #333;
    }
    .chip .chip-line:nth-child(1) {
      top: 13px;
    }
    .chip .chip-line:nth-child(2) {
      top: 20px;
    }
    .chip .chip-line:nth-child(3) {
      top: 28px;
    }
    .chip .chip-line:nth-child(4) {
      left: 25px;
      width: 1px;
      height: 50px;
    }
    .chip .chip-main {
      width: 20px;
      height: 25px;
      border: 1px solid #333;
      border-radius: 3px;
      background-image: -webkit-gradient(linear, right top, left bottom, from(#efdbab), to(#e1cb94));
      background-image: linear-gradient(to bottom left, #efdbab, #e1cb94);
      z-index: 1;
    }
    .wave {
      position: relative;
      top: -10px;
      left: 100px;
    }
    .card-number {
      position: relative;
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      align-items: center;
      margin: 20px 25px 10px;
      font-size: 23px;
      font-family: 'cc font', monospace;
    }
    .end {
      margin-left: 25px;
      text-transform: uppercase;
      font-family: 'cc font', monospace;
    }
    .end .end-text {
      font-size: 9px;
      color: rgba(255,255,255,0.8);
    }
    .card-holder {
      margin: 10px 25px;
      text-transform: uppercase;
      font-family: 'cc font', monospace;
    }
    .master {
      position: absolute;
      right: 20px;
      bottom: 10px;
      display: -webkit-box;
      display: flex;
    }
    .master .circle {
      width: 50px;
      height: 50px;
    }
  }
}
