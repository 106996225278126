.day-detail-container {
  margin-top: 20px;
  .text {
    margin-bottom: 15px;
  }
  .day-input.remove {
    animation: removed-item-animation 0.8s cubic-bezier(0.65, -0.02, 0.72, 0.29);
  }
  .day-input:last-child {
    border-bottom: none;
  }
  .day-input {
    background-color: #cdcdcd40;
    width: 100%;
    float: left;
    animation: new-item-animation 0.3s linear forwards;
    margin-bottom: 15px;
    .anticon {
      vertical-align: 2px;
    }
    &__header {
      padding: 10px;
      .expand-click {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
      }
      .dynamic-delete-button {
        background-color: white;
        padding: 10px 5px;
        color: red;
        z-index: 10 !important;
        font-size: 20px;
        cursor: pointer;
      }
    }
    &__day-section {
      margin: 20px 0px;
    }
    &__day-section {
      &__guide,
      &__meal,
      &__lodging {
        .body {
          height: 100%;
          background-color: white;
          padding-left: 15px;
          padding-top: 5px;
        }
        .height-100 {
          height: 100%;
        }
        .ant-input {
          width: 80%;
        }
        .ant-input-group {
          .ant-input {
            width: 77%;
          }
        }
      }
      &__meal {
        padding: 0px 5px ;
      }
      &__guide {
        .body {
          margin-right: 0px;
        }
      }
      &__lodging {
        .body {
          margin-left: 0px;
        }
      }
    }
  }
  .add-day {
    margin-top: 20px;
    .anticon.anticon-plus {
      vertical-align: 1px;
      border: 1px solid #40a9ff;
      border-radius: 100px;
      padding: 2px;
    }
    button {
      background-color: white;
      color: #40a9ff;
      border: 0px;
      box-shadow: none;
    }
  }
}
