@import "../../../../assets/sass/variables.scss";
.table-custom {
  padding: 10px;
  width: 100%;
  float: left;
  .source-text {
    font-size: 12px;
  }
  .source {
    color: #096dd9;
    font-size: 12px;
  }
  .title-image {
    display: flex;
    .image {
      float: left;
      margin-right: 10px;
      @media only screen and (max-width: $screen-md) {
        margin-right: 0px;
        margin-bottom: 10px;
      }
    }
    .item_detail {
      float: left;
    }
    @media only screen and (max-width: $screen-md) {
      display: inline;
    }
  }
  @media screen and (max-width: 1024px) {
    .ant-table-scroll {
      width: 135%;
    }
  }
  @media only screen and (max-width: $screen-md) {
    .ant-table-scroll {
      width: 700px;
    }
    .image {
      img {
        width: 100% !important;
      }
    }

    .tab-container {
      padding-left: 40px;
    }
  }

  .ant-btn {
    margin-bottom: 10px;
  }
  .btn-move {
    margin-left: 10px;
    @media only screen and (max-width: $screen-md) {
      margin-right: 10px;
      margin-left: 0px;
    }
  }

  .btn-edit {
    margin: 0 10px;
    @media only screen and (max-width: $screen-md) {
      margin-right: 10px;
      margin-left: 0px;
    }
  }

  .btn-delete {
    @media only screen and (max-width: $screen-sm) {
      margin-top: 10px;
    }
  }

  .new-quotation-btn {
    width: fit-content;
    color: white;
    background-color: orange;
    @media only screen and (max-width: $screen-md) {
      margin-bottom: 10px;
    }
  }

}
